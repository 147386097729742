import { JSXElementConstructor, Key, lazy, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import PrivateRoute from 'utils/privateRoute';
// import LandingPage from 'components/LandingPage/LandingPage';
import MLOBSDashboard from 'components/MachineLearning/dashboard';
import ModelDetails from 'components/MachineLearning/models/modelDetails';
import controlTestDashboard from 'components/ControlsCompliance/controlTestDashboard';
import JobRoutes from './Job';
import MasterSystemRoutes from './MasterSystem';
import PlatformCostObsRoutes from './PlatformCostObs';
import DataGrowthRoutes from './DataGrowth';
import IBPCapabilitiesRoutes from './IBPCapabilities';
import DataEstateRoutes from './DataEstate';
import FinOpsRoutes from './Finops';
import DataPipelineRoutes from './DataPipeline';
import TableauObsRoutes from './TableauObs';
import PrestoObsRoutes from './PrestoObs';
import DatabricksObsRoute from './DatabricksObs';
import DashboardRoutes from './Dashboard';
import DataManagementRoutes from './DataManagement';
import PowerBIObsRoutes from './PowerBIObs';
import VerificationValidationRoutes from './VerificationValidation';
import { GrafanaDashboardAnalytics, GrafanaDashboardIframe } from '../components/framework/grafanaDashboard';


// Lazy load components
const LandingPage = lazy(() => import('components/LandingPage/LandingPage'));

const routes = [
	//Landing page
	{
		key: 'landing',
		route: '/LandingPage',
		component: (
			<PrivateRoute
				Component={LandingPage}
				restrictedRoles={['D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	...DashboardRoutes,
	...DatabricksObsRoute,
	...DataEstateRoutes,
	...DataGrowthRoutes,
	...DataManagementRoutes,
	...DataPipelineRoutes,
	...FinOpsRoutes,
	...IBPCapabilitiesRoutes,
	...JobRoutes,
	...MasterSystemRoutes,
	...PlatformCostObsRoutes,
	...PowerBIObsRoutes,
	...PrestoObsRoutes,
	...TableauObsRoutes,
	...VerificationValidationRoutes,
	//Control and Complaiance observability
	{
		key: 'ctrl_comp_dash',
		route: '/controlsCompliance/dashboard',
		component: (
			<PrivateRoute
				Component={controlTestDashboard}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	//Machine learning
	{
		key: 'mach_lrn_dash',
		route: '/machineLearning/dashboard',
		component: (
			<PrivateRoute
				Component={MLOBSDashboard}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'mach_lrn_modl_id',
		route: 'machineLearning/models/:modelId',
		component: (
			<PrivateRoute
				Component={ModelDetails}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'grafana_dashboard_poc_analytics',
		route: 'grafanaDashboard/poc/analytics',
		component: (
			<PrivateRoute
				Component={GrafanaDashboardAnalytics}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'grafana_dashboard_poc_iframe',
		route: 'grafanaDashboard/poc/iframe',
		component: (
			<PrivateRoute
				Component={GrafanaDashboardIframe}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

const getRoutes = (allRoutes: any[]): any =>
	allRoutes.map(
		(route: {
			default: any;
			route: string;
			component: ReactElement<any, string | JSXElementConstructor<any>>;
			key: Key;
		}): object => {
			return <Route path={route.route} element={route.component} key={route.key} />;
		}
	);

const AppRoutes = () => {
	const [controller] = usePepwiseAuthController();
	const { isAuthenticated, accessToken } = controller;

	const roleName = (accessToken?.role?.name as string) ?? 'default';
	const rolePaths: { [key: string]: string } = {
		'Data Operations Admin': '/LandingPage',
		'Data Operations User': '/LandingPage',
		'D&AI Executive User': '/dataestate/executive-summary',
		'Program Executive': '/ibp/executive',
		default: '/LandingPage'
	};

	const defaultRoutePath =
		!isAuthenticated || !rolePaths.hasOwnProperty(roleName) ? '/LandingPage' : rolePaths[roleName];

	return (
		<Routes>
			{getRoutes(routes)}
			<Route path="*" element={<Navigate to={defaultRoutePath} replace />} />
		</Routes>
	);
};

export default AppRoutes;
