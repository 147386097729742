import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { Box, Typography } from '@mui/material';

export function PaginationDetails({
	page,
	pageSize,
	paginationData,
	listLength,
	handlepageSizeChange,
	handlePageChange
}: any) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				px: 1,
				backgroundColor: 'blue.dark',
			}}
		>
			<Typography variant="body2" color="light.diff">
				Showing {(page * parseInt(pageSize) - parseInt(pageSize) + 1).toLocaleString()} -{' '}
				{page * parseInt(pageSize) > paginationData.totalElements
					? paginationData.totalElements.toLocaleString()
					: (page * parseInt(pageSize)).toLocaleString()}{' '}
				of {listLength ? paginationData.totalElements.toLocaleString() : 0} results
			</Typography>
			<Stack direction="row" gap={2} alignItems="center">
				<Stack direction="row" alignItems='center'>
					<Typography variant="body2" color="light.diff">
						Rows per Page:
					</Typography>
					<Select
						sx={{
							color: 'white',
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none'
							},
							'& .MuiSvgIcon-root': {
								color: 'white.main'
							}
						}}
						labelId="pageSize"
						id="pageSize"
						value={pageSize}
						onChange={handlepageSizeChange}
					>
						<MenuItem value={100}>100</MenuItem>
						<MenuItem value={200}>200</MenuItem>
						<MenuItem value={500}>500</MenuItem>
						<MenuItem value={1000}>1000</MenuItem>
					</Select>
				</Stack>
				<Pagination
					count={paginationData.totalPages}
					page={Number(page)}
					onChange={handlePageChange}
					shape="rounded"
					showFirstButton
					showLastButton
					sx={{
						'& .MuiPaginationItem-root': {
							color: 'rgba(229, 229, 229, 1)'
						},
						'& .MuiPaginationItem-root:hover': {
							backgroundColor: 'rgba(90, 84, 152, 0.2)'
						},
						'& .Mui-selected': {
							backgroundColor: 'rgba(90, 84, 152, 0.2) !important'
						}
					}}
				/>
			</Stack>
		</Box>
	);
}
