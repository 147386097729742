import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	Collapse,
	Dialog,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	MenuProps,
	Popover,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { DataPipelineModal } from './modal-component';
import FilterIcon from '../../assets/filter-projects.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import { ReactComponent as AddIcon } from '../../assets/plus-icon.svg';
import ExpandMoreIcon from '../../assets/down-arrow.svg';
import ViewIcon from '../../assets/view-detail-img.svg';
import ExportIcon from '../../assets/export-img.svg';
import CalenderDateIcon from '../../assets/calender-date.svg';
import NoPrgramFound from '../../assets/no-program-found.svg';
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import {
	getJobPerformanceChartData,
	getMappedJobScheduleList,
	getProjectById,
	getSlaCountDetail
} from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as CalendarGreyIcon } from '../../assets/icon-calendar-grey.svg';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import { Diversity1Outlined } from '@mui/icons-material';
import { DateRange as DateRangeCustom } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import { enUS } from 'date-fns/locale';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import MoreOptionMenu from 'components/Dropdown/MoreOptionSecondary';
import DateInfoTooltip from 'components/Tooltip/DateInfoTooltip';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import DataInfoColorWidget from 'components/Widgets/DataInfoColorWidget';
import ReactDateRangeFilter from 'components/Dropdown/ReactDateRangeFilter';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import StatusChip from 'components/Table/StatusChip';
import RowTypography from 'components/Table/RowTypography';
import eventEmitter from 'SnackBarEventEmitter';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
			}
		}
	}
}));

const ProjectDetails = () => {
	// interface Row {
	// 	id: number;
	// 	Job_Name: string;
	// 	Platform_Id: number;
	// 	Start_Time: string;
	// 	Normal_Completion_Time: number;
	// 	slaStatus: number;
	// 	jobStatus: string;
	// 	estimatedTime: string;
	// 	Days: number;
	// 	actualStartTime: string;
	// 	actualEndTime: string;
	// 	scheduleStartTime: number;
	// 	Date: string;
	// 	successor: number;
	// 	dependentData: any;
	// 	isExpanded: boolean;
	// 	children: Row[];
	// 	ischildren?: boolean;
	// 	Dep_Job_Name?: string;
	// 	projectScheduleId: string;
	// }

	interface Row {
		id: number,
	    jobId: number,
		jobName: string,
		dependentData: any,
		scheduler?: string,
		jobStatus: string,
		oDate: string,
		actualStartTime: string,
		actualEndTime: string,
		slaStatus: string,
		slaTime: number,
		isExpanded: boolean,
		ischildren?: boolean;
		children: Row[],
		platformId: number,
		slaDate: string
	}

	const { programId, projectId, program, project } = useParams();

	// const scheduleData: Row[] = [
	//     {
	//         "projectScheduleId": 2765,
	//         "isExpanded": false,
	//         "id": 286582,
	//         "Days": 3,
	//         "Job_Name": "ppdi2_s7_custom_vanilla_analyt",
	//         "Platform_Id": 1,
	//         "slaStatus": 2,
	//         "Normal_Completion_Time": 200,
	//         "actualStartTime": '00:00',
	//         "actualEndTime": '',
	//         "scheduleStartTime": 2300,
	//         "jobStatus": '',
	//         "estimatedTime": '',
	//         "Date": "2024-10-02T00:00:00.000Z",
	//         "successor": 0,
	//         "jobId": 53918,
	//         "projectId": 152,
	//         "programId": 52,
	//         "dependentData": [{
	//         "projectScheduleId": 2775,
	//         "id": 286583,
	//         "Days": 3,
	//         "Job_Name": "ppdi2_dwh_dw_hold_job",
	//         "Platform_Id": 1,
	//         "slaStatus": 2,
	//         "Normal_Completion_Time": null,
	//         "actualStartTime": null,
	//         "actualEndTime": null,
	//         "scheduleStartTime": 0,
	//         "jobStatus": null,
	//         "estimatedTime": null,
	//         "Date": "2024-10-02T00:00:00.000Z",
	//         "successor": 2765,
	//         "jobId": 52490,
	//         "projectId": 152,
	//         "programId": 52
	//     }, {
	//         "projectScheduleId": 2776,
	//         "id": 286584,
	//         "Days": 3,
	//         "Job_Name": "ppdi2_s7_custom_vanilla_analyt",
	//         "Platform_Id": 1,
	//         "slaStatus": 2,
	//         "Normal_Completion_Time": null,
	//         "actualStartTime": null,
	//         "actualEndTime": null,
	//         "scheduleStartTime": 2300,
	//         "jobStatus": null,
	//         "estimatedTime": null,
	//         "Date": "2024-10-02T00:00:00.000Z",
	//         "successor": 2765,
	//         "jobId": 53918,
	//         "projectId": 152,
	//         "programId": 52
	//     }]
	//     }
	// ]

	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
	};

	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [projectDetail, setProjectDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [slaLoading, setSlaLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [jobSchedulesList, setjobSchedulesList] = useState<Row[]>([]);
	const [slaData, setSlaData] = useState<any>({});
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterOptions, setFilterOptions] = useState<any>({});
	const [slaReport, setSlaReport] = useState<any>({});
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');

	const [startDate, setStartDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
	const [endDate, setEndDate] = useState<string>(moment().endOf('day').format('YYYY-MM-DD HH:mm'));

	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);

	// const [is24Hrs, setIs24Hrs] = useState<any>(false);
	let is24Hrs: boolean = false;

	const handleDateSelect = (stDt: string, edDt: string, selectedDate?: string, is24HrsTime?: boolean) => {
		projectMappedJobScheduleFunc(page, pageSize, stDt, edDt, parseFilter(filterInfo), sortingInfo);
		// jobPerformanceBarChartData(stDt, edDt);
		// getSlaCountData(stDt, edDt);
		if (is24HrsTime !== undefined) {
			is24Hrs = is24HrsTime;
		}
		// getServiceRequestSummary(selectedDate);
	};

	eventEmitter.on('showSnackbar', (message: string) => {
		setTableLoading(false);
		setSlaLoading(false);
		setGrafanaChartLoading(false)
	});

	const handleTableAction = (props: any, type: string) => {
		if (type == 'delete') {
			setOpenModal({
				isOpen: true,
				type: 'Schedule',
				action: 'DELETE',
				from: 'delete',
				data: props
			});
		}
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	function convertToTimeString(num: any) {
		const hours = Math.floor(num / 100)
			.toString()
			.padStart(2, '0');
		const minutes = (num % 100).toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	}

	const handleAndGetFilterOptions = async (column: any) => {
		console.log("COLLLL", column)
		let filterSch: any = {};
		let colObj: any = {
			Job_Name: 'Job_Name',
			platform: 'Platform_Id',
			Scheduler_Name: 'Scheduler_Name',
			Start_Time: 'Start_Time'
		};
		if (jobSchedulesList.length) {
			filterSch[column] = [];

			jobSchedulesList.map((data: any) => {
				// Check if the value is already in the array before pushing

				if (column == 'platformId') {
					switch (data[column]) {
						case 1:
							if (!filterSch[column].includes('Teradata')) {
								filterSch[column].push('Teradata');
							}
							break;

						default:
							break;
					}
				}  else if (
					column == 'oDate' &&
					!filterSch[column].includes(`${moment(data.oDate).tz('America/Chicago').format("MM-DD-YY")}`)
				) {
					filterSch[column].push(`${moment(data.oDate).tz('America/Chicago').format("MM-DD-YY")}`);
				}  else if (
					column == 'actualStartTime' || column == 'actualEndTime'
					
				) {
					if (!filterSch['actualStartTime'].includes(`${moment(data.actualStartTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`)) {
						filterSch['actualStartTime'].push(`${moment(data.actualStartTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`);
					} else if(!filterSch['actualEndTime'].includes(`${moment(data.actualEndTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`)) {
						filterSch['actualEndTime'].push(`${moment(data.actualEndTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`);
					}
					
				} else if (
					column == 'slaTime' &&
					!filterSch[column].includes(`${convertToTimeString(data.slaTime)}`)
				) {
					filterSch[column].push(`${convertToTimeString(data.slaTime)}`);
				} else if (!filterSch[column].includes(data[column])) {
					filterSch[column].push(data[column]);
				}
			});
			if (column == 'slaTime') {
				let filteredData = [];
				filteredData = filterSch[column].filter((item: any) => typeof item === 'string');
				filterSch[column] = filteredData;
			}
			console.log(filterSch)
			setFilterOptions(filterSch);
		}
	};

	const handleFilter = (filterData: any) => {
		setFilterInfo(filterData);
		let objData: any = parseFilter(filterData);
		if (Object.keys(objData).includes('slaTime')) {
			objData['slaTime'].map((data: any, index: number) => {
				objData['slaTime'][index] = Number(data.replace(':', ''));
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else if (Object.keys(objData).includes('platformId')) {
			objData['platformId'].map((data: any, index: number) => {
				if (data == 'Teradata') {
					objData['platformId'][index] = 1;
				}
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else {
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterData), sortingInfo);
		}
	};

	function toggleSubRow(name: number, resourceTypes: any) {
		
		setjobSchedulesList((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.id === name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}

	const [pageSize, setPageSize] = useState('100');
	const defaultColumnNames = [
		'Platform',
		'Jobs',
		'Order Date',
		'SLA Status',
		'Job Status',
		'Act. Start Time (CST)',
		'Act. End Time (CST)',
		'SLA Date',
		'SLA Time (CST)',
	];

	// function getColumns(): Array<Column<Row>> {
	// 	return [
	// 		{
	// 			key: 'Job_Name',
	// 			name: 'Jobs',
	// 			// formatter(props) {
	// 			// 	return <div title={props.row.Job_Name} className={`has-ellipsis ${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Job_Name ? props.row.Job_Name : '--'}</div>
	// 			// },
	// 			formatter({ row, isCellSelected }) {
	// 				const hasChildren = row.dependentData;
	// 				return (
	// 					<>
	// 						{hasChildren && (
	// 							<CellExpanderFormatter
	// 								isCellSelected={isCellSelected}
	// 								expanded={row.isExpanded === true}
	// 								onCellExpand={() => {
	// 									toggleSubRow(row.projectScheduleId, row.dependentData);
	// 								}}
	// 							/>
	// 						)}
	// 						{'   '}
	// 						<RowTypography isSuccessor={row.successor > 0}>{row.Job_Name}</RowTypography>
	// 					</>
	// 				);
	// 			},
	// 			minWidth: 170
	// 		},
	// 		{
	// 			key: 'Platform_Id',
	// 			name: 'Platform',
	// 			formatter({ row }) {
	// 				return (
	// 					<RowTypography isSuccessor={row.successor > 0}>{row.Platform_Id == 1 ? 'Teradata' : ''}</RowTypography>
	// 				);
	// 			},
	// 			minWidth: 110
	// 		},

	// 		{
	// 			key: 'slaStatus',
	// 			name: 'SLA Status',
	// 			formatter(props) {
	// 				const status = props.row.slaStatus == 1 ? 'SLA Met' : props.row.slaStatus == 0 ? 'SLA Missed' : '--';
	// 				return <StatusChip status={status} />;
	// 				// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'NA'}</div>
	// 			},
	// 			minWidth: 120
	// 		},

	// 		{
	// 			key: 'Normal_Completion_Time',
	// 			name: 'SLA Completion Time (CST)',
	// 			formatter(props) {
	// 				return (
	// 					<RowTypography textTransform="capitalize" isSuccessor={props.row.successor > 0}>
	// 						{props.row.Normal_Completion_Time
	// 							? props.row.Normal_Completion_Time < 10
	// 								? `000${props.row.Normal_Completion_Time}`.replace(/(.{2})$/, ':$1')
	// 								: props.row.Normal_Completion_Time < 100
	// 								? `00${props.row.Normal_Completion_Time}`.replace(/(.{2})$/, ':$1')
	// 								: props.row.Normal_Completion_Time.toString().replace(/(.{2})$/, ':$1')
	// 							: '--'}
	// 					</RowTypography>
	// 				);
	// 			},
	// 			minWidth: 250
	// 		},
	// 		{
	// 			key: 'actualStartTime',
	// 			name: 'Act. Start Time (CST)',
	// 			formatter(props) {
	// 				const startDate = props.row.actualStartTime;
	// 				// if (startDate) {
	// 				return (
	// 					<RowTypography isSuccessor={props.row.successor > 0}>
	// 						{' '}
	// 						{startDate && startDate != null
	// 							? moment(props.row.actualStartTime).tz('America/Chicago').format('MM-DD-YY HH:mm')
	// 							: '--'}{' '}
	// 					</RowTypography>
	// 				);
	// 				// }
	// 				// return '';
	// 			},
	// 			minWidth: 180
	// 		},
	// 		{
	// 			key: 'actualEndTime',
	// 			name: 'Act. End Time (CST)',
	// 			formatter(props) {
	// 				const startDate = props.row.actualEndTime;
	// 				// if (startDate) {
	// 				return (
	// 					<RowTypography isSuccessor={props.row.successor > 0}>
	// 						{' '}
	// 						{startDate
	// 							? moment(props.row.actualEndTime).tz('America/Chicago').format('MM-DD-YY HH:mm')
	// 							: '--'}{' '}
	// 					</RowTypography>
	// 				);
	// 				// }
	// 				// return '';
	// 			},
	// 			minWidth: 180
	// 		},
	// 		// {
	// 		//     key: 'Days',
	// 		//     name: 'Schedule Start Day (CST)',
	// 		//     formatter(props) {
	// 		// 		return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Days ? getDays(props.row.Days) : '--'}</div>
	// 		// 	},
	// 		//     minWidth: 220
	// 		// },
	// 		{
	// 			key: 'scheduleStartTime',
	// 			name: 'Schedule Start Time (CST)',
	// 			formatter(props) {
	// 				return (
	// 					<RowTypography textTransform="capitalize" isSuccessor={props.row.successor > 0}>
	// 						{props.row.scheduleStartTime
	// 							? getDays(props.row.Days) +
	// 							  ', ' +
	// 							  (props.row.scheduleStartTime < 10
	// 									? `000${props.row.scheduleStartTime}`.replace(/(.{2})$/, ':$1')
	// 									: props.row.scheduleStartTime < 100
	// 									? `00${props.row.scheduleStartTime}`.replace(/(.{2})$/, ':$1')
	// 									: props.row.scheduleStartTime.toString().replace(/(.{2})$/, ':$1'))
	// 							: '--'}
	// 					</RowTypography>
	// 				);
	// 			},
	// 			minWidth: 220
	// 		},
	// 		{
	// 			key: 'jobStatus',
	// 			name: 'Job Status',
	// 			formatter(props) {
	// 				const status =
	// 					props.row.slaStatus == 2 ? (props.row.jobStatus ? props.row.jobStatus : 'Scheduled') : props.row.jobStatus;
	// 				return <StatusChip status={status} />;
	// 				// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.jobStatus ? props.row.jobStatus : '--'}</div>
	// 			},
	// 			minWidth: 120
	// 		},
	// 		// {
	// 		//     key: 'estimatedTime',
	// 		//     name: 'Estimated Completion (CST)',
	// 		//     formatter(props) {
	// 		// 		const startDate = props.row.estimatedTime;
	// 		// 		if (startDate) {
	// 		// 			return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ?  moment(props.row.estimatedTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss') : '--'}  </span>
	// 		// 		}
	// 		// 		return '';
	// 		// 	},
	// 		//     minWidth: 220
	// 		// },
	// 		{
	// 			key: 'Date',
	// 			name: 'Date',
	// 			formatter(props) {
	// 				const startDate = props.row.Date;
	// 				if (startDate) {
	// 					return (
	// 						<RowTypography isSuccessor={props.row.successor > 0}>
	// 							{' '}
	// 							{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
	// 						</RowTypography>
	// 					);
	// 				}
	// 				return '';
	// 			},
	// 			minWidth: 100
	// 		}
	// 	];
	// }

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'jobName',
				name: 'Jobs',
				formatter({ row, isCellSelected }) {
					const hasChildren = row.dependentData;
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.dependentData);
									}}
								/>
							)}
							{'   '}
							<RowTypography isSuccessor={!row.dependentData}>{row.jobName}</RowTypography>
						</>
					);
				},
				minWidth: 170
			},
			{
				key: 'platformId',
				name: 'Platform',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.platformId == 1 ? 'Teradata' : ''}</RowTypography>
					);
				},
				minWidth: 110
			},

			{
				key: 'slaStatus',
				name: 'SLA Status',
				formatter(props) {
					return <StatusChip status={props.row.slaStatus} />;
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'NA'}</div>
				},
				minWidth: 120
			},
			{
				key: 'jobStatus',
				name: 'Job Status',
				formatter(props) {
					return <StatusChip status={props.row.jobStatus} />;
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.jobStatus ? props.row.jobStatus : '--'}</div>
				},
				minWidth: 120
			},
			{
				key: 'actualStartTime',
				name: 'Act. Start Time (CST)',
				formatter(props) {
					const startDate = props.row.actualStartTime;
					// if (startDate) {
					return (
						<RowTypography isSuccessor={!props.row.dependentData}>
							{' '}
							{startDate && startDate != null
								? moment(props.row.actualStartTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
					// }
					// return '';
				},
				minWidth: 180
			},
			{
				key: 'actualEndTime',
				name: 'Act. End Time (CST)',
				formatter(props) {
					const startDate = props.row.actualEndTime;
					// if (startDate) {
					return (
						<RowTypography isSuccessor={!props.row.dependentData}>
							{' '}
							{startDate
								? moment(props.row.actualEndTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
					// }
					// return '';
				},
				minWidth: 180
			},

			{
				key: 'slaTime',
				name: 'SLA Time (CST)',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize" isSuccessor={!props.row.dependentData}>
							{props.row.slaTime
								? props.row.slaTime < 10
									? `000${props.row.slaTime}`.replace(/(.{2})$/, ':$1')
									: props.row.slaTime < 100
									? `00${props.row.slaTime}`.replace(/(.{2})$/, ':$1')
									: props.row.slaTime.toString().replace(/(.{2})$/, ':$1')
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 250
			},
			
			// {
			//     key: 'Days',
			//     name: 'Schedule Start Day (CST)',
			//     formatter(props) {
			// 		return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Days ? getDays(props.row.Days) : '--'}</div>
			// 	},
			//     minWidth: 220
			// },

			
			// {
			//     key: 'estimatedTime',
			//     name: 'Estimated Completion (CST)',
			//     formatter(props) {
			// 		const startDate = props.row.estimatedTime;
			// 		if (startDate) {
			// 			return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ?  moment(props.row.estimatedTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss') : '--'}  </span>
			// 		}
			// 		return '';
			// 	},
			//     minWidth: 220
			// },
			{
				key: 'oDate',
				name: 'Order Date',
				formatter(props) {
					const startDate = props.row.oDate;
					if (startDate) {
						return (
							<RowTypography isSuccessor={!props.row.dependentData}>
								{' '}
								{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 100
			},
			{
				key: 'slaDate',
				name: 'SLA Date',
				formatter(props) {
					const startDate = props.row.slaDate;
					if (startDate) {
						return (
							<RowTypography isSuccessor={!props.row.dependentData}>
								{' '}
								{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 100
			}
		];
	}

	const getDays = (day: number) => {
		let days;
		if (day == 0) {
			days = 'Sun';
		} else if (day == 1) {
			days = 'Mon';
		} else if (day == 2) {
			days = 'Tue';
		} else if (day == 3) {
			days = 'Wed';
		} else if (day == 4) {
			days = 'Thur';
		} else if (day == 5) {
			days = 'Fri';
		} else if (day == 6) {
			days = 'Sat';
		}
		return days;
	};

	const jobPerformanceBarChartData = async (stdt: any = '', eddt: any = '') => {
		let params = {
			projectId,
			programId,
			startDate: stdt ? stdt : startDate,
			endDate: eddt ? eddt : endDate,
			hours: is24Hrs ? 24 : ''
		};

		setGrafanaChartLoading(true);
		let result = await getJobPerformanceChartData(params);
		if (result) {
			setGrafanaChartOptions(result.data.slaMetrics);
			setGrafanaChartLoading(false);
		}
	};
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/projects')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}

		getProjectDetails();
		// getSlaCountData(startDate, endDate);
		// jobPerformanceBarChartData();
	}, []);

	const getProjectDetails = async () => {
		setLoading(true);
		let result = await getProjectById({ projectId, programId });
		setLoading(false);
		setProjectDetail(result.data?.programData ? result.data.programData[0] : '');
		if (result.data.programData[0].jobCount) {
			projectMappedJobScheduleFunc('1', '100', startDate, endDate, parseFilter(filterInfo), sortingInfo);
		}
	};

	const passInfoToParent = (data: any) => {
		getProjectDetails();
		// jobPerformanceBarChartData();
		// getSlaCountData(startDate, endDate);
	};

	const handleEditClose = (data: any) => {
		setOpenModal({
			isOpen: false
		});
	};

	const onCreateProgramClick = (event: any) => {
		setOpenModal({
			isOpen: true,
			type: 'Project',
			action: 'CREATE'
		});
	};

	function convertToTimeFormat(num: number) {
		// Ensure the number is a string with leading zeros if necessary
		const numStr = num.toString().padStart(4, '0');

		// Extract hours and minutes
		const hours = numStr.slice(0, 2);
		const minutes = numStr.slice(2);

		// Format as 'HH:MM'
		return `${hours}:${minutes}`;
	}

	const handleSorting = (sortingInfo: any) => {
        projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
        setSortingInfo(sortingInfo)

	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
		projectMappedJobScheduleFunc(value, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		projectMappedJobScheduleFunc(page, event.target.value, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};

	async function getSlaCountData(start: string, end: string) {
		setSlaLoading(true);
		const res: any = await getSlaCountDetail(projectId, start, end, is24Hrs);
		let objData = {
			slaMet: 0,
			slaMissed: 0,
			slaPending: 0,
			completed: 0,
			failed: 0
		};
		res.data.map((obj: any) => {
			objData.slaMet += obj.slaMet;
			objData.slaMissed += obj.slaMissed;
			(objData.slaPending += obj.slaPending), (objData.completed += obj.completed), (objData.failed += obj.failed);
		});
		// setSlaReport({
		// 	...objData
		// });
		setSlaLoading(false);
	}

	const removeDuplicates = (arr: any) => {
		const seenIds = new Set();

		return arr.filter((item: any) => {
			const duplicate = seenIds.has(item.id);
			seenIds.add(item.id);
			return !duplicate; // Only return items with unique ids
		});
	};

	// async function projectMappedJobScheduleFunc(
	// 	pageNumber: string,
	// 	pageSize: string,
	// 	startDate: string,
	// 	endDate: string,
	// 	filterData: any,
	// 	sortingInfo: any
	// ) {
	// 	setTableLoading(true);
	// 	const res = await getMappedJobScheduleList(projectId, pageNumber, pageSize, startDate, endDate, filterData, is24Hrs, sortingInfo);
	// 	if (res.success) {
	// 		setTableLoading(false);
	// 		let jobArr = res.data.scheduleList.filter((obj: any) => !obj.successor || obj.successor === null);
	// 		let depJobArr = res.data.scheduleList.filter((obj: any) => obj.successor && obj.successor !== null);
	// 		let reqData: any = [];
	// 		setSlaData({
	// 			...slaData,
	// 			...res.data.slaData
	// 		});
	// 		const filters = res.data.filterOptions;
	// 		let formatedDates: any = [];
	// 		Object.entries(filters).filter((d: any) => {
	// 			if (d[0] == 'date') {
	// 				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'));
	// 			}
	// 		});
	// 		filters['date'] = formatedDates;

	// 		Object.keys(filters).map((key: any, index: number) => {
	// 			if (key === 'slaStatus') {
	// 				filters['sla'] = [];
	// 				filters[key].map((val: any) => {
	// 					switch (val) {
	// 						case 1:
	// 							filters['sla'].push('SLA Met');
	// 							break;
	// 						case 0:
	// 							filters['sla'].push('SLA Missed');
	// 							break;
	// 						case 2:
	// 							filters['sla'].push('Scheduled');
	// 							break;
	// 						default:
	// 							break;
	// 					}
	// 				});
	// 				filters['slaStatus'] = filters['sla'];
	// 				delete filters['sla'];
	// 			} else if (key === 'status') {
	// 				filters['jobStatus'] = [];
	// 				filters[key].map((val: any) => {
	// 					if (val === 'Blank') {
	// 						filters['jobStatus'].push('Scheduled');
	// 					} else {
	// 						filters['jobStatus'].push(val);
	// 					}
	// 				});
	// 				delete filters['status'];
	// 			} else if (key === 'Platform_Id') {
	// 				filters[key].map((val: any, index: number) => {
	// 					if (val === 1) {
	// 						filters['Platform_Id'][index] = 'Teradata';
	// 					}
	// 				});
	// 			} else if (key === 'startAt') {
	// 				filters['actualStartTime'] = filters['startAt'];
	// 			} else if (key === 'endAt') {
	// 				filters['actualEndTime'] = filters['endAt'];
	// 			} else if (key === 'Start_Time') {
	// 				filters['scheduleStartTime'] = filters['Start_Time'];

	// 				filters['scheduleStartTime'].map((data: any, i: number) => {
	// 					typeof data == 'number'
	// 						? (filters['scheduleStartTime'][i] = convertToTimeFormat(data))
	// 						: (filters['scheduleStartTime'][i] = data);
	// 				});
	// 			} else if (key === 'Normal_Completion_Time') {
	// 				filters['Normal_Completion_Time'].map((data: any, i: number) => {
	// 					typeof data == 'number'
	// 						? (filters['Normal_Completion_Time'][i] = convertToTimeFormat(data))
	// 						: (filters['Normal_Completion_Time'][i] = data);
	// 				});
	// 			}
	// 		});
	// 		setFilterOptions(filters);
	// 		if (is24Hrs) {
	// 			jobArr.map((data: any) => {
	// 				data['dependentData'] = [];
	// 				data['isExpanded'] = false;
	// 				depJobArr.map((dep: any) => {
	// 					if (dep.successor === data.projectScheduleId) {
	// 						// dep['Dep_Job_Name'] = dep['Job_Name']
	// 						// delete dep['Job_Name']
	// 						data.dependentData.push(dep);
	// 					}
	// 				});
	// 				data['dependentData'] = data.dependentData.length ? data.dependentData : false;
	// 				reqData.push(data);
	// 			});
	// 		} else {
	// 			jobArr.map((data: any) => {
	// 				data['dependentData'] = [];
	// 				data['isExpanded'] = false;
	// 				depJobArr.map((dep: any) => {
	// 					if (
	// 						dep.successor === data.projectScheduleId &&
	// 						moment(dep.Date).format('YYYY-MM-DD') === moment(data.Date).format('YYYY-MM-DD')
	// 					) {
	// 						// reqData.push(dep)
	// 						// dep['Dep_Job_Name'] = dep['Job_Name']
	// 						// delete dep['Job_Name']
	// 						data.dependentData.push(dep);
	// 					}
	// 				});
	// 				data['dependentData'] = data.dependentData.length ? data.dependentData : false;
	// 				reqData.push(data);
	// 			});
	// 		}

	// 		reqData = removeDuplicates(reqData);

	// 		// if (sortingInfo.sortBy != 'Date') {
	// 		//     reqData.sort(function (a: any, b: any) {
	// 		//         // First, compare by date
	// 		//         const dateDiff = new Date(b.Date).getTime() - new Date(a.Date).getTime();
							
	// 		//         // If dates are equal, compare by Days
	// 		//         if (dateDiff === 0) {
	// 		//           const daysDiff = a.Days - b.Days;
								
	// 		//           // If Days are also equal, compare by Start_Time
	// 		//           if (daysDiff === 0) {
	// 		//             return a.Start_Time - b.Start_Time; // Assuming Start_Time is a comparable number or timestamp
	// 		//           }
						
	// 		//           return daysDiff;
	// 		//         }
						
	// 		//         return dateDiff;
	// 		//       });
	// 		// }

	// 		setjobSchedulesList(reqData);
	// 		setPaginationData(res.data.page);
	// 		setPage(res.data.page.currentPage);
	// 		setPageSize(res.data.page.size);
	// 		// const pagination = {"size":100,"totalElements": reqData.length || res.data.scheduleList.length,"totalPages": 1,"currentPage":1};
	// 		// setPaginationData(pagination);
	// 		// setPage('1');
	// 		// setPageSize('100');
	// 	}
	// }

	async function projectMappedJobScheduleFunc(
		pageNumber: string,
		pageSize: string,
		startDate: string,
		endDate: string,
		filterData: any,
		sortingInfo: any
	) {
		setTableLoading(true);
		const res = await getMappedJobScheduleList(projectId, pageNumber, pageSize, startDate, endDate, filterData, is24Hrs, sortingInfo);
		if (res.success) {
			setTableLoading(false);
			setGrafanaChartLoading(false)
			res.data.reqResult.map((data: any, index: number) => {
				res.data.reqResult[index] = {...data, id: index+1}
			})
			setjobSchedulesList(res.data.reqResult);
			setGrafanaChartOptions(res.data.chartData);
			let objData = {
				slaMet: res.data.kpiData.sla_met,
				slaMissed: res.data.kpiData.sla_missed,
				slaPending: res.data.kpiData.sla_pending,
				completed: res.data.kpiData.success,
				failed: res.data.kpiData.failed
			};
			
			setSlaReport({
				...objData
			});
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
		
		}
	}


	const formatDate = (isoString: string) => {
		const date = new Date(isoString);

		const padTo2Digits = (num: any) => String(num).padStart(2, '0');

		const month = padTo2Digits(date.getUTCMonth() + 1); // getUTCMonth() returns months from 0-11
		const day = padTo2Digits(date.getUTCDate());
		const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
		const hours = padTo2Digits(date.getUTCHours());
		const minutes = padTo2Digits(date.getUTCMinutes());
		const seconds = padTo2Digits(date.getUTCSeconds());

		return `${month}-${day}-${year}`;
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleMenuClose(e);
		data = {
			...data,
			ProgramName: projectDetail?.program_Name
		};
		// debugger
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'EDIT',
					from: 'view',
					data: module === 'Project' ? projectDetail : data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'DELETE',
					from: 'delete',
					data: module === 'Project' ? projectDetail : data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
					program || ''
				)}/${encodeURIComponent(project || '')}`;
				break;
			default:
				break;
		}
	};

	const seriesData = [
		{ name: 'SLA MET', dataKey: 'sla_met', color: '#43CC14' },
		{ name: 'SLA MISSED', dataKey: 'sla_missed', color: '#ff7f00' }
	];

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[5]),
						link: `/data-pipeline/programs/${window.location.pathname
							.split('/')[3]
							.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[5].replace(/%20/g, ' ')}`
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[6])
					}
				]}
				childrenContainerProps={{
					minWidth: '395px'
				}}
			>
				<ReactDateRangeFilter setStartDate={setStartDate} setEndDate={setEndDate} handleDateSelect={handleDateSelect} />
				<Link
					to={`/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
						program || ''
					)}/${encodeURIComponent(project || '')}`}
				>
					<Button variant="pepwiseSecondary" startIcon={<AddIcon />}>
						ADD JOBS
					</Button>
				</Link>
			</PageHeader>
			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal
					handleEditClose={handleEditClose}
					programData={openModal}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Stack spacing={2} p={2}>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} lg={6}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Project Name',
									value: projectDetail?.Project_Name,
									isLoading: !projectDetail?.Project_Name,
									valueProps: {
										lineHeight: '24px'
									}
								}
							]}
							infoTooltip={{
								titleValues: [
									{
										label: 'Project Owner',
										value: projectDetail?.Project_Owner ? projectDetail.Project_Owner : 'Loading...'
									},
									{
										label: 'Description',
										value: projectDetail?.Project_Description ? projectDetail.Project_Description : 'Loading...'
									}
								],
								dateValues: [
									{ label: 'Date Created', value: projectDetail?.Created_Dt ?? 'NA' },
									{ label: 'Last Updated', value: projectDetail?.Updated_Dt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleClick,
								onClose: handleMenuClose,
								menuItems: !projectDetail.Clarity_Project_Id
									? [
											{
												key: 'edit',
												label: 'Edit',
												icon: <EditIcon />,
												onMenuClick: (e) => handleAction('Project', 'edit', {}, e)
											},
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Project', 'delete', {}, e)
											}
									  ]
									: [
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Project', 'delete', {}, e)
											}
									  ]
							}}
						/>
					</Grid>
					<Grid item container xs={12} lg={6} spacing={1}>
						{[
							{
								title: 'Jobs',
								value: paginationData.totalElements ? paginationData.totalElements : 0,
								isLoading: tableLoading,
								color: ''
							},
							{
								title: 'Success',
								value: slaReport.completed || 0,
								isLoading: tableLoading,
								color: 'blue'
							},
							{
								title: 'Failed',
								value: slaReport.failed || 0,
								isLoading: tableLoading,
								color: 'red'
							},
							{
								title: 'Scheduled',
								value: slaReport.slaPending || 0,
								isLoading: tableLoading,
								color: 'default'
							},
							{
								title: 'SLA Met',
								value: slaReport.slaMet || 0,
								isLoading: tableLoading,
								color: 'green'
							},
							{
								title: 'SLA Missed',
								value: slaReport.slaMissed || 0,
								isLoading: tableLoading,
								color: 'orangeDark'
							}
						].map(({ title, value, color, isLoading }) => (
							<Grid item xs={4} sm={2} display="flex">
								<DataInfoColorWidget title={title} value={value} boxColor={color} isLoading={isLoading} />
							</Grid>
						))}
					</Grid>
				</Grid>

				
				{!tableLoading && jobSchedulesList.length > 0 ? (
					// <CustomizedDataGrid
					// 	tableColumns={getColumns()}
					// 	tableData={jobSchedulesList}
					// 	tableType="job-schedules-1"
					// 	defaultColumnNames={defaultColumnNames}
					// 	filterDataOptions={filterOptions}
					// 	onSorting={handleSorting}
					// 	currSorting={sortingInfo}
					// 	onFilterCheck={handleFilter}
					// 	currFilter={filterInfo}
					// 	page={page}
					// 	pageSize={pageSize}
					// 	paginationData={paginationData}
					// 	listLength={jobSchedulesList.length}
					// 	handlepageSizeChange={handlepageSizeChange}
					// 	handlePageChange={handlePageChange}
					// 	isDateRangeFilter={false}
					// 	isDisabled={false}
					// 	isFetching={true}
					// 	showExport={false}
					// 	isShowMoreButton
					// />
					<Stack>
						<Typography variant="pageTitle" style={{ fontSize: '16px', fontWeight: '700', position: 'absolute', marginTop: '10px' }}>Job Executions</Typography>
					   <Box>
					   <CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={jobSchedulesList}
						tableType="job-schedules-1"
						defaultColumnNames={defaultColumnNames}
						filterDataOptions={filterOptions}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={jobSchedulesList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={true}
						showExport={false}
						isShowMoreButton
						onFilterClick={handleAndGetFilterOptions}
					/>
					   </Box>
					  <Box marginTop={'10px'}>
					  { !grafanaChartLoading && Object.keys(grafanaChartOptions).length ? (
					<BarChartWidget
					title={'Job Performance'}
					isLoading={grafanaChartLoading}
					data={grafanaChartOptions}
					seriesData={seriesData}
					/>
					) : (
						<Loader />
					)
				}
					  </Box>
					</Stack>
					
				) : !tableLoading && jobSchedulesList.length == 0 && projectDetail.jobCount == 0 ? (
					<ShowInfoWidget title="Add Jobs" height="50vh" />
				) : !tableLoading && jobSchedulesList.length == 0 ? (
					<><br /> <br /> <Typography textAlign='center'>Data will be displayed based on selected filters.</Typography></>
				) : !!tableLoading && (
					<Loader />
				)}
			</Stack>
		</Box>
	);
};

export default ProjectDetails;
