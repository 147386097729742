import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import Overview from 'components/FineOps/overview';
import ExecutiveDashboard from 'components/FineOps/executive-dashboard';
import ProgramSummary from 'components/FineOps/program';
import DetailsSummary from 'components/FineOps/details';
import ResourceMapping from 'components/FineOps/ResourceMapping';

// const Overview = lazy(() => import('components/FineOps/overview'));
// const ExecutiveDashboard = lazy(() => import('components/FineOps/executive-dashboard'));
// const ProgramSummary = lazy(() => import('components/FineOps/program'));
// const DetailsSummary = lazy(() => import('components/FineOps/details'));

const FinOpsRoutes = [
	{
		key: 'fin_oview',
		route: '/finops/overview',
		component: (
			<PrivateRoute
				Component={Overview}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_dest_exec_dash',
		route: '/finops/executive-dashboard',
		component: (
			<PrivateRoute
				Component={ExecutiveDashboard}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_dest_oview',
		route: '/dataestate/financialOverview',
		component: (
			<PrivateRoute
				Component={ExecutiveDashboard}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_prgrm',
		route: '/finops/program',
		component: (
			<PrivateRoute
				Component={ProgramSummary}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_dest_prgm',
		route: '/dataestate/financialProgram',
		component: (
			<PrivateRoute
				Component={ProgramSummary}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_exec_dash',
		route: '/finops/resourceMapping',
		component: (
			<PrivateRoute
				Component={ResourceMapping}
				restrictedRoles={[]}
			/>
		)
	},
	{
		key: 'fin_res_map',
		route: '/finops/details',
		component: (
			<PrivateRoute
				Component={DetailsSummary}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'fin_exec_dash',
		route: '/dataestate/financialDetails',
		component: (
			<PrivateRoute
				Component={DetailsSummary}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	}
];

export default FinOpsRoutes;
