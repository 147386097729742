import React, { useState, useRef, useEffect } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-maskedinput';
import { Popper, Paper, ClickAwayListener, Box } from '@mui/material';
import colors from 'assets/theme/base/colors';
import { ReactComponent as MaskedInputIcon } from '../../assets/clockIcon.svg';
import { ReactComponent as EditPencilIcon } from '../../assets/material-symbols_edit-rounded_white.svg';

type CustomMaskedInputProps = {
	dropdownOptions?: {
		label: string;
		value: string;
		handleItemClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
		isDisabled?: boolean;
	}[];
	isOutlined?: boolean;
} & MaskedInputProps;

const CustomMaskedInput: React.FC<CustomMaskedInputProps> = ({
	dropdownOptions,
	isOutlined = true,
	...maskedInputProps
}) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement | null>(null);

	const handleToggleDropdown = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: any) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<Box
				ref={anchorRef}
				sx={{
					height: '100%',
					display: 'flex',
					width: '100%',
					'& input': {
            position: 'relative',
						background: 'transparent',
						border: 'none',
						borderRadius: '4px',
						color: 'white.main',
						fontFamily: 'Montserrat',
						letterSpacing: '1px',
						padding: '10px',
						width: '100%',
						minWidth: '90px',
						...(isOutlined && {
							outline: `1px solid ${colors.light.diff}`,
							'&.active, &:hover': {
								outline: `1px solid ${colors.info.main}`
							}
						})
					},
          '& .icon-clock': {
            position: 'absolute',
            right: '40px',
            top: '10px',
            zIndex: -1,
            verticalAlign: 'middle',
            '& path': {
              fill: colors.info.main,
            },
          },
          '& .icon-pencil': {
            position: 'absolute',
            right: '15px',
            top: '10px',
            zIndex: -1,
            verticalAlign: 'middle',
          },
				}}
			>
				<MaskedInput
					{...maskedInputProps}
					onClickCapture={(e) => {
						maskedInputProps.onClickCapture?.(e);
						handleToggleDropdown();
					}}
				/>
				<MaskedInputIcon className="icon-clock" />
				<EditPencilIcon className="icon-pencil" />
			</Box>

			{!!dropdownOptions && (
				<Popper open={open} anchorEl={anchorRef.current} placement="bottom">
					<ClickAwayListener onClickAway={handleClose}>
						<Paper sx={{ width: anchorRef?.current?.offsetWidth }}>
							<Box maxHeight={200} height="100%" overflow="auto" bgcolor="primary.main" width="100%">
								{dropdownOptions.map(({ label, value, handleItemClick, isDisabled }) => (
									<Box
										onClick={(e) => {
											handleItemClick?.(e);
											setOpen(false);
										}}
										key={label}
										sx={{
											py: '8px',
											px: '12px',
											cursor: 'pointer',
											'&:hover': {
												bgcolor: 'blue.darker'
											},
											...(isDisabled && {
												pointerEvents: 'none',
												color: 'light.diff'
											})
										}}
									>
										{label}
									</Box>
								))}
							</Box>
						</Paper>
					</ClickAwayListener>
				</Popper>
			)}
		</>
	);
};

export default CustomMaskedInput;
