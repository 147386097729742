import { Typography, TypographyProps } from '@mui/material';

const RowTypography: React.FC<{ children: React.ReactNode; isSuccessor?: boolean } & TypographyProps> = ({
	isSuccessor = false,
	children,
	...props
}) => (
	<Typography
		variant="tableRowFont"
		textOverflow="ellipsis"
		overflow="hidden"
		color={isSuccessor ? 'light.diff' : 'white.main'}
		{...props}
	>
		{children}
	</Typography>
);

export default RowTypography;
