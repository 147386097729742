import { Accordion, AccordionDetails, Box, Button, Dialog, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import { ReactComponent as ViewIcon } from '../../assets/view-detail-img.svg';
import { ReactComponent as ViewHighlightIcon } from '../../assets/icon-view-highlight.svg';
import '../DataPipeline/dataPipeline.scss';
import { getProgramList, getProjectListByProgram } from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Link } from 'react-router-dom';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import CustomAccordionSummary from 'components/Accordion/CustomAccordionSummary';
import CountBox from 'components/Widgets/CountBox';
import MoreOptionMenu from 'components/Dropdown/MoreOptionSecondary';
import RowTypography from 'components/Table/RowTypography';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import eventEmitter from 'SnackBarEventEmitter';

const ProgramList = () => {
	const [projAnchorEl, setProjAnchorEl] = React.useState<null | HTMLElement>(null);
	const projectOpen = Boolean(projAnchorEl);
	const [accordionActiveProjId, setAccordionActiveProjId] = useState<any>('1');
	const [tableActiveProjId, setTableActiveProjId] = useState<string>('99999'); // Use state here
	const [tableProjAnchorEle, setTableProjAnchorEle] = useState<null | HTMLElement>(null); // Use state here
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
	const [controller] = usePepwiseAuthController();
	const { role } = controller;

	const [programList, setProgramList] = useState<any>([]);
	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [loading, setLoading] = useState(false);
	const [filterInfo, setFilterInfo] = useState([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [filterData, setFilterData] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [currentProgram, setCurrentProgram] = useState<any>({});

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getProgramData();
	}, [openModal]);

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
	});


	const getProgramData = async () => {
		setLoading(true);
		let data: any = await getProgramList();
		setLoading(false);
		setProgramList(data.data.programData);
	};

	const handleEditClose = useCallback((data: any) => {
		console.log('CLOSEE', data);
		setOpenModal({
			isOpen: false
		});
	}, []);

	const handleProjectModal = (action: any, e: any, props: any) => {
		e.preventDefault();
		if (props.row) {
			const updatedRow = { ...props.row, activeRow: '99999' };
			setProjectList((prevRows: any) =>
				prevRows.map((prevRow: any) => (prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow))
			);
		}
		console.log('PROOO', props);
		setOpenModal({
			isOpen: true,
			type: 'Project',
			action,
			data: props.row
		});
	};

	const onCreateProgramClick = useCallback(() => {
		setOpenModal({
			isOpen: true,
			type: 'Program',
			action: 'CREATE',
			data: ''
		});
	}, []);

	const handleAction = (type: string, data: any, e: any) => {
		console.log(data);
		handleThreeDotsCloseMenu(e);
		e.preventDefault();
		e.stopPropagation();
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: 'Program',
					action: 'EDIT',
					data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: 'Program',
					action: 'DELETE',
					data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/programs/${data.Program_ID}/${encodeURIComponent(data.program_Name)}`;
				break;

			default:
				break;
		}
	};

	const getProjectsByProgram = async (data: any, e: any, page: any = 1) => {
		console.log('INNNNNNNNNNNNNNN');
		if (e) {
			e?.preventDefault();
			e?.stopPropagation();
		}
		setLoading(true);
		setCurrentProgram(data);
		let result = await getProjectListByProgram(data.Program_ID, page, pageSize);
		setTimeout(() => {
			let table = document.getElementById(`project_list_details${data.Program_ID}`);
			if (table) {
				const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
				if (rdgElement) {
					let jobsLength = result.data.projectList.length;
					let height = 80;
					if (jobsLength === 1) height = jobsLength * 80;
					else if (jobsLength > 1 && jobsLength <= 3) height = jobsLength * 65;
					else if (jobsLength > 3 && jobsLength <= 5) height = jobsLength * 55;
					else if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
					else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
					else height = jobsLength * 30;
					rdgElement.style.height = `${height}px`;
					console.log('HEREE', height);
				}
			}
		}, 100);
		setPaginationData(result.data.page);
		setLoading(false);
		const projData = result.data.projectList.map((list: any) => {
			list['activeRow'] = 0;
			return list;
		});
		setProjectList(projData);
	};

	const defaultColumnNames = ['Project', 'Project Owner', 'Description', 'Action'];

	const handleTableAction = (params: any, type: string) => {
		switch (type) {
			case 'edit':
				break;
			case 'view':
				break;
			case 'delete':
				break;
			default:
				break;
		}
	};

	let newTableActiveProjId = '';
	const handleThreeDotsClickMenu = (
		event: React.MouseEvent<HTMLElement>,
		index: number,
		prgId?: any,
		prjId?: any,
		row?: any,
		props: any = {}
	) => {
		event.preventDefault();
		event.stopPropagation();
		// if (props.row) {
		//   setCurrentProgram(props.row)
		// }
		if (index === 99999) {
			newTableActiveProjId = `${prgId}${prjId}`;
			console.log('EVVV2222', newTableActiveProjId, prgId, prjId);
			console.log(row);
			const updatedRow = { ...row, activeRow: newTableActiveProjId, currentTarget: event.currentTarget };
			setProjectList((prevRows: any) =>
				prevRows.map((prevRow: any) => (prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow))
			);
			console.log(projectList);
			setTableActiveProjId(newTableActiveProjId); // Update state
			setTableProjAnchorEle(event.currentTarget); // Update state
			setAccordionActiveProjId(newTableActiveProjId); // Update accordion
			setProjAnchorEl(event.currentTarget);
		} else {
			setAccordionActiveId(index);
			setAnchorEl(event.currentTarget);
		}
	};

	const gettableActiveProjId = () => {
		return tableActiveProjId;
	};

	const handleThreeDotsCloseMenu = (event: any, props: any = {}) => {
		console.log('PROOOOOOO', props);
		event.preventDefault();
		event.stopPropagation();
		newTableActiveProjId = '99999';
		if (props.row) {
			const updatedRow = { ...props.row, activeRow: newTableActiveProjId, currentTarget: event.currentTarget };
			setProjectList((prevRows: any) =>
				prevRows.map((prevRow: any) => (prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow))
			);
		}

		setAccordionActiveId(null);
		setAnchorEl(null);
		setAccordionActiveProjId(null);
		setProjAnchorEl(null);
		setTableActiveProjId('99999'); // Reset state
		setTableProjAnchorEle(null); // Reset state
	};

	useEffect(() => {
		console.log('DEBUGGGG', currentProgram, openModal, pageSize);
		if (currentProgram.Program_ID && !openModal.isOpen) {
			getProjectsByProgram(currentProgram, false, page || 1);
		}
	}, [openModal]);

	const getColumns = () => {
		return [
			{
				key: 'Project_Name',
				name: 'Project',
				formatter(props: any) {
					return <RowTypography title={props.row.Project_Name}>{props.row.Project_Name ? props.row.Project_Name : '--'}</RowTypography>;
				},
				width: 375
			},
			{
				key: 'Project_Owner',
				name: 'Project Owner',
				formatter(props: any) {
					return (
						<RowTypography title={props.row.Project_Owner
							? props.row.Project_Owner.includes('null')
								? props.row.Project_Owner.replace('(null)', '')
								: props.row.Project_Owner
							: '--'}>
							{props.row.Project_Owner
								? props.row.Project_Owner.includes('null')
									? props.row.Project_Owner.replace('(null)', '')
									: props.row.Project_Owner
								: '--'}
						</RowTypography>
					);
				},
				width: 375
			},
			{
				key: 'Project_Description',
				name: 'Description',
				formatter(props: any) {
					return <RowTypography title={props.row.Project_Description}>{props.row.Project_Description ? props.row.Project_Description : '--'}</RowTypography>;
				},
				minWidth: 250,
				maxWidth: 600
			},
			{
				key: 'action',
				name: 'Action',
				resizable: false,
				width: 100,
				formatter: (props: any) => {
					return (
						<Stack direction="row" alignItems="center" height="100%">
							<Box
								component={Link}
								to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(
									selectedProgramName
								)}/${encodeURIComponent(props.row.Project_Name)}`}
								lineHeight={0}
							>
								<ViewIcon title="Click to view" style={{ width: '24px', height: '24px' }} />
							</Box>
							<MoreOptionMenu
								onClick={(e) =>
									handleThreeDotsClickMenu(e, 99999, props.row.Program_ID, props.row.Project_Id, props.row)
								}
								onClose={(e) => handleThreeDotsCloseMenu(e, props)}
								menuItems={[
									{
										key: 'edit',
										label: 'Edit',
										icon: <EditIcon />,
										onMenuClick: (e) => handleProjectModal('EDIT', e, props)
									},
									{
										key: 'delete',
										label: 'Delete',
										icon: <DeleteIcon />,
										onMenuClick: (e) => handleProjectModal('DELETE', e, props)
									}
								]}
							/>
						</Stack>
					);
				}
			}
		];
	};

	const handleFilter = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSearch = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSorting = (e: any) => {
		console.log('FILTER', e);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectListByProgram(currentProgram.Program_ID, value, pageSize);
	};

	const handlepageSizeChange = (e: any) => {
		console.log('FILTER', e);
	};

	const [expanded, setExpanded] = useState<any>(false);
	const [selectedProgramName, setSelectedProgramName] = useState<string>('');

	const handleChange = (panel: any, programName: string) => (event: any, isExpanded: any) => {
		event.preventDefault();
		event.stopPropagation();
		setSelectedProgramName(programName);
		setExpanded(isExpanded ? panel : false);
		console.log('change');
	};

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader title="Programs" icon={DashboardIcon}>
				{(role === 'Data Operations Admin' || role === 'PepWise System User') && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick}>
						CREATE PROGRAM
					</Button>
				)}
			</PageHeader>
			<Stack p={3} mt={2}>
				<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
					<DataPipelineModal handleEditClose={handleEditClose} programData={openModal} />
				</Dialog>
				{!!programList.length && (
					<Grid container mb={2}>
						<Grid item xs={5} fontWeight={700}>
							Programs
						</Grid>
						<Grid item xs={7} fontWeight={700}>
							Total Projects
						</Grid>
					</Grid>
				)}
				<Box>
					{programList.length ? (
						<Stack
							spacing={2}
							sx={{
								// below mx: 0 is given becoz another css file overriding this component. remove this once all the css files are removed.
								'.MuiPaper-root': {
									mx: '0 !important'
								}
							}}
						>
							{programList.map((program: any, index: number) => {
								return (
									<Accordion
										key={index}
										expanded={expanded === index}
										onChange={handleChange(index, program.program_Name)}
									>
										<CustomAccordionSummary onClick={(e) => getProjectsByProgram(program, e)} id={`panel${index}-header`}>
											<Stack direction="row" justifyContent="center" alignItems="center" width="100%">
												<Box width="40%">
													<Typography lineHeight="normal" borderRight="1px solid #fff">
														{program.program_Name}
													</Typography>
												</Box>
												<Box width="60%">
													<Stack direction="row" justifyContent="space-between" alignItems="center">
														<CountBox count={program.ProjectCount} ml={2} />
														<Stack direction="row" alignItems="center">
															<Link
																to={`/data-pipeline/programs/${program.Program_ID}/${encodeURIComponent(
																	program.program_Name
																)}`}
															>
																<ViewHighlightIcon title="Click to View" />
															</Link>
															{(role === 'Data Operations Admin' || role === 'PepWise System User') && (
																<MoreOptionMenu
																	onClick={(e) => handleThreeDotsClickMenu(e, index)}
																	onClose={handleThreeDotsCloseMenu}
																	menuItems={[
																		{
																			key: 'edit',
																			label: 'Edit',
																			icon: <EditIcon />,
																			onMenuClick: (e) => handleAction('edit', program, e)
																		},
																		{
																			key: 'delete',
																			label: 'Delete',
																			icon: <DeleteIcon />,
																			onMenuClick: (e) => handleAction('delete', program, e)
																		}
																	]}
																/>
															)}
														</Stack>
													</Stack>
												</Box>
											</Stack>
										</CustomAccordionSummary>
										{program.ProjectCount > 0 && (
											<AccordionDetails>
												{!loading ? (
													<CustomizedDataGrid
														tableColumns={getColumns()}
														tableData={projectList}
														tableType="project-list"
														defaultColumnNames={defaultColumnNames}
														onSorting={handleSorting}
														currSorting={sortingInfo}
														filterDataOptions={filterData}
														searchDataValues={searchDetails}
														onFilterCheck={handleFilter}
														currFilter={filterInfo}
														onFilterSearch={handleSearch}
														page={page}
														pageSize={pageSize}
														paginationData={paginationData}
														listLength={projectList.length}
														handlepageSizeChange={handlepageSizeChange}
														handlePageChange={handlePageChange}
														isFetching={loading}
														showExport={false}
														fileName={`Projects.xlsx`}
														isDateRangeFilter={false}
														isDisabled={false}
														isShowMoreButton={false}
													/>
												) : (
													<Loader />
												)}
											</AccordionDetails>
										)}
									</Accordion>
								);
							})}
						</Stack>
					) : !loading ? (
						<ShowInfoWidget title="Create Program" height="60vh" />
					) : (
						<Stack justifyContent="center" alignItems="center" height="60vh">
							<Typography variant="pageTitle" color="dark.main">
								Loading...
							</Typography>
						</Stack>
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export default ProgramList;
