import { Box, BoxProps, lighten, Typography } from '@mui/material';
import { colorsDark } from 'assets/theme/colorUtil';

interface CountProps extends BoxProps {
	count: number | string;
	boxColor?: keyof typeof colorsDark.boxColor;
}

const CountBox = ({ count, boxColor, ...props }: CountProps) => {
	return (
		<Box
			{...props}
			sx={{
				color: `boxColor.${boxColor}.color`,
				backgroundColor: `boxColor.${boxColor}.bgColor`,
				borderRadius: '6px',
				py: '4px',
				width: '50px',
				...props.sx
			}}
		>
			<Typography sx={{ fontSize: '22px', fontWeight: 600, textAlign: 'center' }}>{count}</Typography>
		</Box>
	);
};

export default CountBox;
