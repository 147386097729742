/* eslint no-eval: 0 */
import React, { useState, useMemo, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableHeaderRenderer } from './HeaderRenderers/DraggableHeaderRenderer';
import DataGrid from 'react-data-grid';
import type { HeaderRendererProps } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import './jobs/jobs.css';
import { exportToXlsx } from './exportUtils';
import { PaginationDetails } from './pagination_details';
import { ReactComponent as ClearAllFilterIcon } from '../assets/clearAllFilter-white.svg';
import { ReactComponent as DefaultViewIcon } from '../assets/default-view.svg';
import { ReactComponent as ClearSortIcon } from '../assets/clear-sort.svg';
import { ReactComponent as ExportListIcon } from '../assets/export_small.svg';

import MoreOptionDropdown, { MenuItemType } from './Dropdown/MoreOption';
import { Box, debounce, Stack, Typography } from '@mui/material';

export function CustomizedDataGrid({
	tableColumns,
	tableData,
	tableType,
	onCellEdit,
	defaultColumnNames,
	onSorting,
	currSorting,
	filterDataOptions,
	searchDataValues,
	onFilterCheck,
	currFilter,
	onFilterSearch,
	onHeaderSelectAll,
	onFilterClick,
	showExport,
	fileName,
	page,
	pageSize,
	paginationData,
	listLength,
	handlepageSizeChange,
	handlePageChange,
	isFetching,
	isDateRangeFilter,
	isDisabled,
	onCellClick,
	selectedRowId,
	isShowMoreButton,
	title,
	rowHeight,
	headerRowHeight
}: any) {
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	type Row = (typeof tableData)[0];
	const [columns, setColumns] = useState(tableColumns);
	const [sortColumns, setSortColumns] = useState<any[]>([]);

	useEffect(() => {
		const handleResize = debounce(() => {
			setWindowSize(window.innerWidth);
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const sortedRows = useMemo((): Row[] => {
		if (sortColumns.length === 0) return tableData;

		return [...tableData].sort((a, b) => {
			for (const sort of sortColumns) {
				const comparator = getComparator(sort.columnKey);
				const compResult = comparator(a, b);
				if (compResult !== 0) {
					return sort.direction === 'ASC' ? compResult : -compResult;
				}
			}
			return 0;
		});
	}, [tableData, sortColumns]);

	// function rowKeyGetter(row: Row) {
	// 	return row.id;
	// }

	// const columns = useMemo(() => tableColumns, []);

	type Comparator = (a: Row, b: Row) => number;
	function getComparator(sortColumn: string): Comparator {
		switch (sortColumn) {
			// case 'criticalFlag':
			// 	return (a, b) => {
			// 		return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
			// 	};
			default:
				return (a: any, b: any) => {
					return a[sortColumn].localeCompare(b[sortColumn]);
				};
		}
	}

	const draggableColumns = useMemo(() => {
		function headerRenderer(props: HeaderRendererProps<Row>) {
			return (
				<DraggableHeaderRenderer
					{...props}
					onColumnsReorder={handleColumnsReorder}
					onContextMenu={handleContextClick}
					onFilterMenu={handleFilterClick}
					filterData={filterDataOptions}
					searchData={searchDataValues}
					onFilterCheck={handleFilterValues}
					onFilterSearch={handleSearchValues}
					onHeaderSelectAll={handleHeaderSelectAll}
					onFilterClick={handleAndGetFilterOptions}
					columnNames={tableColumns.map((col: any) => {
						return col.name;
					})}
					visibleColumns={columns.map((col: any) => {
						return col.name;
					})}
					currSorting={currSorting}
					currFilter={currFilter}
					tableColumnKeys={tableColumns.map((col: any) => {
						return col.key;
					})}
					tableType={tableType}
				/>
			);
		}
		function handleContextClick(selectedColumns: any) {
			const currColKeys = columns.map((col: any) => {
				return selectedColumns.includes(col.name) ? col.name : null;
			});
			const currColumns = columns.filter((col: any) => {
				return selectedColumns.includes(col.name);
			});
			tableColumns.map((col: any) => {
				if (selectedColumns.includes(col.name) && !currColKeys.includes(col.name)) {
					currColumns.push(col);
				}
				return null;
			});
			setColumns(currColumns);
			localStorage.setItem(`${tableType}_user_col_pref`, JSON.stringify(currColumns.map((col: any) => col.name)));
		}
		function handleFilterClick(sortingInfo: any) {
			onSorting(sortingInfo);
		}
		function handleFilterValues(filterInfo: any, columnKey: any) {
			onFilterCheck(filterInfo, columnKey);
		}
		function handleSearchValues(searchDetails: any, columnKey: any) {
			onFilterSearch(searchDetails, columnKey);
		}
		function handleHeaderSelectAll(event: any) {
			onHeaderSelectAll(event);
		}
		function handleAndGetFilterOptions(column: any) {
			onFilterClick(column);
		}
		function handleColumnsReorder(sourceKey: string, targetKey: string) {
			const sourceColumnIndex = columns.findIndex((c: any) => c.key === sourceKey);
			const targetColumnIndex = columns.findIndex((c: any) => c.key === targetKey);
			const reorderedColumns = [...columns];
			reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
			setColumns(reorderedColumns);
			localStorage.setItem(`${tableType}_user_col_pref`, JSON.stringify(reorderedColumns.map((col: any) => col.name)));
		}
		return columns.map((c: any) => {
			if (c.key === 'id') return c;
			return { ...c, headerRenderer };
		});
	}, [columns, currSorting, filterDataOptions, currFilter, searchDataValues]);

	function handleEditCell(rows: Row[], index: any) {
		onCellEdit(rows, index);
	}
	function EmptyRowsRenderer() {
		return isFetching ? <></> : <Box width='100vw' textAlign="center" className="records-msg" p={2}>No records found</Box>;
	}

	useEffect(() => {
		const storedPref: any = localStorage.getItem(`${tableType}_user_col_pref`);
		const storedColumnNames: any = JSON.parse(storedPref);
		const preferredColumnNames = storedColumnNames && storedColumnNames.length ? storedColumnNames : defaultColumnNames;
		// Append filltered columnNames
		if (currFilter && currFilter.atRisk) preferredColumnNames.push('Threshold %');
		currFilter &&
			Object.keys(currFilter).forEach((key: any) => {
				if (currFilter[key] && currFilter[key].length) {
					const filteredColumnName = tableColumns.filter((col: any) => col.key === key)[0]?.name;
					if (!preferredColumnNames.includes(filteredColumnName)) preferredColumnNames.push(filteredColumnName);
				}
			});
		if (preferredColumnNames) {
			const preferredColumns = tableColumns.filter((col: any) => {
				return preferredColumnNames.includes(col.name);
			});
			preferredColumns.sort(
				(a: any, b: any) => preferredColumnNames.indexOf(a.name) - preferredColumnNames.indexOf(b.name)
			);
			setColumns(preferredColumns);

			if (filterDataOptions && filterDataOptions.thresholdPercent) filterDataOptions.thresholdPercent = filterDataOptions?.thresholdPercent?.map(String);
		}
	}, []);

	function enableDefaultViewBtn() {
		const setDefaultColumnNames = defaultColumnNames.filter((value: any) => value != null);
		if (setDefaultColumnNames)
			return JSON.stringify(setDefaultColumnNames) !== JSON.stringify(draggableColumns.map((x: any) => x.name));
		else return false;
	}

	const resetView = (event: any) => {
		if (defaultColumnNames) {
			const defaultColumns = tableColumns.filter((col: any) => {
				return defaultColumnNames.includes(col.name);
			});
			setColumns(defaultColumns);
			localStorage.removeItem(`${tableType}_user_col_pref`);
		}
	};

	function enableClearFilter() {
		let enableClear = false;
		if (!currFilter) return false;
		Object.keys(currFilter).forEach((key: any) => {
			if (currFilter[key] && currFilter[key].length) {
				enableClear = true;
				return false;
			}
		});
		return enableClear;
	}

	const handleClearAllFilter = (event: any = null) => {
		const filterQuerySkeleton = {} as any;
		tableColumns.forEach((col: any) => {
			filterQuerySkeleton[col.key] = [];
		});
		onFilterCheck(filterQuerySkeleton, '');
	};

	useEffect(() => {
		const isDrillDownExecution = localStorage.getItem('drill-down-execution');
		const isDrillDownJobs = localStorage.getItem('drill-down-jobs');
		const isDrillDownSchdeule = localStorage.getItem('drill-down-schedules');
		if (isDrillDownExecution === 'false') {
			handleClearAllFilter();
			localStorage.removeItem('drill-down-execution');
		} else if (isDrillDownJobs === 'false') {
			handleClearAllFilter();
			localStorage.removeItem('drill-down-jobs');
		} else if (isDrillDownSchdeule === 'false') {
			handleClearAllFilter();
			localStorage.removeItem('drill-down-schedules');
		}
	}, []);
	function enableClearSoting() {
		return currSorting?.sortBy && currSorting?.sortBy?.length;
	}

	const handleClearSorting = (event: any) => {
		onSorting({ sortBy: '', sortDir: '' });
	};

	const handleCellClick = (args: any, event: any) => {
		onCellClick && onCellClick(args.row);
	};

	const menuItems: MenuItemType[] = [
		{
			key: 'defaultView',
			label: 'Default View',
			icon: <DefaultViewIcon />,
			onClick: resetView,
			disabled: !enableDefaultViewBtn()
		},
		{
			key: 'ClearAllFilter',
			label: 'Clear All Filter',
			icon: <ClearAllFilterIcon />,
			onClick: handleClearAllFilter,
			disabled: !enableClearFilter()
		},
		{
			key: 'ClearSorting',
			label: 'Clear Sorting',
			icon: <ClearSortIcon />,
			onClick: handleClearSorting,
			disabled: !enableClearSoting()
		},
		{
			key: 'exportXLSX',
			label: 'Export to XLSX',
			icon: <ExportListIcon />,
			onClick: async () => {
				await exportToXlsx(fileName, tableData, tableColumns);
			},
			isExportButton: true,
			isShowExportButton: !!showExport,
		}
	];

	const exactPathsExcluded = [
		'/tableau/summary/dashboard',
		'/tableau/performance/dashboard',
		'/tableau/governance/dashboard'
	];

	const partialPathsExcluded = [
		'/tableau/observation',
		'/presto/dashboard',
		'/presto/observation',
		'/databricks/observation',
		'/dataGrowth/observation',
		'/data-pipeline/programs',
		'/data-pipeline/programOps',
		'/finops/details',
		'data-pipeline/search-jobs'
	];

	// TODO: remove below condition after passing isShowMoreButton as props from every component using this dataGrid, instead of checking the paths.
	const isShowMoreOption = !(
		(
			exactPathsExcluded.includes(location.pathname) || // exact match
			partialPathsExcluded.some((path) => location.pathname.includes(path))
		) // partial match
	);

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
				<Typography variant="title1">{title ?? ''}</Typography>
				{(!!isShowMoreButton || isShowMoreOption) && (
					<MoreOptionDropdown menuItems={menuItems} isDisabled={isDisabled} />
				)}
			</Stack>
			<DndProvider backend={HTML5Backend}>
				<Box>
					<Box
						sx={{
							'& .rdg': {
								background: 'transparent',
								border: 'none',
								overflowY: 'auto'
							},
							'.rdg-cell': {
								'&:hover': {
									textOverflow: 'clip',
									overflowX: 'auto !important',
									overflowY: 'hidden !important'
								}
							},
							'& .rdg-header-row': {
								background: '#003771 !important'
							},
							'& .highlightClass .rdg-cell': {
								backgroundColor: '#062242 !important'
							}
						}}
					>
						<DataGrid
							key={windowSize}
							style={{
								height: '100%',
								maxHeight: 'calc(100vh - 250px)',
								width: '100%',
								minHeight: '100px'
							}}
							columns={draggableColumns}
							rows={sortedRows}
							rowHeight={rowHeight}
							headerRowHeight={headerRowHeight}
							defaultColumnOptions={{
								sortable: false,
								resizable: true
							}}
							sortColumns={sortColumns}
							onSortColumnsChange={setSortColumns}
							onRowsChange={handleEditCell}
							onCellClick={handleCellClick}
							rowClass={(row) =>
								row.isChildren || row.isExpanded
									? 'highlightClass'
									: selectedRowId && row.id === selectedRowId
									? 'selectedClass'
									: row.columns_with_drift > 0
									? 'drifted'
									: ''
							}
							renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
						/>
					</Box>
					<PaginationDetails
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={listLength}
						tableType={tableType}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
					/>
				</Box>
			</DndProvider>
		</>
	);
}
