import { CustomizedDataGrid } from 'components/customized_data_grid';
import { useEffect, useState } from 'react';
import { Column } from 'react-data-grid';
import { ReactComponent as EditIcon } from '../../../assets/material-symbols_edit-rounded.svg';
import { Box, FormControl, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { getResourceGroups, getResourceSearchFilterColumn } from 'service/service';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import RowTypography from 'components/Table/RowTypography';

interface Row {
	id: string;
	Resource: string;
	ResourceGroup: string;
	DFProjectcode: string;
	DFSectorname: string;
	Environment: string;
	ProjectName: string;
	BillingInformation: string;
	Subscription: string;
	MeterCategory: string;
	MeterName: string;
	selected: boolean;
	DAI: String;
}

const defaultColumnNames = [
	'HeaderCheckbox',
	'Resource',
	'Resource Group',
	'Sector',
	'Environment',
	'Project',
	'Project Number',
	'Billing Information',
	'Subscription',
	'Meter Category',
	'Meter Name',
	'D & AI'
];
const parseFilter = (filterInfo: any, name?: string) => {
	// Call only filterable columns and reset the filtering in case of wrong/empty column
	const parsedFilter: any = {};
	Object.keys(filterInfo).forEach((key: any) => {
		if (key !== name && filterInfo[key] && filterInfo[key].length) {
			parsedFilter[key] = filterInfo[key];
		}
	});
	return parsedFilter;
};
const TableSection = ({ tableData }: any) => {
	function toggleSubRow(name: number, resourceTypes: any) {
		setResourceGroupList((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.id === name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}
	function getColumns(): Array<Column<any>> {
		return [
			// {
			// 	key: 'selected',
			// 	name: 'HeaderCheckbox',
			// 	formatter: ({ row }) => (
			// 		<input
			// 			type="checkbox"
			// 			className="transparent-checkbox row-checkbox"
			// 			style={{ width: 20 }}
			// 			checked={row.selected}
			// 			onChange={(e) => {
			// 				const checked = e.target.checked;
			// 				const updatedRow = { ...row, selected: checked };
			// 				const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
			// 				if (checked) {
			// 					selectedRows.push(row);
			// 					selectedRowsId.push(row.id);
			// 				} else {
			// 					selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1);

			// 					if (headerCheckboxInput) {
			// 						headerCheckboxInput.checked = false;
			// 					}
			// 				}

			// 				selectedRows = selectedRows.filter((eachRow: any) => {
			// 					return selectedRowsId.includes(eachRow.id);
			// 				});
			// 				if (selectedRows.length == resourceGroupList.length) {
			// 					if (headerCheckboxInput) {
			// 						headerCheckboxInput.checked = true;
			// 					}
			// 				}
			// 				setBulkSelectedRows(selectedRows);

			// 				setResourceGroupList((prevRows) =>
			// 					prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow))
			// 				);
			// 			}}
			// 		/>
			// 	),
			// 	width: 30
			// },
			{
				key: 'Resource',
				name: 'Resource',
				formatter({ row, isCellSelected }) {
					const hasChildren = row.dependentData;
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.dependentData);
									}}
								/>
							)}
							{'   '}
							<RowTypography isSuccessor={!row.dependentData}>{row.Resource}</RowTypography>
						</>
					);
				},
				width: 180
			},
			{
				key: 'ResourceGroup',
				name: 'Resource Group',
				minWidth: 150
			},
			{
				key: 'DFSectorname',
				name: 'Sector',
				minWidth: 140,
				maxWidth: 140,
				width: 140,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.DFSectorname?.length ? (
								<span className="">{props.row.DFSectorname}</span>
							) : (
								<span className="editable_ph">Select Sector</span>
							)}{' '}
						</>
					);
				}
			},

			{
				key: 'Environment',
				name: 'Environment',
				minWidth: 160,
				maxWidth: 160,
				width: 160,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.Environment?.length ? (
								<span className="">{props.row.Environment}</span>
							) : (
								<span className="editable_ph">Select Environment</span>
							)}
						</>
					);
				}
			},
			{
				key: 'ProjectName',
				name: 'Project',
				minWidth: 140,
				width: 140,
				formatter(props) {
					return (
						<>
							{props.row.ProjectName?.length ? (
								<span className="">{props.row.ProjectName}</span>
							) : (
								<span className="editable_ph">Enter Project</span>
							)}
						</>
					);
				},
				cellClass: 'has-editor'
			},
			{
				key: 'DFProjectcode',
				name: 'Project Number',
				minWidth: 180,
				width: 140,
				formatter(props) {
					return (
						<>
							{props.row.DFProjectcode?.length ? (
								<span className="">{props.row.DFProjectcode}</span>
							) : (
								<span className="editable_ph">Enter Project Number</span>
							)}
						</>
					);
				},
				cellClass: 'has-editor'
			},
			{
				key: 'BillingInformation',
				name: 'Billing Information',
				minWidth: 200,
				width: 140,
				formatter(props) {
					return (
						<>
							{props.row.BillingInformation?.length ? (
								<span className="">{props.row.BillingInformation}</span>
							) : (
								<span className="editable_ph">Enter Billing Information</span>
							)}
						</>
					);
				},
				cellClass: 'has-editor'
			},
			{
				key: 'Subscription',
				name: 'Subscription',
				minWidth: 200,
				width: 140
			},
			{
				key: 'MeterCategory',
				name: 'Meter Category',
				minWidth: 200,
				width: 140
			},
			{
				key: 'MeterName',
				name: 'Meter Name',
				minWidth: 250,
				width: 140
			},
			{
				key: 'DAIResource',
				name: 'D&AI',
				minWidth: 250,
				width: 140
			}
		];
	}
	let selectedRows: any = [];
	let selectedRowsId: any = [];
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('bysector');
			searchParams.delete('sector');
			searchParams.delete('taggedproject');
			searchParams.delete('taggedsector');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};
	const [bulkSelectedRows, setBulkSelectedRows] = useState<Row[]>([]);
	const [resourceGroupList, setResourceGroupList] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [searchFilterDetails, setFilterSearchInfo] = useState([]);

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);
	const [searchQuery, setSearchQuery] = useState<{ column: string; value: string }>({ column: '', value: '' });

	const fetchDummyData: () => Promise<any> = async () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(tableData);
			}, 500);
		});
	};

	async function listResourceGroups(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		resourceGroupFilterInfo: any,
		searchQuery: any
	) {
		setLoading(true);
		const drillDownSector = hasDrillDown ? searchParams.get('sector') : '';
		console.log(drillDownSector);
		const drillDownBySector = hasDrillDown ? searchParams.get('bysector') : '';
		const drillDownTaggedToProject = hasDrillDown ? searchParams.get('taggedtoproject') : '';
		const drillDownTaggedToSector = hasDrillDown ? searchParams.get('taggedtosector') : '';

		Object.keys(resourceGroupFilterInfo).forEach((key) => {
			resourceGroupFilterInfo[key] = resourceGroupFilterInfo[key].filter((value: any) => value !== null);
		});

		// const res = await getResourceGroups(
		// 	pageNumber.toString(),
		// 	size.toString(),
		// 	sortingInfo,
		// 	resourceGroupFilterInfo,
		// 	drillDownTaggedToProject,
		// 	drillDownSector,
		// 	drillDownBySector,
		// 	drillDownTaggedToSector,
		// 	null, //resource_group_id,
		// 	searchQuery
		// );
		const res = await fetchDummyData();
		if (res.success) {
			const listData = res.data.result.map((row: any) => ({ ...row, selected: false }));
			setResourceGroupList(listData);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterData(res.data.filterOptions);
			setFilterInfo(resourceGroupFilterInfo);
			setLoading(false);
			const resourceGroupsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: resourceGroupFilterInfo
			};
			localStorage.setItem('resourceGroupsState ', JSON.stringify(resourceGroupsState));
		}
	}

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listResourceGroups(value, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};

	const handlepageSizeChange = (event: any) => {
		listResourceGroups('1', event.target.value, sortingInfo, parseFilter(filterInfo), searchQuery);
	};
	const handleSorting = (sortingInfo: any) => {
		listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};
	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		resetSearchParams();
		listResourceGroups(1, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};
	const handleHeaderSelectAll = (event: any) => {
		selectedRowsId = [];
		selectedRows = [];
		resourceGroupList.map((row: any) => {
			row.selected = event.target.checked;
			if (event.target.checked) {
				selectedRowsId.push(row.id);
			}
		});
		if (event.target.checked) {
			selectedRows = resourceGroupList;
		}
		setResourceGroupList(resourceGroupList);

		let inputElements: any = document.getElementsByClassName('row-checkbox');

		for (let i = 0; i < inputElements.length; i++) {
			if (inputElements[i] instanceof HTMLInputElement) {
				inputElements[i].checked = event.target.checked;
			}
		}
		setBulkSelectedRows(selectedRows);
	};
	const handleAndGetFilterOptions = async (column: any) => {
		let filterOptions: any = {};
		const res = await getResourceSearchFilterColumn(parseFilter(filterInfo), '', '', '', '', null, '', column);
		if (res.success) {
			filterOptions[column] = res.data?.name || [];
			setFilterData(filterOptions);
		}
	};
	async function searchListResourceGroups(searchKeyword: any, columnKey: any) {
		const drillDownSector = hasDrillDown ? searchParams.get('sector') : '';
		console.log(drillDownSector);
		const drillDownBySector = hasDrillDown ? searchParams.get('bysector') : '';
		const drillDownTaggedToProject = hasDrillDown ? searchParams.get('taggedtoproject') : '';
		const drillDownTaggedToSector = hasDrillDown ? searchParams.get('taggedtosector') : '';

		const res = await getResourceSearchFilterColumn(
			parseFilter(filterInfo),
			drillDownTaggedToProject,
			drillDownSector,
			drillDownBySector,
			drillDownTaggedToSector,
			null,
			searchKeyword,
			columnKey
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleFilterSearch = (searchKeyword: any, columnKey: any) => {
		console.log(searchKeyword, columnKey);
		console.log(searchQuery);
		if (searchKeyword?.length >= 3) searchListResourceGroups(searchKeyword, columnKey);
	};
	useEffect(() => {
		listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	}, [tableData]);

	return (
		<CustomizedDataGrid
      title="Resources"
			tableColumns={getColumns()}
			tableData={resourceGroupList}
			defaultColumnNames={defaultColumnNames}
			onSorting={handleSorting}
			currSorting={sortingInfo}
			filterDataOptions={filterData}
			onFilterCheck={handleFilter}
			onHeaderSelectAll={handleHeaderSelectAll}
			onFilterClick={handleAndGetFilterOptions}
			currFilter={filterInfo}
			searchDataValues={searchFilterDetails}
			onFilterSearch={handleFilterSearch}
			page={page}
			pageSize={pageSize}
			paginationData={paginationData}
			listLength={resourceGroupList?.length}
			handlepageSizeChange={handlepageSizeChange}
			handlePageChange={handlePageChange}
			isDateRangeFilter={false}
			isDisabled={false}
			isFetching={loading}
		/>
	);
};

export default TableSection;
