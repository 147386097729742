import {
	Box,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	debounce,
	Typography,
	Radio,
	FormLabel,
	RadioGroup,
	Stack
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteImage from '../../assets/delete-img-modal.svg';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import '../DataPipeline/dataPipeline.scss';
import { useState, useEffect, useMemo } from 'react';
import {
	createDataPipelineProgram,
	createDataPipelineProject,
	deleteDataPipelineProgram,
	deleteDataPipelineProject,
	deleteDataPipelineSchedule,
	editDataPipelineProgram,
	editDataPipelineProject,
	getClarityProjectList,
	getProgramById,
	getProgramSectorList,
	getProjectOwnerList
} from '../../service/service';
import { useNavigate } from 'react-router-dom';
import CustomTextField from 'components/Inputs/CustomTextField';
import LoaderComponent from 'components/Loader';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import eventEmitter from 'SnackBarEventEmitter';

export function DataPipelineModal({ handleEditClose, programData, getToastData, passInfoToParent }: any) {
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [loading, setLoading] = useState<any>({
		sector: false,
		project: false
	});
	const [saveBtnLoader, setSaveBtnLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState<any>({
		description: false,
		owner: false
	});
	const [inputValue, setInputValue] = useState('');
	const [projectOwner, setProjectOwner] = useState('');
	const [value, setValue] = useState(null);
	const [ownerList, setOwnerList] = useState([]);
	const [isClarityProject, setIsClarityProject] = useState(false);
	const [projectList, setProjectList] = useState([]);
	const [projectOptionList, setProjectOptionList] = useState([]);
	const [dropdownLoading, setDropdownLoading] = useState<any>({
		project: false,
		owner: false
	});
	const [formData, setFormData] = useState<any>({
		id: '',
		SectorName: '',
		ProgramName: '',
		projectName: null,
		projectDescription: '',
		projectOwner: '',
		clarityId: ''
	});
	const [formErrors, setFormErrors] = useState({
		estimatedDurationError: ''
	});
	const [toastData, setToastData] = useState<any>({
		message: ''
	});

	const navigate = useNavigate();

	
	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setDropdownLoading({
			project: false,
			owner: false
		})
	});

	useEffect(() => {
		if (programData.type === 'Program') {
			if (programData.action === 'EDIT' || programData.action === 'DELETE') {
				getProgramDetails();
			}
			getSectorData();
		} else if (programData.type === 'Project') {
			if (programData.action === 'EDIT' || programData.action === 'DELETE') {
				getProjectDetails();
				setIsClarityProject(programData.data.Clarity_Project_Id ? false : true);
			}
		}
	}, []);

	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (value, type) => {
				setLoading({
					...loading,
					project: true
				});
				if (type === 'projectName') {
					getProjectList(value);
				} else {
					getOwnerList(value);
				}
				setLoading({
					...loading,
					project: false
				});
			}, 500),
		[]
	);

	useEffect(() => {
		debouncedFetchOptions(projectOwner, 'projectOwner');
	}, [projectOwner, debouncedFetchOptions]);

	useEffect(() => {
		debouncedFetchOptions(inputValue, 'projectName');
	}, [inputValue, debouncedFetchOptions]);

	const getProjectDetails = () => {
		setFormData({
			projectName: programData.data.Project_Name,
			projectDescription: programData.data.Project_Description,
			projectOwner: programData.data.Project_Owner,
			projectId: programData.data.Project_Id
		});
	};

	const getOwnerList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			owner: true
		});
		let result = await getProjectOwnerList(name);
		setDropdownLoading({
			...dropdownLoading,
			owner: false
		});
		let owner: any = [];
		result.data.projectOwnerNames.map((obj: string) => {
			let formattedData = (obj.includes('null') ? obj.replace('(null)', '') : obj).trim();
			owner.push(formattedData);
		});
		setOwnerList(owner);
	};

	const getProjectList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			project: true
		});
		let result = await getClarityProjectList(name);
		setDropdownLoading({
			...dropdownLoading,
			project: false
		});
		setProjectList(result.data.projectList);
		setProjectOptionList(
			result.data.projectList.map((obj: any) => {
				return { label: obj.Project_Name, id: obj.Project_Code };
			})
		);
	};

	const handleInputChange = (event: any, name: any, newInputValue: any) => {
		if (name === 'projectName') {
			setInputValue(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
		} else if (name === 'projectOwner') {
			setProjectOwner(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
		} else {
			setFormData({
				...formData,
				[name]: newInputValue
			});
		}
	};

	const getProgramDetails = async () => {
		let result = await getProgramById(programData.data.Program_ID);
		programData.data = result.data.programData[0];
		setFormData({
			id: programData.data.Program_ID,
			SectorName: result.data.programData[0].Sector,
			ProgramName: result.data.programData[0].program_Name
		});
	};

	const getSectorData = async () => {
		setLoading({
			...loading,
			sector: true
		});
		let sectors: any = await getProgramSectorList();
		setSectorList(sectors.data.sectors);
		setLoading({
			...loading,
			sector: false
		});
	};

	const editProgram = async () => {
		let data = {
			id: formData.id,
			sector: formData.SectorName,
			programName: formData.ProgramName
		};
		await editDataPipelineProgram(data);
	};

	const editProject = async () => {
		let data = {
			id: formData.projectId,
			projectOwner: formData.projectOwner,
			projectName: formData.projectName,
			projectDescription: formData.projectDescription
		};
		await editDataPipelineProject(data);
	};

	const createProgram = async (data: any) => {
		await createDataPipelineProgram(data);

		setSaveBtnLoader(false);
	};

	const createProject = async (data: any) => {
		let result = await createDataPipelineProject(data);

		setSaveBtnLoader(false);
		return result;
	};

	const onSubmit = async (event: any) => {
		if (saveBtnLoader) return;
		setSaveBtnLoader(true);
		event.preventDefault();
		let formPayload;
		let result: any;
		if (programData.action === 'CREATE') {
			if (programData.type === 'Program') {
				formPayload = {
					programName: formData?.ProgramName,
					sector: formData?.SectorName
				};
				result = await createProgram(formPayload);
			} else {
				formPayload = {
					programID: window.location.pathname.split('/')[3].replace(/%20/g, ' '),
					projectName: formData?.projectName?.label || formData?.projectName,
					clarityProId: formData?.clarityId || '',
					projectDescription: formData?.projectDescription,
					projectOwner: formData?.projectOwner
				};
				result = await createProject(formPayload);
				// debugger
				if (result.data?.message) {
					// setToastData({
					//     message: result.data.message
					// })
					getToastData(result);
				}
			}
		} else if (programData.action === 'EDIT') {
			if (programData.type === 'Program') {
				result = await editProgram();
			} else {
				if (inputValue) {
					formData['projectName'] = inputValue;
				}
				result = await editProject();
			}
		} else if (programData.action === 'DELETE') {
			if (programData.type === 'Program') {
				result = await deleteDataPipelineProgram(programData.data.Program_ID);
			} else if (programData.type === 'Project') {
				result = await deleteDataPipelineProject(formData.projectId);
			} else {
				const jobIds = programData.data.map((d: any) => d.jobId);
				const reqBody = {
					bulkProjectId: programData.data[0].Project_Id,
					bulkJobId: jobIds
				};
				result = await deleteDataPipelineSchedule(reqBody);
			}
		}
		setSaveBtnLoader(false);

		handleClose();

		if (programData['from'] === 'view' && programData.type === 'Program') {
			navigate('/data-pipeline/programs');
		} else if (programData['from'] === 'delete' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'view' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'delete' && programData.type === 'Schedule') {
			passInfoToParent();
		}
	};

	const isFormValid = () => {
		return formData.ProgramName && formData.ProgramName.length <= 100 && formData.SectorName;
	};

	const isProjectFormValid = () => {
		// return true
		//formData.clarityId ? true :
		return (
			formData.projectName && formData.projectName.length <= 100 && formData.projectDescription && formData.projectOwner
		);
	};

	const handleChange = (event: any, newValue: any = '', type: string = '') => {
		if (newValue === null) {
			setIsDisabled({
				description: false,
				owner: false
			});
			if (!isClarityProject && type === 'projectName') {
				setFormData({
					...formData,
					projectName: '',
					projectOwner: '',
					projectDescription: ''
				});
				setIsDisabled({
					description: true,
					owner: true
				});
			}

			return;
		}
		if (type) {
			if (type === 'projectOwner') {
				setFormData({
					...formData,
					projectOwner: ''
				});
			} else if (type === 'projectDescription') {
				setFormData({
					...formData,
					projectDescription: ''
				});
			}

			if (type === 'projectName') {
				setIsDisabled({
					description: false,
					owner: false
				});
				if (newValue?.id) {
					let data: any = projectList.filter((obj: any) => obj.Project_Code === newValue.id);
					setFormData({
						...formData,
						projectDescription: data[0].Project_Description || '',
						projectOwner: data[0].EMPLOYEE_NAME,
						projectName: data[0].Project_Name,
						clarityId: data[0].Project_Code
					});
					setIsDisabled({
						description: true,
						owner: true
					});
				}
			} else if (type === 'projectOwner') {
				setFormData({
					...formData,
					[type]: newValue
				});
			}
		} else {
			const { name, value } =
				event && event.target
					? event.target.type === 'checkbox'
						? { name: event.target.name, value: event.target.checked }
						: event.target
					: '';

			setFormData({
				...formData,
				[name]: value
			});
		}

		setFormErrors({ estimatedDurationError: '' });
	};

	const handleClose = () => {
		handleEditClose();
	};

	const getTitle = () => {
		programData.action === 'DELETE'
			? `Deleting ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name
			  }`
			: programData.action === 'EDIT'
			? `Edit ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name
			  }`
			: programData.type !== 'Project'
			? 'Add Project'
			: `Create New ${programData.type}`;
		switch (programData.action) {
			case 'DELETE':
				return `Deleting ${
					programData.type == 'Schedule' && programData.data.length > 1 ? 'Jobs' : ''
				} ${
					programData.type === 'Program'
						? programData.data?.program_Name
						: programData.type === 'Project'
						? programData.data?.Project_Name
						: programData.data.length > 1
						? ''
						: programData.data[0].jobName
				}`;
			case 'EDIT':
				return `Edit ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name
				}`;

			default:
				return programData.type === 'Program' ? `Create New ${programData.type}` : 'Add Project';
		}
	};

	const getMsg = () => {
		return (
			<Box component="span">
				project from{' '}
				<Box component="span" sx={{ fontWeight: '800' }}>
					{programData.data.ProgramName}
				</Box>
				?
			</Box>
		);
	};

	const onCheckBoxChange = (e: any) => {
		const { checked } = e.target;
		setIsClarityProject(checked);
		setFormData({
			...formData,
			projectName: '',
			projectOwner: '',
			projectDescription: ''
		});
		if (checked) {
			setIsDisabled({
				description: false,
				owner: false
			});
		} else {
			setIsDisabled({
				description: true,
				owner: true
			});
		}
	};

	const getLabel = () => {
		return (
			<Box>
				Project Name*{' '}
				<Box component="span" sx={{ fontStyle: 'italic' }}>
					{' '}
					(Search & Select from Clarity)
				</Box>
			</Box>
		);
	};

	const getContent = () => {
		switch (programData.action) {
			case 'EDIT':
			case 'CREATE':
				if (programData.type === 'Program') {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={2} bgcolor="blue.darker">
							<DialogContent>
								<Stack spacing={2}>
									<CustomTextField
										error={formData.ProgramName?.length > 100}
										label={
											<Box>
												Program Name *
												<Box component="span" fontSize="0.75rem">
													{' '}
													(Max 100 characters)
												</Box>
											</Box>
										}
										id="ProgramName"
										name="ProgramName"
										variant="outlined"
										autoComplete="off"
										value={formData.ProgramName}
										onChange={handleChange}
										fullWidth
										helperText="Program name can't contain more than 100 characters."
									/>
									<FormControl fullWidth>
										<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' } }}>
											Select Sector *
										</FormLabel>
										<RadioGroup name="SectorName" value={formData.SectorName} onChange={handleChange}>
											<Grid container maxWidth="sm">
												{loading.sector ? (
													<LoaderComponent />
												) : (
													sectorList.map((sector) => (
														<Grid item xs={3} key={sector}>
															<FormControlLabel
																value={sector}
																control={<Radio variant="pepwiseRadio" />}
																label={sector}
															/>
														</Grid>
													))
												)}
											</Grid>
										</RadioGroup>
									</FormControl>
								</Stack>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button disabled={!isFormValid()} type="submit" variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				} else {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={2} bgcolor="blue.darker">
							<DialogContent>
								<Grid container spacing={2}>
									{programData.action != 'EDIT' && (
										<Grid item xs={12}>
											<FormControlLabel
												label="Create New Project"
												control={
													<Checkbox
														sx={{
															color: 'white.main',
															'&.Mui-checked': {
																color: 'primary.focus',
																'& .MuiSvgIcon-root': {
																	color: 'white.main',
																},
															},
														}}
														value="all"
														checked={isClarityProject}
														onChange={(e) => {
															onCheckBoxChange(e);
														}}
													/>
												}
											/>
										</Grid>
									)}
									{!isClarityProject ? (
										<Grid item xs={12}>
											<FormControl fullWidth>
												<CustomAutocomplete
													textFieldLabel={getLabel()}
													loading={dropdownLoading.project}
													options={projectOptionList}
													value={formData.projectName}
													onInputChange={(e, val) => handleInputChange(e, 'projectName', val)}
													onChange={(e, val) => handleChange(e, val, 'projectName')}
													noOptionsText="No project found"
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={12}>
											<CustomTextField
												id="ProjectName"
												label={
													<Box display="flex" alignItems="center">
														Project Name *
														<Typography variant="body2" fontSize="0.75rem" sx={{ marginLeft: 1 }}>
															(Max 100 characters)
														</Typography>
													</Box>
												}
												name="projectName"
												variant="outlined"
												autoComplete="off"
												value={formData.projectName}
												error={formData.projectName?.length > 100}
												helperText="Project name can't contain more that 100 characters."
												onChange={handleChange}
											/>
										</Grid>
									)}

									<Grid item xs={6}>
										<FormControl fullWidth>
											<CustomTextField
												id="ProjectDescription"
												label="Project Description"
												name="projectDescription"
												variant="outlined"
												autoComplete="off"
												disabled={(!isClarityProject && programData.action != 'EDIT') ||isDisabled.description}
												value={formData.projectDescription}
												required
												onChange={handleChange}
											/>
										</FormControl>
									</Grid>
									{!isClarityProject && programData.action != 'EDIT' ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomTextField
													id="ProjectOwner"
													label="Project Owner"
													name="projectOwner"
													variant="outlined"
													autoComplete="off"
													value={formData.projectOwner}
													disabled
													required
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													loading={dropdownLoading.owner}
													options={ownerList}
													noOptionsText="No user found"
													value={formData.projectOwner}
													onChange={(e, val) => handleChange(e, val, 'projectOwner')}
													onInputChange={(e, val) => handleInputChange(e, 'projectOwner', val)}
													disabled={isDisabled.owner}
													textFieldLabel="Project Owner *"
												/>
											</FormControl>
										</Grid>
									)}
								</Grid>
							</DialogContent>

							<DialogActions>
								<Button onClick={handleClose} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button disabled={!isProjectFormValid()} type="submit" variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				}

			case 'DELETE':
				return (
					<Box p={2} bgcolor="blue.darker">
						<DialogContent>
							<Stack direction="row" spacing={2}>
								<Box component="img" src={DeleteImage} alt="" />
								<Stack spacing={2}>
									<Typography fontSize="28px" fontWeight={600}>
										Are you sure to delete{' '}
										{programData.type === 'Program' ? (
											'this ' + programData.type
										) : programData.type === 'Project' ? (
											<Box component="span">
												this project from{' '}
												<Box component="span" sx={{ fontWeight: '800' }}>
													{programData.data.ProgramName}
												</Box>
												?
											</Box>
										) : programData.data.length > 1 ? (
											programData.data.length + ' jobs from the project?'
										) : (
											' this job from the project?'
										)}
									</Typography>
									<Typography variant="body1">Once confirmed, the action can't be undone.</Typography>
								</Stack>
							</Stack>
						</DialogContent>

						<DialogActions>
							<Button onClick={handleClose} variant="pepwiseOutline">
								NO, I WANT IT!
							</Button>
							<Button onClick={onSubmit} variant="pepwiseError">
								{saveBtnLoader ? 'Deleting...' : 'Delete'}
							</Button>
						</DialogActions>
					</Box>
				);

			default:
				break;
		}
	};

	const getToastMsg = () => {
		return (
			<Box p={2} bgcolor="blue.darker">
				<DialogContent>
					<Typography>{toastData.message}</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="pepwiseOutline">
						NO, I WANT IT!
					</Button>
					<Button onClick={onSubmit} variant="pepwiseError">
						{saveBtnLoader ? 'Deleting...' : 'Delete'}
					</Button>
				</DialogActions>
			</Box>
		);
	};

	return (
		<>
			<DialogTitle bgcolor="primary.main" sx={{ p: '8px 16px !important' }}>
				<Box display="flex" alignItems="center">
					<Typography variant="widgetTitle" flexGrow={1}>
						{getTitle()}
					</Typography>
					<Box>
						<IconButton onClick={handleClose} sx={{ color: 'white.main' }}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>

			{!loading.project ? (
				toastData.message ? (
					getToastMsg()
				) : (
					getContent()
				)
			) : (
				<Box sx={{ padding: '40px 20px', textAlign: 'center', fontSize: '14px', backgroundColor: '#0c0f25' }}>
					Fetching Data...
				</Box>
			)}
		</>
	);
}
