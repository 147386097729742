import { Box, CircularProgress } from '@mui/material';

const LoaderComponent = ({ size = 40 }: { size?: number }) => (
	<Box
		sx={{
			display: 'inline-block',
			alignItems: 'center',
			alignContent: 'center',
			textAlign: 'center',
			width: '100%'
		}}
		minHeight={`${size}px`}
	>
		<CircularProgress size={size} color="info" />
	</Box>
);

export default LoaderComponent;
