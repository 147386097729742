import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	debounce,
	Dialog,
	Divider,
	FormControl,
	Grid,
	ListItemText,
	makeStyles,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
	Theme,
	Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TeradataIcon from '../../assets/icon-teradata.svg';
import DatabricksIcon from '../../assets/databricks.svg';
import PowerBiIcon from '../../assets/PowerBI.svg';
import AzureIcon from '../../assets/azure-adf.svg';
import {ReactComponent as PlusSymbolIcon} from '../../assets/plus-blue.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import {
	getSearchScheduleJobNameList,
	getTeradataJobNameList,
	linkAddScheduleToProject,
	getNormalCompletionTime,
	getAddedSchedules,
	getAddedScheduleSearchFilterColumn,
	getDPdatasetIdList,
	getDPworkspaceIdList,
	getDPcapacityIdList,
	getSearchSchedulePowerBiList,
	linkAddPowerBiScheduleToProject
} from '../../service/service';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link, useParams } from 'react-router-dom';
import useStyles from './data-pipeline-styles';
import { ReactComponent as EditPencilIcon } from '../../assets/material-symbols_edit-rounded_white.svg';

import { ReactComponent as MaskedInputIcon } from '../../assets/clockIcon.svg';

import MaskedInput from 'react-maskedinput';
import { getHoursList } from 'utils/common-util';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import CustomDropdown from 'components/Dropdown';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import AddIcon from '@mui/icons-material/Add';
import OutlinedButton from 'components/Button/OutlinedButton';
import CloseIcon from '@mui/icons-material/Close';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import colors from 'assets/theme/base/colors';
import CustomMaskedInput from 'components/Dropdown/MaskedInput';
import TableCheckbox from 'components/Table/TableCheckbox';
import RowTypography from 'components/Table/RowTypography';
import DialogModal from 'components/Modal/DialogModal';
import LoaderComponent from 'components/Loader';
import eventEmitter from "SnackBarEventEmitter"
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { ReactComponent as BlueSearchIcon } from '../../assets/icons_search_blue.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-triangle-down.svg';
import { ReactComponent as SuccessTicketIcon } from '../../assets/icon-vector.svg';

function getStyles(name: string, personName: readonly string[], theme: Theme) {
	return {
		fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
	};
}

const dropdownSxProps = {
	borderColor: 'light.main',
	borderWidth: '1px',
	borderStyle: 'solid',
	'&.MuiInputBase-root': {
		border: 'none'
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'rgba(113, 117, 143, 0.8) !important'
	},
	'& .MuiSelect-select': {
		fontSize: '14px',
		fontWeight: '500',
		color: '#F5F5F5 !important'
	},
	'& .MuiSelect-icon': {
		top: 'calc(50% - .7em)'
	}
};

const autocompleteSxProps = {
	'& .MuiInputBase-root': {
		pt: .5,
		pb: .2,
		px: 1
	}
};
const autocompleteTextFieldProps = {
	sx: {
		'& .MuiOutlinedInput-root': {
			fontSize: '14px',
			fontWeight: '500',
			'& fieldset': {
				// !important tag is given becoz style is getting overridden by some css files
				borderColor: `rgba(113, 117, 143, 0.8) !important`
			},
			'&:hover fieldset': {
				borderColor: 'light.main'
			},
			'&.Mui-focused fieldset': {
				borderColor: 'light.main'
			}
		}
	}
};

const AddSchedules = () => {
	// interface Row {
	// 	id: number;
	// 	selected: boolean;
	// 	jobName: string;
	// 	schedulerName: string;
	// 	schedule: string;
	// 	normalCompletionTime: string;
	// 	freqExecution: string;
	// 	jobId: number;
	// 	avgExeTime: number;
	// }

	interface Row {
		id: number;
		selected: boolean;
		jobName: string;
		scheduledDays:string;
		scheduledTimes: string;
		runWindow: string;
		frequency: string;
		SLAODate: string;
		SLATime: string;
		Scheduler: string;
		jobId: string;
		scheduleInfo: string;
		cyclic: string;
		dsName: string;
		dsId: string;
		enabled: boolean;
		workspaceName: string;
		capacityName: string;
	}

	interface Filter {
		filterType: string;
		keywords: string | Array<string>;
		fromTime?: any;
		fromTimeValue?: string;
		toTime?: any;
		toTimeValue?: string;
		id?: number;
		operation?: string;
		type?: string;
		subType?: string;
	}

	interface AddedScheduleData {
		Job_Name: string;
		jobName: string;
		Platform_Id: number;
		startTime: string;
		normalCompletionTime: number;
		scheduler: string;
		dependentData: any;
		isExpanded: boolean;
		children: AddedScheduleData[];
		ischildren?: boolean;
		Dep_Job_Name?: string;
		Project_Schedule_ID: number;
		Primary_Project_Schedule_Id: number;
		Days: number;
		Start_Time: number;
		Normal_Completion_Time: number;
		Scheduler_Name: string;
		schedulerName: string;
		slaDate: number;
		slaTime: number;
		platformId: number;
		scheduleInfo: string;
		priCyclic: string;
	}

	const { prgmid, prjid, prgname, prjname } = useParams();

	let selectedRowsId: any = [];

	const ITEM_HEIGHT = 38;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
			}
		}
	};

	const platformList = ['Teradata', 'Power BI'];

	const [filterCriteriaList, setFilterCriteriaList] = React.useState<any>(['Scheduler Name', 'Job Name']);

	const [timeList, setTimeList] = useState<any>(getHoursList);
	const [addedSchedulesList, setAddedSchedulesList] = useState<AddedScheduleData[]>([]);
	// const defaultColumnNames = ['HeaderCheckbox','Job Name','Scheduler','Start Time (CST)', 'Average Execution Time','SLA Completion Time (CST)'];
	const defaultColumnNames = ['HeaderCheckbox','Jobs','Schedule Info', 'SLA Date (ODate+)', 'SLA Time (CST)', 'Scheduler', 'Cyclic'];
    const PowerBiColumnNames = ['HeaderCheckbox', 'Jobs', 'Capacity', 'Workspace', 'Schedule Info', 'Enabled'];

    const [daysList, setDaysList]  = React.useState(['All', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);

	const [selectedRows, setSelectedRows] = useState<Row[]>([]);
	const [tableDataList, setTableDataList] = useState<Row[]>([]);
	const [jobDataList, setJobDataList] = useState<any[]>([]);
	const [jobNameList, setJobNameList] = useState<any[]>([]);
	const [datasetList, setDatasetList] = useState<any[]>([]);
	const [workspaceList, setWorkspaceList] = useState<any[]>([]);
	const [capacityList, setCapacityList] = useState<any[]>([]);
	const [datasetNameList, setDatasetNameList] = useState<any[]>([]);
	const [workspaceNameList, setWorkspaceNameList] = useState<any[]>([]);
	const [capacityNameList, setCapacityNameList] = useState<any[]>([]);
	const [platformName, setPlatformName] = React.useState<string>('Teradata');
	const [criteriaName, setCriteriaName] = React.useState<string>('');
	const [operationName, setOperationName] = React.useState<string>('');
	const [keywordName, setKeywordName] = React.useState<string>('');
	const [searchFilterDetails, setFilterSearchInfo] = useState([]);
	const [addClassFilterHoverEffect, setAddClassFilterHoverEffect] = React.useState<boolean>(false);

	const [openId, setOpenId] = React.useState<number>();

	const [requestBody, setRequestBody] = React.useState<any>();
	const [successMsg, setSuccessMsg] = React.useState<string>('');

	const [hasDaysAdded, setHasDaysAdded] = React.useState<boolean>();
	const [hasTimeAdded, setHasTimeAdded] = React.useState<boolean>();
	const [hasSchedulerAdded, setHasSchedulerAdded] = React.useState<boolean>(true);

	const [tableLoading, setTableLoading] = React.useState<boolean>();
	const [addProjectLoading, setAddProjectLoading] = React.useState<boolean>();
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });

	const [selectedJobName, setSelectedJobName] = React.useState<boolean>(false);
	const [selectedDatasetName, setSelectedDatasetName] = React.useState<boolean>(false);
	const [selectedWorkspaceName, setSelectedWorkspaceName] = React.useState<boolean>(false);
	const [selectedCapacityName, setSelectedCapacityName] = React.useState<boolean>(false);
	const [searchScheduleBtn, setSearchScheduleBtn] = React.useState<boolean>();
	const [jobSearchKeyword, setJobSearchKeyword] = React.useState<string>('');
	const [datasetSearchKeyword, setDatasetSearchKeyword] = React.useState<string>('');
	const [workspaceSearchKeyword, setWorkspaceSearchKeyword] = React.useState<string>('');
	const [capacitySearchKeyword, setCapacitySearchKeyword] = React.useState<string>('');
	const [searchJobNameInputLoading, setSearchInputJobNameLoading] = React.useState<boolean>();
	const [searchDatasetInputLoading, setSearchInputDatasetLoading] = React.useState<boolean>();
	const [searchWorkspaceInputLoading, setSearchInputWorkspaceLoading] = React.useState<boolean>();
	const [searchCapacityInputLoading, setSearchInputCapacityLoading] = React.useState<boolean>();

	const [filterCriteria, setFilterCriteria] = React.useState<Filter[]>([]);

	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');
	const [pageSize, setPageSize] = useState('100');

	const [paginationDataSchedule, setPaginationDataSchedule] = useState({ totalElements: 0, totalPages: 0 });
	const [pageSchedule, setPageSchedule] = useState('1');
	const [pageSizeSchedule, setPageSizeSchedule] = useState('100');
	const [scheduleTableLoading, setScheduleTableLoading] = React.useState<boolean>();
	let reqRows: Row[] = [];

	let isTimeMenuId = 99999999;
	const [isFromTimeActive, setIsFromTimeActive] = useState(false);
	const [isEndTimeActive, setIsEndTimeActive] = useState(false);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: 'desc' });
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterOptions, setFilterOptions] = useState<any>({});

    eventEmitter.on('showSnackbar', (message: string) => {
		setTableLoading(false);
		setScheduleTableLoading(false)
        setSearchInputJobNameLoading(false)
        setAddProjectLoading(false)
	});

	function getColumns(): Array<Column<Row>> {
        return [
			{
				key: 'selected',
				name: 'HeaderCheckbox',
				formatter: ({ row }) => (
					<TableCheckbox
						checked={row.selected}
						onChange={(e) => {
							const checked = e.target.checked;
							const updatedRow = { ...row, selected: checked };
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
							let tempSelectedRows = [...reqRows];
							if (checked) {
								let result = tempSelectedRows.filter(
									(obj: any) => obj.jobId === updatedRow.jobId
								);
								if (!result.length) {
									tempSelectedRows.push(updatedRow);
									console.log("TEMPPP", updatedRow)
									selectedRowsId.push(updatedRow.id);
								}
							} else {
								selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1);
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = false;
								}
							}

							tempSelectedRows = tempSelectedRows.filter((eachRow: any) => {
								return selectedRowsId.includes(eachRow.id);
							});
							if (tempSelectedRows.length == tableDataList.length) {
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = true;
								}
							}
							// let result = findDuplicates(tempSelectedRows)
							reqRows = tempSelectedRows;
							setSelectedRows(tempSelectedRows);
							setTableDataList((prevRows) =>
								prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow))
							);
						}}
					/>
				),
				width: 40,
				maxWidth: 100
			},
			{
				key: 'jobName',
				name: 'Jobs',
				formatter(props) {
					return <RowTypography>{props.row.jobName ? props.row.jobName : '--'}</RowTypography>;
				},
				minWidth: 200,
				maxWidth: 550
			},
			{
				key: 'scheduleInfo',
				name: 'Schedule Info',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize">{props.row.scheduleInfo ? props.row.scheduleInfo : '--'}</RowTypography>
					);
				},
				minWidth: 200,
				maxWidth: 550
			},
			{
				key: 'SLAODate',
				name: 'SLA Date (ODate+)',
				formatter({row}) {
					
					const classes = useStyles();
					return (
						// <RowTypography textTransform="capitalize">{props.row.slaDate ? props.row.slaDate : '--'}</RowTypography>
						<TextField id="outlined-basic" type='number' variant="outlined" defaultValue={0} inputProps={{ min: 0, max: 1 }} className={classes.customInput} onChange={(e) => onChangeSlaDate(e, row, true)} />
					);
				},
				maxWidth: 250
			},
			{
				key: 'SLATime',
				name: 'SLA Time (CST)',
				formatter: ({ row }) => {
					return (
						<CustomMaskedInput
							mask="11:11"
							placeholder="hh:mm"
							name="time"
							value={row.SLATime}
							onChange={(e) => onChangeNormalComplTime(e, row, true)}
							dropdownOptions={timeList?.map((time: string) => ({
								label: time,
								value: time,
								handleItemClick: (e: any) => onChangeNormalComplTime(e, row)
							}))}
							isOutlined={false}
						/>
					);
				},
				maxWidth: 250
			},
			{
				key: 'Scheduler',
				name: 'Scheduler',
				formatter(props) {
					return <RowTypography>{props.row.Scheduler == 'AutoSys' ? 'AutoSys' : 'Control-M'}</RowTypography>;
				},
				maxWidth: 250
			},
			{
				key: 'cyclic',
				name: 'Cyclic',
				formatter(props) {
					return <RowTypography>{props.row.cyclic == '0' || !props.row.cyclic ? 'No' : 'Yes'}</RowTypography>;
				},
				maxWidth: 250
			},
		];
	}

	function getPowerBiColumns(): Array<Column<Row>> {
        return [
			{
				key: 'selected',
				name: 'HeaderCheckbox',
				formatter: ({ row }) => (
					<TableCheckbox
						checked={row.selected}
						onChange={(e) => {
							const checked = e.target.checked;
							const updatedRow = { ...row, selected: checked };
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
							let tempSelectedRows = [...reqRows];
							if (checked) {
								let result = tempSelectedRows.filter(
									(obj: any) => obj.dsId === updatedRow.dsId
								);
								if (!result.length) {
									tempSelectedRows.push(updatedRow);
									selectedRowsId.push(updatedRow.id);
								}
							} else {
								selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1);
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = false;
								}
							}

							tempSelectedRows = tempSelectedRows.filter((eachRow: any) => {
								return selectedRowsId.includes(eachRow.id);
							});
							if (tempSelectedRows.length == tableDataList.length) {
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = true;
								}
							}
							// let result = findDuplicates(tempSelectedRows)
							reqRows = tempSelectedRows;
							setSelectedRows(tempSelectedRows);
							setTableDataList((prevRows) =>
								prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow))
							);
						}}
					/>
				),
				width: 40,
				maxWidth: 100
			},
			{
				key: 'dsName',
				name: 'Jobs',
				formatter(props) {
					return <RowTypography title={props.row.dsName}>{props.row.dsName ? props.row.dsName : '--'}</RowTypography>;
				},
				minWidth: 200,
				maxWidth: 550
			},
			{
				key: 'capacityName',
				name: 'Capacity',
				formatter(props) {
					return <RowTypography title={props.row.capacityName}>{props.row.capacityName ? props.row.capacityName : '--'}</RowTypography>;
				},
				minWidth: 200,
				maxWidth: 450
			},
			{
				key: 'workspaceName',
				name: 'Workspace',
				formatter(props) {
					return <RowTypography title={props.row.workspaceName}>{props.row.workspaceName ? props.row.workspaceName : '--'}</RowTypography>;
				},
				minWidth: 200,
				maxWidth: 450
			},
			{
				key: 'scheduleInfo',
				name: 'Schedule Info',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize"
						title={props.row.scheduleInfo ? props.row.scheduleInfo : '--'}>{props.row.scheduleInfo ? props.row.scheduleInfo : '--'}</RowTypography>
					);
				},
				maxWidth: 550
			},
			{
				key: 'enabled',
				name: 'Enabled',
				formatter(props) {
					return <RowTypography>{props.row.enabled ? 'Yes' : 'No'}</RowTypography>;
				},
				maxWidth: 250
			},
		];
	}

	const getDays = (day: number) => {
		let days;
		if (day == 0) {
			days = 'Sun';
		} else if (day == 1) {
			days = 'Mon';
		} else if (day == 2) {
			days = 'Tue';
		} else if (day == 3) {
			days = 'Wed';
		} else if (day == 4) {
			days = 'Thur';
		} else if (day == 5) {
			days = 'Fri';
		} else if (day == 6) {
			days = 'Sat';
		}
		return days;
	};

	const defaultAddedScheduleColumnNames = [
		'Jobs',
		'Platform',
		'Schedule Info',
		'Scheduler',
		'SLA Date (ODate+)',
		'SLA Time (CST)',
		'Cyclic'
	];

	function getAddedScheduleColumns(): Array<Column<AddedScheduleData>> {
		return [
			{
				key: 'jobName',
				name: 'Jobs',
				// formatter(props) {
				// 	return <div title={props.row.jobName}>{props.row.jobName ? props.row.jobName : '--'}</div>
				// },
				formatter({ row, isCellSelected }) {
					const hasChildren = row.dependentData && row.dependentData.length ? true : false;
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(String(row.jobName), row.dependentData);
									}}
								/>
							)}
							{'   '}
							<Link to="" state={row} style={{ paddingLeft: hasChildren ? '5px' : '20px', paddingRight: '10px' }}>
								<RowTypography title={row.jobName}>{row.jobName}</RowTypography>
							</Link>{' '}
						</>
					);
				},
				maxWidth: 550
			},
			{
				key: 'platformId',
				name: 'Platform',
				formatter(props) {
					return (
						<RowTypography>
							{ props.row.platformId == 1 ? <img src={TeradataIcon} style={{ width: '18px', verticalAlign: 'middle' }} /> :
							<img src={PowerBiIcon} style={{ width: '18px', verticalAlign: 'middle' }} /> } { ' ' }
							{props.row.platformId == 1 ? 'Teradata' : 'Power BI'}
						</RowTypography>
					);
				},
				maxWidth: 250
			},
			{
				key: 'slaDate',
				name: 'SLA Date (ODate+)',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize">
							{props.row.slaDate == null ? '--' : props.row.slaDate}
						</RowTypography>
					);
				},
				maxWidth: 250
			},
			{
				key: 'slaTime',
				name: 'SLA Time (CST)',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize">
							{props.row.slaTime == null ? '--' : String(props.row.slaTime)
								? `${convertToTimeString(props.row.slaTime)}`
								: '--'}
						</RowTypography>
					);
				},
				maxWidth: 250
			},
			{
				key: 'scheduleInfo',
				name: 'Schedule Info',
				formatter(props) {
					return (
						<RowTypography textTransform="capitalize" title={props.row.scheduleInfo ? props.row.scheduleInfo : '--'}>
							{props.row.scheduleInfo ? props.row.scheduleInfo : '--'}</RowTypography>
					);
				},
				maxWidth: 550
			},

			{
				key: 'schedulerName',
				name: 'Scheduler',
				formatter(props) {
					return (
						<RowTypography>
							{props.row.platformId == 1 ? props.row.schedulerName : '--'}
						</RowTypography>
					);
				},
				maxWidth: 250
			},
			{
				key: 'priCyclic',
				name: 'Cyclic',
				formatter(props) {
					return (
						<RowTypography>
							{props.row.platformId == 1 ? props.row.priCyclic == '0' || props.row.priCyclic == null ? 'No' : 'Yes': '--'}
						</RowTypography>
					);
				},
				maxWidth: 200
			}

		];
	}

	function compareTimes(time1: string, time2: string) {
		const format = 'HH:mm';

		// Parse both times using moment
		const momentTime1 = moment(time1, format);
		const momentTime2 = moment(time2, format);
		const momentTime = momentTime1.add(30, 'minutes');

		// Compare and return true only if time1 is strictly greater than time2
		return momentTime.isAfter(momentTime2);
	}

	function toggleSubRow(name: string, resourceTypes: any) {
		setAddedSchedulesList((prev) => {
			const rows = prev;
			const rowIndex = rows.findIndex((r: any) => r.jobName == name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: AddedScheduleData[] = [];
			let childRows: AddedScheduleData[] = [];
			if (!row.isExpanded) {
				if (resourceTypes.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
			} else {
				children = row.children;
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
			}
			return newRows;
		});
	}
	let addSchData: any = [];
	
	const getAddedScheduleList = async (
		page: number = 1,
		size: number = 100,
		filter: any = {},
		sortingInfo: any = {}
	) => {
		let colFilterInfo = filter;
		Object.entries(filter).forEach(([key, value]: any) => {
			let platformIdVal: any = [];
			let cyclicVal: any = [];
			if (key == 'platformId') {
				value.filter((id: string) => {
					if (id == 'Teradata') {
						platformIdVal.push(1)
					} else if (id == 'Power BI') {
						platformIdVal.push(2)
					} else if (id == 'ADF') {
						platformIdVal.push(3)
					} else if (id == 'ADB') {
						platformIdVal.push(4)
					}
				})
				colFilterInfo.platformId = platformIdVal;
			} else if (key == 'priCyclic') {
				value.filter((id: string) => {
					if (id == 'Yes') {
						cyclicVal.push(1);
					} else if (id == 'No') {
						cyclicVal.push(0);
					}
				});
				colFilterInfo.Cyclic = cyclicVal;
				delete colFilterInfo.priCyclic
			}
		});
		let params = {
			projectId: Number(prjid),
			page,
			size,
			filter: colFilterInfo,
			sortBy: sortingInfo.sortBy || '',
			orderBy: sortingInfo.sortDir || ''
		};
		setScheduleTableLoading(true);
		const result = await getAddedSchedules(params);
		if (result.success) {
			setScheduleTableLoading(false);
			let jobArr = result.data.selectedJobData;
			let reqData: any = [];
			jobArr.map((data: any) => {
				data['dependentData'] = [];
				data['isExpanded'] = false;
				data['dependentData'] = data.dependInfo && data.dependInfo.length ? data.dependInfo : [];
				reqData.push(data);
			});

			setAddedSchedulesList(reqData);
			addSchData = reqData;
			let filterOptionCon: any = {
				jobName: [],
				platformId: []
			}
			Object.entries(result.data.filterOptions).forEach(([key, value]: any) => {
				if (key == 'Job_Name') {
					filterOptionCon.jobName = value;
				} else if (key == 'Scheduler_Name' && value && value.length) {
					filterOptionCon.schedulerName = value;
				} else if (key == 'Platform_Id') {
					value.filter((platformNum: number) => {
						if (platformNum == 1) {
							filterOptionCon.platformId.push('Teradata');
						} else if (platformNum == 2) {
							filterOptionCon.platformId.push('Power BI');
						} else if (platformNum == 3) {
							filterOptionCon.platformId.push('ADF');
						} else {
							filterOptionCon.platformId.push('ADB');
						}
					})
				} else if (key == 'Cyclic' && value && value.length) {
					filterOptionCon.priCyclic = []
					value.filter((cyclic: number) => {
						if (cyclic == 1) {
							filterOptionCon.priCyclic.push('Yes')
						} else if (cyclic == 0) {
							filterOptionCon.priCyclic.push('No')
						}
					});
				}
			});
			console.log(filterOptionCon)
			setFilterData(filterOptionCon)
			setPaginationDataSchedule(result.data.page);
			setPageSchedule(result.data.page.currentPage);
			setPageSizeSchedule(result.data.page.size);
		}
	};

	// const getAddedScheduleListOld = async (
	// 	page: number = 1,
	// 	size: number = 100,
	// 	filter: any = {},
	// 	sortingInfo: any = {}
	// ) => {
	// 	let params = {
	// 		projectId: Number(prjid),
	// 		page,
	// 		size,
	// 		filter: filter || filterInfo,
	// 		sortBy: sortingInfo.sortBy || '',
	// 		orderBy: sortingInfo.sortDir || ''
	// 	};
	// 	setScheduleTableLoading(true);
	// 	const result = await getAddedSchedules(params);
	// 	if (result.success) {
	// 		setScheduleTableLoading(false);
	// 		let jobArr = result.data.scheduleList.filter(
	// 			(obj: any) => !obj.Primary_Project_Schedule_Id || obj.Primary_Project_Schedule_Id === null
	// 		);
	// 		let depJobArr = result.data.scheduleList.filter(
	// 			(obj: any) => obj.Primary_Project_Schedule_Id && obj.Primary_Project_Schedule_Id !== null
	// 		);
	// 		let reqData: any = [];
	// 		jobArr.map((data: any) => {
	// 			data['dependentData'] = [];
	// 			data['isExpanded'] = false;
	// 			depJobArr.map((dep: any) => {
	// 				if (dep.Primary_Project_Schedule_Id === data.Project_Schedule_ID) {
	// 					data.dependentData.push(dep);
	// 				}
	// 			});
	// 			data['dependentData'] = data.dependentData.length ? data.dependentData : false;
	// 			reqData.push(data);
	// 		});
	// 		reqData.sort(function (a: any, b: any) {
	// 			// First, compare by date
	// 			const dateDiff = new Date(b.Date).getTime() - new Date(a.Date).getTime();

	// 			// If dates are equal, compare by Days
	// 			if (dateDiff === 0) {
	// 				const daysDiff = a.Days - b.Days;

	// 				// If Days are also equal, compare by Start_Time
	// 				if (daysDiff === 0) {
	// 					return a.Start_Time - b.Start_Time; // Assuming Start_Time is a comparable number or timestamp
	// 				}

	// 				return daysDiff;
	// 			}

	// 			return dateDiff;
	// 		});

	// 		setAddedSchedulesList(reqData);
	// 		addSchData = reqData;
	// 		setPaginationDataSchedule(result.data.page);
	// 		setPageSchedule(result.data.page.currentPage);
	// 		setPageSizeSchedule(result.data.page.size);
	// 	}
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleSorting = (sortingInfo: any) => {
		if (parseFilter(sortingInfo).sortBy == 'platform') {
			let parsedData = parseFilter(sortingInfo);
			parsedData['sortBy'] = 'Platform_Id';

			// parsedData['platform'].map((data: any) => {
			//     switch (data) {
			//         case 'Teradata':
			//             sortingInfo['Platform_Id'].push(1)
			//             break;

			//         default:
			//             break;
			//     }
			// })
			setSortingInfo(parsedData);

			// delete parsedData.platform

			// getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
		} else {
			setSortingInfo(sortingInfo);
			getAddedScheduleList(Number(page), Number(pageSize), parseFilter(filterInfo), sortingInfo);
		}
	};

	const handleFilter = (filterData: any) => {
		setAddedSchedulesList([]);
		setScheduleTableLoading(true);
		if (filterData.Project_Schedule_ID) {
			delete filterData.Project_Schedule_ID;
		}

		if (Object.keys(parseFilter(filterData)).includes('Job_Name')) {
			let filterFormat: any = {
				...parseFilter(filterData),
				Project_Schedule_ID: []
			};
			parseFilter(filterData)['Job_Name'].map((data: any) => {
				let filteredData: any = addedSchedulesList.filter((fil: any) => fil.Job_Name == data);
				if (filteredData.length) {
					filterFormat.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID);
				}
			});
			if (!Object.keys(filterFormat).includes('Job_Name')) {
				filterFormat = {};
			}
			setFilterInfo(filterFormat);
			getAddedScheduleList(Number(page), Number(pageSize), filterFormat, sortingInfo);
		} else if (Object.keys(parseFilter(filterData)).includes('platform')) {
			let parsedData = parseFilter(filterData);
			parsedData['Platform_Id'] = [];

			parsedData['platform'].map((data: any) => {
				switch (data) {
					case 'Teradata':
						parsedData['Platform_Id'].push(1);
						break;

					default:
						break;
				}
			});
			setFilterInfo(filterData);
			delete parsedData.platform;

			getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
		} else if (Object.keys(parseFilter(filterData)).includes('Start_Time')) {
			let parsedData = parseFilter(filterData);
			let start = parsedData['Start_Time'];
			parsedData['Days'] = [];
			parsedData['Start_Time'] = [];
			parsedData['Project_Schedule_ID'] = [];
			start.map((data: string) => {
				let obj = convertDayTime(data);
				parsedData['Start_Time'].push(obj.time);
				parsedData['Days'].push(obj.day);
			});
			parsedData['Days'].map((data: any, index: number) => {
				let filteredData: any = addedSchedulesList.filter(
					(fil: any) => fil.Days == data && fil.Start_Time == parsedData['Start_Time'][index]
				);
				if (filteredData.length) {
					parsedData.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID);
				}
			});
			setFilterInfo(filterData);
			getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
		} else if (Object.keys(parseFilter(filterData)).includes('normalCompletionTime')) {
			let parsedData = parseFilter(filterData);
			let start = parsedData['normalCompletionTime'];

			parsedData['Normal_Completion_Time'] = [];
			parsedData['Project_Schedule_ID'] = [];
			start.map((data: string) => {
				parsedData['Normal_Completion_Time'].push(convertTimeToNumber(data));
			});
			parsedData['Normal_Completion_Time'].map((data: any, index: number) => {
				let filteredData: any = addedSchedulesList.filter((fil: any) => fil.Normal_Completion_Time == data);
				if (filteredData.length) {
					parsedData.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID);
				}
			});
			setFilterInfo(filterData);
			delete parsedData.normalCompletionTime;
			getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
		} else {
			setFilterInfo(filterData);
			getAddedScheduleList(Number(page), Number(pageSize), parseFilter(filterData), sortingInfo);
		}
	};

	function convertTimeToNumber(time: string) {
		// Remove the colon and convert the string to a number
		const timeStr = time.replace(':', '');

		// Convert to a number, removing any leading zeros
		return Number(timeStr);
	}

	function convertDayTime(input: string) {
		// Split the input into day and time parts
		const [dayStr, time] = input.split(', ');

		// Map days of the week to their respective numbers
		const daysMap: any = {
			sun: 0,
			mon: 1,
			tue: 2,
			wed: 3,
			thu: 4,
			fri: 5,
			sat: 6
		};

		// Get the numeric value for the day
		const day = daysMap[dayStr.toLowerCase()];

		// Convert the time part to a number without the colon
		const timeNum = Number(time.replace(':', ''));

		// Return the desired object
		return {
			day: day,
			time: timeNum
		};
	}

	const handleAndGetFilterOptions = async (column: any) => {
		console.log(column)
		let filterSch: any = {};
		let colObj: any = {
			jobName: 'jobName',
			platformId: 'platformId',
			schedulerName: 'schedulerName',
			priCyclic: 'Cyclic'
		};
		if (addedSchedulesList.length) {
			filterSch[column] = [];

			addedSchedulesList.map((data: any) => {
				// Check if the value is already in the array before pushing

				if (column == 'platformId') {
					switch (data[colObj[column]]) {
						case 1:
							if (!filterSch[column].includes('Teradata')) {
								filterSch[column].push('Teradata');
							}
							break;

						default:
							break;
					}
				} else if (
					column == 'Start_Time' &&
					!filterSch[column].includes(`${getDays(data.Days)}, ${convertToTimeString(data.Start_Time)}`)
				) {
					filterSch[column].push(`${getDays(data.Days)}, ${convertToTimeString(data.Start_Time)}`);
				} else if (
					column == 'normalCompletionTime' &&
					data.Normal_Completion_Time &&
					!filterSch[column].includes(`${convertToTimeString(data.Normal_Completion_Time)}`)
				) {
					filterSch[column].push(`${convertToTimeString(data.Normal_Completion_Time)}`);
				} else if (!filterSch[column].includes(data[colObj[column]])) {
					filterSch[column].push(data[colObj[column]]);
				}
			});
			if (column == 'Start_Time') {
				let filteredData = [];
				filteredData = filterSch[column].filter((item: any) => typeof item === 'string');
				filterSch[column] = filteredData;
			}
			console.log(filterSch)
			setFilterData(filterSch);
		}
	};

	const onClickMaskInput = (e: any, row: any) => {
		isTimeMenuId = row.id;
	};

	const handleTimeMenuPopupClose = () => {
		isTimeMenuId = 9999999;
	};

	const onClickMaskInputFromTime = (isOpen: boolean) => {
		if (isOpen) {
			setIsFromTimeActive(isOpen);
		} else {
			setTimeout(() => {
				setIsFromTimeActive(isOpen);
			}, 300);
		}
	};

	const onClickMaskInputToTime = (isOpen: boolean) => {
		if (isOpen) {
			setAddClassFilterHoverEffect(true);
			setIsEndTimeActive(isOpen);
		} else {
			setTimeout(() => {
				setIsEndTimeActive(isOpen);
			}, 300);
		}
	};

	const onChangeFromToTime = (event: any, index: number, type: any, subType: any, isTimeMenuClicked?: boolean) => {
		let val = event.target.value || event.target.innerText;
		val = val.split('');
		if (val[0] > 2 || (val[0] == 2 && val[1] > 3)) {
			val = ['2', '3', ':', '5', '9'];
			handleTimePickerMenuCloseFromOutside(event);
		}
		if (val[3] > 5) {
			val[3] = '5';
			val[4] = '9';
			handleTimePickerMenuCloseFromOutside(event);
		}
		val = val.join('');
		const filterData: any = [...filterCriteria];
		filterData[index][subType] = val;
		filterData[index][type] = val;
		setFilterCriteria(filterData);
		const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
		if ((isTimeMenuClicked || findLenOfEnteredNum == 4) && type == 'fromTime') {
			setIsFromTimeActive(false);
		}
		if ((isTimeMenuClicked || findLenOfEnteredNum == 4) && type == 'toTime') {
			setIsEndTimeActive(false);
		}
	};

	const handleTimePickerMenuCloseFromOutside = (event: any) => {
		const inputEleList = document.getElementsByClassName('has-ellipsis') as HTMLCollection;
		if (inputEleList.length) {
			const ele = inputEleList[0] as HTMLInputElement;
			ele.click();
		}
	};

	const onClickOnInput = (id: number) => {
		const inputEle = document.getElementById('time-input-' + id) as HTMLInputElement;
		inputEle.showPicker();
	};

	const onHandleOpenTimePicker = (idx: number) => {
		setOpenId(idx);
	};

	function replaceAt(str: string, index: number, replacement: string) {
		return str.substring(0, index) + replacement + str.substring(index + replacement.length);
		// let formatValue = '';
		// if (val.substring(0,1) > 2) {
		//     formatValue = replaceAt(val, 0, '2')
		// }
		// if (val.substring(0,1) == 2 && val.substring(1, 2) > 3) {
		//     formatValue = replaceAt(val, 1, '3')
		// }
		// if (val.substring(3,4) > 5) {
		//     formatValue = replaceAt(val, 3, '5')
		// }
	}

	let allRows: Row[] = []

	const onChangeNormalComplTime = (event: any, row: any, isSelect?: boolean) => {
		let val = event.target.value || event.target.innerText;
		val = val.split('');
		if (val[0] > 2 || (val[0] == 2 && val[1] > 3)) {
			val = ['2', '3', ':', '5', '9'];
			handleTimePickerMenuCloseFromOutside(event);
		}
		if (val[3] > 5) {
			val[3] = '5';
			val[4] = '9';
			handleTimePickerMenuCloseFromOutside(event);
		}
		val = val.join('');
		const updatedRow = { ...row, SLATime: val };
		console.log("VALL", updatedRow, val)
		const allSelectedRows = selectedRows.length ? [...selectedRows] : reqRows;
		allSelectedRows.map((eachRow) => {
			if (eachRow.id == row.id) {
				eachRow.SLATime = val;
			}
		});
		const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
		if (!isSelect || findLenOfEnteredNum == 4) {
			isTimeMenuId = 9999999;
		}
		// setSelectedRows(allSelectedRows);
		tableDataList.map((data, index) => {
			if (data.id === updatedRow.id) {
				tableDataList[index] = updatedRow
			}
		})
		allRows = tableDataList
		setSelectedRows((prevRows) =>
			prevRows.map((prevRow) => {
				let reqArr = allSelectedRows.filter((obj) => obj.id === prevRow.id)
			  return (prevRow.id === reqArr[0]?.id ? updatedRow : prevRow)
			})
		);
		setTableDataList((prevRows) => prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow)));
	};

	const onChangeSlaDate = (event: any, row: any, isSelect?: boolean) => {
		let val = event.target.value || event.target.innerText;
		
		const updatedRow = { ...row, SLAODate: val };
		console.log("VALL", updatedRow, val)
		const allSelectedRows = selectedRows.length ? [...selectedRows] : reqRows;
		allSelectedRows.map((eachRow) => {
			if (eachRow.id == row.id) {
				eachRow.SLAODate = val;
			}
		});
		const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
		if (!isSelect || findLenOfEnteredNum == 4) {
			isTimeMenuId = 9999999;
		}
		tableDataList.map((data, index) => {
			if (data.id === updatedRow.id) {
				tableDataList[index] = updatedRow
			}
		})
		setSelectedRows(allSelectedRows);
		
		setTableDataList((prevRows) => prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow)));
	};

	const handleHeaderSelectAll = (event: any) => {
		console.log("SELECTEDDDD", allRows)
		selectedRowsId = [];
		let tempSelectedRows: any = [];
		tableDataList.map((row: any) => {
			row.selected = event.target.checked;
			if (event.target.checked) {
				selectedRowsId.push(row.id);
			}
		});
		if (event.target.checked) {
			// let reqArr: any = []
			// tableDataList.map((data:any) => {
			// 	console.log("HEREEEE", data, selectedRows)
			// 	let filteredData = selectedRows.filter((obj: any) => obj.jobId === data.jobId)
            //     if (filteredData.length) {
			// 		reqArr.push(filteredData[0])
			// 	} else {
			// 		reqArr.push(data)
			// 	}
			// })
			tempSelectedRows = tableDataList;
		}
		setSelectedRows(tempSelectedRows);

		setTableDataList(tableDataList);

		reqRows = tempSelectedRows;

		let inputElements: any = document.getElementsByClassName('row-checkbox');

		for (let i = 0; i < inputElements.length; i++) {
			if (inputElements[i] instanceof HTMLInputElement) {
				inputElements[i].checked = event.target.checked;
			}
		}
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
		if (platformName == 'Power BI') {
			searchSchedulePowerBiFunc(requestBody, value, pageSize)
		} else {
			searchScheduleFunc(requestBody, value, pageSize);
		}
	};
	const handlepageSizeChange = (event: any) => {
		if (platformName == 'Power BI') {
			searchSchedulePowerBiFunc(requestBody, page, event.target.value)
		} else {
			searchScheduleFunc(requestBody, page, event.target.value);
		}
	};

	const handlePageChangeSchedule = (event: React.ChangeEvent<unknown>, value: string) => {
		getAddedScheduleList(Number(value), Number(pageSize));
	};

	const handleFilterSearch = async (searchKeyword: any, columnKey: any) => {
		if (searchKeyword?.length >= 3) {
			let obj = {
				columnName: columnKey,
				key: searchKeyword
			};
			const res = await getAddedScheduleSearchFilterColumn(obj);
			if (res.success) {
				filterOptions[columnKey] = res.data?.map((obj: any) => obj.name) || [];
				setFilterData(filterOptions);
			}
		}
	};
	const handlepageSizeChangeSchedule = (event: any) => {
		getAddedScheduleList(Number(page), event.target.value);
	};
	const handleOnClose = (event: any, index: number, autoValue?: any) => {
		if (autoValue == 'selectOption') {
			const {
				target: { textContent }
			} = event;
			const filterData = [...filterCriteria];
			filterData[index]['keywords'] = textContent;
			if (textContent) {
				jobDataList.filter((job: any) => {
					if (job.JobName == textContent) {
						filterData[index]['id'] = job.JobId;
					}
				});
			}
			// setSelectedJobName(false);
			filterData.filter((fil: any) => {
				if (fil.filterType == 'Job Name' && fil.keywords) {
					setSelectedJobName(true);
				}
			});
			setFilterCriteria(filterData);
		}
	};
	let selectedJobs: any = [];
	const handleChangeDynamicKeywords = (event: any, index: number, autoValue?: any, selectedFilterType?: string) => {
		const {
			target: { value }
		} = event;
		const filterData = [...filterCriteria];
		filterData[index]['keywords'] = autoValue ? autoValue : value;
		let jobNameArr = filterData.filter((obj: any) => obj.filterType == 'Job Name');
		if (autoValue) {
			if (selectedFilterType == 'Job Name') {
				jobDataList.filter((job: any) => {
					if (job.JobName == autoValue) {
						filterData[index]['id'] = job.JobId;
					}
				});
			} else if (selectedFilterType == 'Dataset') {
				datasetList.filter((ds: any) => {
					if (ds.NAME == autoValue) {
						filterData[index]['id'] = ds.ID;
					}
				});
			} else if (selectedFilterType == 'Workspace') {
				workspaceList.filter((ws: any) => {
					if (ws.name == autoValue) {
						filterData[index]['id'] = ws.orgId;
					}
				});
			} else if (selectedFilterType == 'capacity') {
				capacityList.filter((cap: any) => {
					if (cap.displayname == autoValue) {
						filterData[index]['id'] = cap.capacityId;
					}
				});
			}
		}

		if (jobNameArr.length) {
			jobNameArr.map((obj: any) => {
				jobDataList.filter((job: any) => {
					if (job.JobName == obj.keywords) {
						const pos = filterData.findIndex((data: any) => data.keywords === obj.keywords);
						filterData[pos]['id'] = job.JobId;
					}
				});
			});
		}
		console.log(filterData)
		// setSelectedJobName(false);
		filterData.filter((fil: any) => {
			if (fil.filterType == selectedFilterType && fil.keywords) {
				if (selectedFilterType == 'Job Name') {
					setSelectedJobName(true);
				} else if (selectedFilterType == 'Dataset') {
					setSelectedDatasetName(true);
				} else if (selectedFilterType == 'Workspace') {
					setSelectedWorkspaceName(true);
				} else if (selectedFilterType == 'Capacity') {
					setSelectedCapacityName(true);
				}
			}
		});
		setFilterCriteria(filterData);
	};

	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (filterData, value, type: string) => {
				if (type == 'Job Name') {
					jobNameListFunc(filterData[0].keywords, value, filterData);
				} else if (type == 'Dataset') {
					datasetIdListFunc(value);
				}  else if (type == 'Workspace') {
					workspaceIdListFunc(value);
				}  else if (type == 'Capacity') {
					capacityIdListFunc(value);
				}				
			}, 500),
		[]
	);

	const onChangeFieldKeyDown = (event: any) => {
		if (!/[0-9a-zA-Z[\]#!*?_-]/.test(event.key)) {
			event.preventDefault();
		}
	};

	const onChangeFieldInput = (event: any, index: number, type: string) => {
		const {
			target: { value }
		} = event;
		const valueTrim = value.trim();
		const filterData = [...filterCriteria];
		filterData[index]['keywords'] = valueTrim;
		setFilterCriteria(filterData);
		setSelectedJobName(false);
		if (valueTrim && valueTrim.length > 0) {
			if (type == 'Job Name') {
				setJobSearchKeyword(valueTrim);
				setSearchInputJobNameLoading(true);
			} else if (type == 'Dataset') {
				setDatasetSearchKeyword(valueTrim);
				setSearchInputDatasetLoading(true);
			}  else if (type == 'Workspace') {
				setWorkspaceSearchKeyword(valueTrim);
				setSearchInputWorkspaceLoading(true);
			}  else if (type == 'Capacity') {
				setCapacitySearchKeyword(valueTrim);
				setSearchInputCapacityLoading(true);
			} 
			debouncedFetchOptions(filterData, valueTrim, type);
		}
	};

	const onChangeTime = (event: any, index: number, type: any, subType: any) => {
		let selectedTime = moment(event);
		const filterData: any = [...filterCriteria];
		if (event && event._d) filterData[index][subType] = moment(event._d).utc().format('HH:mm');
		filterData[index][type] = selectedTime;
		setFilterCriteria(filterData);
	};

	const handleChangePlatform = (event: SelectChangeEvent<typeof platformName>) => {
		const {
			target: { value }
		} = event;
		setSearchScheduleBtn(false);
		setPlatformName(value);
		setFilterCriteria([]);
		setJobSearchKeyword('');
		setDatasetSearchKeyword('');
		setWorkspaceSearchKeyword('');
		setCapacitySearchKeyword('');
		if (value == 'Power BI') {
			setFilterCriteriaList(['Capacity', 'Workspace', 'Dataset'])
			const newFilterData = [
				{ filterType: 'Capacity', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Workspace', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Dataset', operation: 'Equals to (=)', keywords: '' }				
			];			
			const insertedFilterCriteria = newFilterData;
			setFilterCriteria([...insertedFilterCriteria]);
		} else {
			const newFilterData = [
				{ filterType: 'Scheduler Name', operation: 'Equals to (=)', keywords: 'All' },
				{ filterType: 'Job Name', operation: 'Equals to (=)', keywords: '', id: 1 }
			];
			const insertedFilterCriteria = newFilterData;
			setFilterCriteria([...insertedFilterCriteria]);
			setFilterCriteriaList(['Scheduler Name', 'Job Name'])
		}
	};

	const onHoverFilterEffect = (isOpen: boolean) => {
		setAddClassFilterHoverEffect(isOpen);
	};

	const handleChangeDynamicFilter = (event: any, index: number) => {
		const { target: { value } } = event;
		const filterData = [...filterCriteria];
		filterData[index].filterType = value;
		if (value == 'Days') {
			filterData[index].keywords = []
			filterData[index]['id'] = index;
		} else if (value == 'Job Name') {
			filterData[index].id = index;
		} else if (value == 'Start Time') {
			filterData[index].fromTime = '';
			filterData[index].toTime = '';
			filterData[index].fromTimeValue = '';
			filterData[index].toTimeValue = '';
		}
		setHasSchedulerAdded(false);
		setHasDaysAdded(false);
		setHasTimeAdded(false);
		filterData.filter((fil: any) => {
			if (fil.filterType == 'Days') {
				setHasDaysAdded(true);
			} else if (fil.filterType == 'Start Time') {
				setHasTimeAdded(true);
			} else if (fil.filterType == 'Scheduler Name') {
				setHasSchedulerAdded(true);
			}
		})
		setFilterCriteria(filterData);
	};

	const handleAddFilter = (index: any) => {
		let newFilterData = { filterType: 'Job Name', operation: 'Equals to (=)', keywords: '' };
		if (!hasSchedulerAdded) {
			newFilterData = { filterType: 'Scheduler Name', operation: 'Equals to (=)', keywords: '' };
		}
		if (platformName == 'Power BI') {
			newFilterData = { filterType: 'Capacity', operation: 'Equals to (=)', keywords: '' };
		}
		if (index == -1) {
			filterCriteria.unshift(newFilterData);
		} else {
			filterCriteria.push(newFilterData);
		}
		const insertedFilterCriteria = filterCriteria;
		setFilterCriteria([...insertedFilterCriteria]);
	};

	const handleRemoveFilter = (index: any) => {
		const filterData = [...filterCriteria];
		if (filterData[index].filterType == 'Days') {
			setHasDaysAdded(false);
		} else if (filterData[index].filterType == 'Start Time') {
			setHasTimeAdded(false);
		} else if (filterData[index].filterType == 'Scheduler Name') {
			setHasSchedulerAdded(false);
		}
		filterData.splice(index, 1);
		setFilterCriteria([...filterData]);
	};

	const handleClear = () => {
		onHoverFilterEffect(false);
		setSelectedJobName(false);
		setSelectedDatasetName(false);
		setSelectedWorkspaceName(false);
		setSelectedCapacityName(false);
		setSearchScheduleBtn(false);
		setHasDaysAdded(false);
		setHasTimeAdded(false);
		setJobSearchKeyword('');
		setDatasetSearchKeyword('');
		setWorkspaceSearchKeyword('');
		setCapacitySearchKeyword('');
		setJobNameList([]);
		setDatasetNameList([]);
		setWorkspaceNameList([]);
		setCapacityNameList([]);
		let filterData = [
			{ filterType: 'Scheduler Name', operation: 'Equals to (=)', keywords: 'All' },
			{ filterType: 'Job Name', operation: 'Equals to (=)', keywords: '', id: 1 }
		];
		if (platformName == 'Power BI') {
			filterData = [
				{ filterType: 'Capacity', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Workspace', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Dataset', operation: 'Equals to (=)', keywords: '' }	
			];
		}
		setFilterCriteria(filterData);
	};

	const onSubmitSearchFetchJobIds = () => {
		setSelectedRows([]);
		handleOnSubmit(null, filterCriteria);
		// if (selectedJobName) {
		// } else {
		// 	setSearchScheduleBtn(true);
		// 	setTableLoading(true);
		// 	debouncedFetchOptions(filterCriteria, jobSearchKeyword, true);
		// }
	}

	const handleOnSubmit = (jobId?: any, filterCriterias?: any) => {
		let filterCriteriaData = filterCriterias;
		if (filterCriterias && filterCriteria.length) {
			filterCriteriaData = filterCriterias;
		}
		let hasJobName = false, hasDatasetName = false, hasWorkspaceName = false, hasCapacityName = false,
			hasSchedulerType = false;
			let reqBody: any = { schedulerType: filterCriteriaData[0].keywords, jobIds: [], days: [], startTime: [], projectId: prjid };
			if (platformName == 'Power BI') {
				reqBody = { dsName: '',  wkName: '', cpName: ''};
			}
		filterCriteriaData.filter((filter: any) => {
			if (filter.filterType == 'Scheduler Name' && filter.keywords != '') {
				hasSchedulerType = true;
			} else if (filter.filterType == 'Job Name' && filter.keywords != '') {
				hasJobName = true;
				reqBody.jobIds.push(filter.keywords);
				if (selectedJobName && !filter.id) {
					reqBody.jobIds = [];
				}
			} else if (filter.filterType == 'Dataset' && filter.keywords != '') {
				hasDatasetName = true;
				reqBody.dsName = filter.keywords;
			} else if (filter.filterType == 'Workspace' && filter.keywords != '') {
				hasWorkspaceName = true;
				reqBody.wkName = filter.keywords;
			} else if (filter.filterType == 'Capacity' && filter.keywords != '') {
				hasCapacityName = true;
				reqBody.cpName = filter.keywords;
			} else if (hasSchedulerType && hasJobName && filter.filterType == 'Days' && filter.keywords != '') {
				reqBody.days = filter.keywords.includes('All')
					? 'all'
					: filter.keywords.map((d: string) => d.substring(0, 2).toLowerCase());
			} else if (
				hasSchedulerType &&
				hasJobName &&
				filter.filterType == 'Start Time' &&
				filter.fromTimeValue != '' &&
				filter.toTimeValue != ''
			) {
				reqBody.startTime = [filter.fromTimeValue, filter.toTimeValue];
			}
		});

		if (hasJobName || hasDatasetName || hasWorkspaceName || hasCapacityName) {
			onHoverFilterEffect(false);
			setSearchScheduleBtn(true);
			setRequestBody(reqBody);
			if (platformName == 'Power BI') {
				searchSchedulePowerBiFunc(reqBody, '1', '100')
			} else {
				searchScheduleFunc(reqBody, '1', '100');
			}
		}
	};

	const splitDays = (days: string) => {
		let dayNumber;
		if (days.includes('Sun')) {
			dayNumber = 0;
		} else if (days.includes('Mon')) {
			dayNumber = 1;
		} else if (days.includes('Tue')) {
			dayNumber = 2;
		} else if (days.includes('Wed')) {
			dayNumber = 3;
		} else if (days.includes('Thu') || days.includes('Thur')) {
			dayNumber = 4;
		} else if (days.includes('Fri')) {
			dayNumber = 5;
		} else if (days.includes('Sat')) {
			dayNumber = 6;
		}
		return dayNumber;
	};

	const addSchedulesToProject = () => {
		let reqBodySch: any = [];
		if (selectedRows.length) {
			if (platformName == 'Power BI') {
				selectedRows.filter((row: any) => {
					reqBodySch.push({
						dsName: row.dsName,
						dsId: row.dsId,
						platformId: platformName == 'Power BI' ? 2 : 1,
						programId: Number(prgmid),
						projectId: Number(prjid),
						isEnabled: row.enabled
					});
				});
			} else {
				selectedRows.filter((row: any) => {
					reqBodySch.push({
						JobName: row.jobName,
						JobId: row.jobId,
						SLAODate: row.SLAODate ? row.SLAODate : 0,
						Scheduler: row.Scheduler,
						SLATime:  row.SLATime == '' ? null : Number(row.SLATime.replace(':', '')),
						ProgramId: Number(prgmid),
						ProjectId: Number(prjid),
						Cyclic: row.cyclic
					});
				});
			}
			addScheduleToProjectFunc(reqBodySch);
		}
	};

	async function addScheduleToProjectFunc(reqBody: any) {
		setAddProjectLoading(true);
		setOpenModal({
			isOpen: true
		});
		let res;
		if (platformName == 'Power BI') {
			res = await linkAddPowerBiScheduleToProject(reqBody);
		} else {
			res = await linkAddScheduleToProject(reqBody);
		}
		if (res.statusCode == 200) {
			setSuccessMsg(res.data.message);
			setAddProjectLoading(false);
		} else if (res.code == 'ERR_BAD_REQUEST') {
			setSuccessMsg('Schedules already added!');
			setAddProjectLoading(false);
		}
	}

	async function jobNameListFunc(schedulerType: string, jobName?: string, filterData?: any) {
		const res = await getTeradataJobNameList(schedulerType, jobName, Number(prjid));
		if (res.success) {
			setJobDataList(res.data.jobNames);
			setSearchInputJobNameLoading(false);
			setJobNameList(res.data.jobNames.map((jn: any) => jn.JobName));
			const jobIds = res.data.jobNames.map((jn: any) => jn.JobId);
			console.log(jobIds)
			// if (isSubmit) {
			// 	if (jobIds && jobIds.length) {
			// 		handleOnSubmit(jobIds, filterData);
			// 	} else {
			// 		setTableLoading(false);
			// 		setTableDataList([]);
			// 	}
			// }
		}
	}

	async function datasetIdListFunc(datasetName: string) {
		const res = await getDPdatasetIdList(datasetName);
		if (res.success) {
			setDatasetList(res.data.result);
			setSearchInputDatasetLoading(false);
			setDatasetNameList(res.data.result.map((ds: any) => ds.NAME));
			const dsids = res.data.result.map((ds: any) => ds.ID);
		}
	}

	async function workspaceIdListFunc(workspaceName: string) {
		const res = await getDPworkspaceIdList(workspaceName);
		if (res.success) {
			setWorkspaceList(res.data.result);
			setSearchInputWorkspaceLoading(false);
			setWorkspaceNameList(res.data.result.map((ws: any) => ws.name));
			const wsids = res.data.result.map((ws: any) => ws.orgId);
		}
	}

	async function capacityIdListFunc(capacityName: string) {
		const res = await getDPcapacityIdList(capacityName);
		if (res.success) {
			setCapacityList(res.data.result);
			setSearchInputCapacityLoading(false);
			setCapacityNameList(res.data.result.map((cn: any) => cn.displayname));
			const capacityIds = res.data.result.map((cn: any) => cn.capacityId);
		}
	}

	function convertToTime(input: any) {
		// Convert the input to a string and pad with leading zeros if necessary
		let timeString = input.toString().padStart(4, '0');

		// Extract the hours and minutes
		let hours = timeString.slice(0, 2);
		let minutes = timeString.slice(2, 4);

		// Return the formatted time string
		return `${hours}:${minutes}`;
	}

	function convertToTimeString(num: any) {
		const hours = Math.floor(num / 100)
			.toString()
			.padStart(2, '0');
		const minutes = (num % 100).toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	}

	async function searchScheduleFunc(reqBody: any, pageNumber: string, pageSize: string) {
		setTableLoading(true);
		const res = await getSearchScheduleJobNameList(reqBody, pageNumber, pageSize);
		if (res.success) {
			let schListData: any = res.data.selectedJobData.map((row: any, index: number) => ({
				...row,
				SLAODate: '',
				SLATime: '',
				selected: false,
				id: index
			}));
			setTableDataList(schListData);
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
		}		
		setJobSearchKeyword('');
		setTableLoading(false);
	}

	async function searchSchedulePowerBiFunc(reqBody: any, pageNumber: string, pageSize: string) {
		setTableLoading(true);
		const res = await getSearchSchedulePowerBiList(reqBody, pageNumber, pageSize);
		if (res.success) {
			let schListData: any = res.data.powerBIData.map((row: any, index: number) => ({
				...row,
				selected: false,
				id: index
			}));
			setTableDataList(schListData);
			setPaginationData(res.data.page ? res.data.page : { totalElements: schListData.length, totalPages: 1 });
			setPage(res.data.page && res.data.page.currentPage ? res.data.page.currentPage : 1);
			setPageSize(res.data.page && res.data.page.size ? res.data.page.size : 100);
		}		
		setTableLoading(false);
		setDatasetSearchKeyword('');
		setWorkspaceSearchKeyword('');
		setCapacitySearchKeyword('');
	}

	async function searchScheduleFuncOld(reqBody: any, pageNumber: string, pageSize: string) {
		setTableLoading(true);
		const res = await getSearchScheduleJobNameList(reqBody, pageNumber, pageSize);
		if (res.success) {
			let schListData: any = res.data.selectedSchedules.map((row: any, index: number) => ({
				...row,
				normalCompletionTime: '',
				selected: false,
				id: index
			}));
			const lastData = [schListData[0]];
			let normalCompletionData = await getNormalCompletionTime(res.data.selectedSchedules[0]?.jobId);
			const defaultDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
			if (normalCompletionData.data.length && schListData.length) {
				let normalCompletionList: any = [];
				schListData.map((data: any) => {
					let filteredData = normalCompletionData.data.filter(
						(obj: any) => data.schedule === `${defaultDays[obj.Days]}, ${convertToTimeString(obj.Start_Time)}`
					);

					if (filteredData.length) {
						normalCompletionList.push({
							...data,
							normalCompletionTime: convertToTime(filteredData[0].Normal_Completion_Time)
						});
					} else {
						normalCompletionList.push({
							...data
						});
					}
				});
				schListData = normalCompletionList;
			}
			addSchData = addSchData.length ? addSchData : addedSchedulesList;
			if (addSchData.length) {
				let filData: any = [];
				schListData.map((obj: any) => {
					let filter = addSchData.filter(
						(fil: any) =>
							`${defaultDays[fil.Days]}, ${convertToTimeString(fil.Start_Time)}` == obj.schedule &&
							obj.jobName == fil.Job_Name
					);
					if (!filter.length) {
						filData.push(obj);
					}
				});
				schListData = filData;
				// commented to fix pagination issue
				// res.data.page = {
				//     ...res.data.page ,
				//     totalElements: schListData.length
				// }
			}

			setTimeout(() => {
				let table = document.getElementById(`sch_list_details${prjid}`);
				if (table) {
					const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
					if (rdgElement) {
						let jobsLength = schListData.length;
						let height = 270;
						// if (jobsLength === 1) height = jobsLength * 80;
						// else if (jobsLength > 1 && jobsLength <=3) height = jobsLength * 65;
						// else if (jobsLength > 3 && jobsLength <=5) height = jobsLength * 55;
						// else
						if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
						else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
						else height = 300;
						rdgElement.style.height = `${height}px`;
					}
				}
			}, 100);
          
			setTableLoading(false);
			// setTableDataList(schListData);
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
		}
	}

	const handleModalClose = (data: any) => {
		if (searchScheduleBtn) {
			handleAddMore();
		} else {
			setOpenModal({
				isOpen: false
			});
		}
	};

	const handleModalExit = () => {
		setOpenModal({
			isOpen: false
		});
	};

	const handleAddMore = () => {
		setAddedSchedulesList([]);
		setSelectedRows([]);
		setOpenModal({
			isOpen: false
		});
		getAddedScheduleList();
		handleClear();
	};

	const classes = useStyles();

	useEffect(() => {
		getAddedScheduleList();
	}, []);

	useEffect(() => {
		let filterData = [
			{ filterType: 'Scheduler Name', operation: 'Equals to (=)', keywords: 'All' },
			{ filterType: 'Job Name', operation: 'Equals to (=)', keywords: '' }
		];
		
		if (platformName == 'Power BI') {
			filterData = [{ filterType: 'Dataset', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Workspace', operation: 'Equals to (=)', keywords: '' },
				{ filterType: 'Capacity', operation: 'Equals to (=)', keywords: '' }
			];
		}
		setFilterCriteria(filterData);
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/search-jobs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, [openModal]);

	return (
		<Stack mt={8} p={2} spacing={2} bgcolor="blue.darker">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[5]),
						link: `/data-pipeline/programs/${window.location.pathname
							.split('/')[3]
							.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[5].replace(/%20/g, ' ')}`
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[6]),
						link: `/data-pipeline/projects/${window.location.pathname
							.split('/')[3]
							.replace(/%20/g, ' ')}/${window.location.pathname
							.split('/')[4]
							.replace(/%20/g, ' ')}/${window.location.pathname
							.split('/')[5]
							.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[6].replace(/%20/g, ' ')}`
					},
					{
						text: 'Add Jobs'
					}
				]}
			/>
			<WidgetCardContainer bgColor="primary.main">
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={2}>
						<Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#e5e5e5', marginTop: '10px' }}>Select Platform</Typography>
					</Grid>
					<Grid item xs={4}>
						<CustomDropdown
							options={platformList.map((platform) => ({
								value: platform,
								label: platform
							}))}
							handleChange={handleChangePlatform}
							value={platformName}
							selectComponentProps={{
								displayEmpty: true,
								sx: dropdownSxProps
							}}
							boxProps={{
								maxWidth: 400
							}}
							dropdownIcon={ArrowDownIcon}
						/>
					</Grid>

					<Grid item xs={6}>
					{/* <Grid item xs={12} md={10} lg={11}> */}
						<Box display="flex" alignItems="right" justifyContent="flex-end">
							<Button
								variant="pepwiseSecondaryOutline2"
								onClick={handleClear}
								sx={{ width: '160px', height: '40px', color: 'rgba(0, 155, 216, 1)', marginRight: '8px', borderColor: 'rgba(0, 155, 216, 1)' }}
							>
								Clear
							</Button>
							<Button
								variant="pepwiseSecondary"
								disabled={selectedJobName || jobSearchKeyword || selectedDatasetName && selectedWorkspaceName && selectedCapacityName || datasetSearchKeyword && workspaceSearchKeyword && capacitySearchKeyword ? false : true }
								onClick={onSubmitSearchFetchJobIds}
								sx={{ minWidth: '160px', height: '40px', background: 'transparent', color: 'rgba(0, 155, 216, 1)', border: '1px solid rgba(0, 155, 216, 1)', '&:hover': { color: '#fff' } }}
							>
								Run Query
							</Button>
							{/* <BlueSearchIcon 
								style={{ border: '1px solid rgba(0, 155, 216, 1)', padding: '10px', borderRadius: '4px' }}
							/> */}
						</Box>
					</Grid>
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Divider style={{borderColor: 'rgba(113, 117, 143, 1)', width: '100%', margin: '15px 0'}} />
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={6} style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}}>Field</Grid>
					<Grid item xs={6} style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)',
						display: 'flex', justifyContent: 'space-between' }}>
						Value <span style={{fontSize: '16px', fontWeight: '700', color: 'rgba(0, 155, 216, 1)', cursor: 'pointer'}} onClick={(e) => handleAddFilter(-1)}>+ ADD CLAUSE</span>
					</Grid>
				</Grid>
				<Grid item container xs={12}>
					<Grid item container xs={12} spacing={1} display="flex" alignItems="center">
						{filterCriteria &&
							filterCriteria.map((filter: any, idx: number) => (
								<React.Fragment key={filter.filterType + idx}>
									
									<Grid item xs={6}>
										<CustomDropdown
											options={filterCriteriaList.map((name: any) => ({
												label: name,
												value: name,
												isDisabled:
													name == 'Days' && !!hasDaysAdded
														? true
														: name == 'Start Time' && !!hasTimeAdded
														? true
														: name == 'Scheduler Name' && !!hasSchedulerAdded
														? true
														: false
											}))}
											value={filter.filterType}
											handleChange={(e) => {
												handleChangeDynamicFilter(e, idx);
											}}
											selectComponentProps={{
												onOpen: () => onHoverFilterEffect(true),
												sx: dropdownSxProps
											}}
											dropdownIcon={ArrowDownIcon}
										/>
									</Grid>

									{filter.filterType == 'Scheduler Name' && (
										<Grid item xs={5}>
											<CustomDropdown
												options={[
													{
														label: 'All',
														value: 'All'
													},
													{
														label: 'AutoSys',
														value: 'AutoSys'
													},
													{
														label: 'Control-M',
														value: 'Control-M'
													}
												]}
												value={filter.keywords}
												handleChange={(e) => {
													handleChangeDynamicKeywords(e, idx);
												}}
												selectComponentProps={{
													displayEmpty: true,
													sx: dropdownSxProps
												}}
												dropdownIcon={ArrowDownIcon}
											/>
										</Grid>
									)}
									
									{filter.filterType == 'Job Name' && (
										<Grid item xs={5}>
											<CustomAutocomplete
												options={jobNameList}
												noOptionsText={
													searchJobNameInputLoading ? 'Loading...' : searchJobNameInputLoading == false ? 'No data' : 'Search Jobs'
												}
												value={filter.keywords}
												onClose={(e, val) => handleOnClose(e, idx, val)}
												onChange={(e, val) => handleChangeDynamicKeywords(e, idx, val, 'Job Name')}
												sx={autocompleteSxProps}
												customTextFieldProps={{
													placeholder: 'Search Job Name',
													onChange: (e) => onChangeFieldInput(e, idx, 'Job Name'),
													onKeyDown: (e) => onChangeFieldKeyDown(e),
													...autocompleteTextFieldProps
												}}
											/>
										</Grid>
									)}
									
									{filter.filterType == 'Dataset' && (
										<Grid item xs={5}>
											<CustomAutocomplete
												options={datasetNameList}
												noOptionsText={
													searchDatasetInputLoading ? 'Loading...' : searchDatasetInputLoading == false ? 'No data' : 'Search Dataset'
												}
												value={filter.keywords}
												onClose={(e, val) => handleOnClose(e, idx, val)}
												onChange={(e, val) => handleChangeDynamicKeywords(e, idx, val, 'Dataset')}
												sx={autocompleteSxProps}
												customTextFieldProps={{
													placeholder: 'Search Keywords',
													onChange: (e) => onChangeFieldInput(e, idx, 'Dataset'),
													onKeyDown: (e) => onChangeFieldKeyDown(e),
													...autocompleteTextFieldProps
												}}
											/>
										</Grid>
									)}
									
									{filter.filterType == 'Workspace' && (
										<Grid item xs={5}>
											<CustomAutocomplete
												options={workspaceNameList}
												noOptionsText={
													searchWorkspaceInputLoading ? 'Loading...' : searchWorkspaceInputLoading == false ? 'No data' : 'Search Workspace'
												}
												value={filter.keywords}
												onClose={(e, val) => handleOnClose(e, idx, val)}
												onChange={(e, val) => handleChangeDynamicKeywords(e, idx, val, 'Workspace')}
												sx={autocompleteSxProps}
												customTextFieldProps={{
													placeholder: 'Search Keywords',
													onChange: (e) => onChangeFieldInput(e, idx, 'Workspace'),
													onKeyDown: (e) => onChangeFieldKeyDown(e),
													...autocompleteTextFieldProps
												}}
											/>
										</Grid>
									)}
									
									{filter.filterType == 'Capacity' && (
										<Grid item xs={5}>
											<CustomAutocomplete
												options={capacityNameList}
												noOptionsText={
													searchCapacityInputLoading ? 'Loading...' : searchCapacityInputLoading == false ? 'No data' : 'Search Capacity'
												}
												value={filter.keywords}
												onClose={(e, val) => handleOnClose(e, idx, val)}
												onChange={(e, val) => handleChangeDynamicKeywords(e, idx, val, 'Capacity')}
												sx={autocompleteSxProps}
												customTextFieldProps={{
													placeholder: 'Search Keywords',
													onChange: (e) => onChangeFieldInput(e, idx, 'Capacity'),
													onKeyDown: (e) => onChangeFieldKeyDown(e),
													...autocompleteTextFieldProps
												}}
											/>
										</Grid>
									)}
									
									<Grid item xs={0.5} display="flex" justifyContent="end">
										<OutlinedButton
											sx={{
												borderColor: 'rgba(254, 38, 38, 1)'
											}}
											onClick={(e) => {
												handleRemoveFilter(idx);
											}}
										>
											<CloseIcon sx={{ color: 'rgba(254, 38, 38, 1)' }} />
										</OutlinedButton>
									</Grid>

									<Grid item xs={0.5} display="flex" justifyContent="end">
										<OutlinedButton
											sx={{
												borderColor: 'rgba(0, 155, 216, 1)'
											}}
											onClick={(e) => {
												handleAddFilter(idx);
											}}
										>
											<AddIcon sx={{ color: 'rgba(0, 155, 216, 1)' }} />
										</OutlinedButton>
									</Grid>

									{/* {filter.filterType == 'Days' && (
										<Grid item xs={11} md={6} lg={8}>
											<CustomDropdown
												value={filter.keywords}
												handleChange={(e) => {
													handleChangeDynamicKeywords(e, idx);
												}}
												selectComponentProps={{
													displayEmpty: true,
													multiple: true,
													sx: dropdownSxProps,
													onOpen: () => onHoverFilterEffect(true),
													renderValue: (selected) => {
														if (Array.isArray(selected)) {
															if (selected.length === 0) {
																return <em>All Days</em>;
															}

															return selected.join(', ');
														}
													}
												}}
											>
												{daysList.map((name) => (
													<MenuItem key={name} value={name}>
														<Checkbox checked={filter.keywords.indexOf(name) > -1} />
														<ListItemText primary={name} />
													</MenuItem>
												))}
											</CustomDropdown>
										</Grid>
									)} */}

									{/* {filter.filterType == 'Start Time' && (
										<Grid item xs={11} md={6} lg={8}>
											<Box display="flex" alignItems="center" gap={1} height="100%">
												<CustomMaskedInput
													mask="11:11"
													placeholder="From Time"
													name="time"
													value={filter.fromTime}
													onChange={(e) => onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue')}
													dropdownOptions={timeList?.map((time: string) => ({
														label: time,
														value: time,
														handleItemClick: (e: any) => onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue', true)
													}))}
												/>
												<CustomMaskedInput
													mask="11:11"
													placeholder="To Time"
													name="time"
													value={filter.toTime}
													disabled={!filter.fromTime}
													onChange={(e) => onChangeFromToTime(e, idx, 'toTime', 'toTimeValue')}
													dropdownOptions={timeList?.map((time: string) => ({
														label: time,
														value: time,
														handleItemClick: (e: any) => {
															if (!filter.fromTime || !compareTimes(filter.fromTime, time)) {
																onChangeFromToTime(e, idx, 'toTime', 'toTimeValue', true);
															}
														},
														isDisabled: !!filter.fromTime && !!compareTimes(filter.fromTime, time)
													}))}
												/>
											</Box>
										</Grid>
									)} */}

								</React.Fragment>
							))}
					</Grid>
				</Grid>
			</WidgetCardContainer>

			{!!searchScheduleBtn && (
				<Stack>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="pageTitle" style={{ fontSize: '16px', fontWeight: '700' }}>Select Jobs From Search Results</Typography>
						{tableDataList && tableDataList.length > 0 && (
							<Button disabled={!selectedRows.length} variant="outlined" startIcon={<PlusSymbolIcon  />} className={classes.addSchBtn} onClick={addSchedulesToProject}
							sx={{borderRadius: '4px', '&:hover': { backgroundColor: '#009BD8', color: '#fff !important'}, '&.Mui-disabled': { opacity: '0.6'}, '&:hover .MuiButton-startIcon svg path': {fill: '#fff !important'} }}>
								ADD ALL SELECTED
							</Button>
						)}
					</Box>
					<Box id={`sch_list_details${prjid}`}>
						{!tableLoading && tableDataList.length ? (
							<CustomizedDataGrid
								tableColumns={ platformName == 'Power BI' ? getPowerBiColumns() : getColumns()}
								tableData={tableDataList}
								tableType="add-schedules"
								defaultColumnNames={platformName == 'Power BI' ? PowerBiColumnNames : defaultColumnNames}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={tableDataList.length}
								onHeaderSelectAll={handleHeaderSelectAll}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={false}
								isDisabled={false}
								isFetching={true}
							/>
						) : !tableLoading && tableDataList.length == 0 ? (
							<Typography textAlign="center">No result found</Typography>
						) : (
							<Loader />
						)}
					</Box>
				</Stack>
			)}

			{addedSchedulesList && addedSchedulesList.length > 0 && !scheduleTableLoading && (
				<Stack pt={1}>
					<Typography variant="pageTitle" style={{ fontSize: '16px', fontWeight: '700' }}>Added Jobs</Typography>
					{addedSchedulesList && addedSchedulesList.length ? (
						<Box>
							{!scheduleTableLoading ? (
								<CustomizedDataGrid
									tableColumns={getAddedScheduleColumns()}
									tableData={addedSchedulesList}
									tableType="added-schedules"
									defaultColumnNames={defaultAddedScheduleColumnNames}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									onFilterCheck={handleFilter}
									currFilter={filterInfo}
									filterDataOptions={filterData}
									page={pageSchedule}
									pageSize={pageSizeSchedule}
									paginationData={paginationDataSchedule}
									listLength={addedSchedulesList.length}
									handlepageSizeChange={handlepageSizeChangeSchedule}
									handlePageChange={handlePageChangeSchedule}
									isDateRangeFilter={false}
									isDisabled={false}
									isFetching={scheduleTableLoading}
									searchDataValues={searchFilterDetails}
									onFilterSearch={handleFilterSearch}
									// onFilterClick={handleAndGetFilterOptions}
								/>
							) : (
								<Loader />
							)}
						</Box>
					) : (
						<Typography textAlign="center">Data will be displayed based on selected filters.</Typography>
					)}
				</Stack>
			)}
			{scheduleTableLoading && !addedSchedulesList.length && <Loader />}
			<DialogModal
				isOpen={openModal.isOpen}
				handleClose={handleModalClose}
				isShowCloseButton={!searchScheduleBtn}
				title={addProjectLoading? 'Confirmation' : 'Jobs Added Successfully'}
			>
				{addProjectLoading ? (
					<Box sx={{ p: 6, textAlign: 'center' }}>
						<LoaderComponent size={24} />
					</Box>
				) : (
					<Box sx={{ p: 4, textAlign: 'center' }}>
						
						{successMsg !== 'Schedules already added!' ? (
							<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
							  <Grid item xs={1.5}>
								<Box sx={{width: '65px', height: '65px', '& svg': {maxWidth: '100%'}}}><SuccessTicketIcon /></Box>
							  </Grid>
							  <Grid item xs={10}>
								<Box sx={{ textAlign: 'left', mt: 1, fontSize: '28px', fontWeight: '500' }}>
									{selectedRows.length} {selectedRows.length > 1 ? 'Jobs' : 'Job'} added successfully in the Project.</Box>
								<Box sx={{ textAlign: 'left', mt: 1, fontSize: '18px', fontWeight: '400' }}>Would you like to add more?</Box>
							  </Grid>
							</Grid>
						  </Box>
						) : <Typography sx={{ textAlign: 'center', mb: 2 }}>{successMsg}</Typography>}
							
						{successMsg !== 'Schedules already added!' ? (
							<Box sx={{ textAlign: 'right', mt: 3 }}>
								<Button
									variant="pepwiseOutline"
									component={Link}
									to={`/data-pipeline/projects/${prgmid}/${prjid}/${prgname}/${prjname}`}
									sx={{
										color: 'info.main'
									}}
								>
									SAVE & EXIT
								</Button>
								<Button onClick={handleAddMore} variant="pepwiseSecondary">
									ADD MORE
								</Button>
							</Box>
						) : (
							<Box sx={{ textAlign: 'right', mt: 2 }}>
								<Button
									variant="pepwiseOutline"
									onClick={handleModalClose}
									sx={{
										color: 'info.main'
									}}
								>
									Close
								</Button>
							</Box>
						)}
					</Box>
				)}
			</DialogModal>
		</Stack>
	);
};

export default AddSchedules;
