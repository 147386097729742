import React from 'react';
import { Box } from '@mui/material';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import dragGripIcon from 'assets/reorder-four.svg';
import envPanelIcon from 'assets/env-panel-dark.svg';
import closePanelIcon from 'assets/close-panel-dark.svg';
import checkboxUnchecked from 'assets/checkbox-unchecked.svg';
import checkboxChecked from 'assets/tick-svgrepo.svg';
import checkboxMinus from 'assets/minus-checkbox.svg';

type StyledAgGridReactProps = {
	containerHeight?: string | number;
} & AgGridReactProps;

const StyledAgGridReact: React.FC<StyledAgGridReactProps> = ({ containerHeight = 300, ...props }) => {
	return (
		<Box
			sx={{
				width: '100%',
				height: containerHeight,
				fontSize: '14px',
				fontWeight: 500,
				color: 'text.main',

				'& .ag-custom-theme-quartz': {
					fontSize: '14px',
					fontWeight: '500',
					color: '#e5e5e5',
					'--ag-icon-font-family': 'agGridQuartz',
					// Row drag grip icon
					'--ag-icon-font-display-grip': 'none',
					'--ag-icon-image-grip': `url(${dragGripIcon})`,
					// Sort icons
					'--ag-icon-font-display-asc': 'none',
					'--ag-icon-image-asc': `url(${dragGripIcon})`,
					'--ag-icon-font-display-desc': 'none',
					'--ag-icon-image-desc': `url(${envPanelIcon})`,
					// Checkbox icons
					'--ag-icon-font-display-checkbox-checked': 'none',
					'--ag-icon-image-checkbox-checked': `url(${checkboxChecked})`,
					'--ag-icon-font-display-checkbox-indeterminate': 'none',
					'--ag-icon-image-checkbox-indeterminate': `url(${checkboxMinus})`,
					'--ag-icon-font-display-checkbox-unchecked': 'none',
					'--ag-icon-image-checkbox-unchecked': `url(${checkboxUnchecked})`,
					'& .ag-root-wrapper': {
						border: 'none',
						borderRadius: '5px',
						background: 'none',
						'& .ag-layout-normal': {
							padding: 0
						},
						'& .ag-header-cell-comp-wrapper': {
							fontFamily: 'Montserrat',
							color: '#fff',
							fontSize: '16px',
							'@media screen and (max-width: 1300px)': {
								fontSize: '14px'
							}
						}
					},

					'& .ag-header': {
						height: '50px !important',
						backgroundColor: 'primary.main',

						'& .ag-header-cell': {
							padding: '4px 8px !important',
							height: '50px !important',
							fontFamily: 'Montserrat',
							color: 'white.main',
							fontSize: '16px',

							'&:hover': {
								backgroundColor: '#0c0f25 !important'
							},

							'@media screen and (max-width: 1300px)': {
								fontSize: '14px'
							}
						},
						'& .ag-header-cell-moving': {
							backgroundColor: 'primary.main',
							color: 'white.main'
						}
					},

					'& .ag-row': {
						height: '50px !important',
						background: 'none',

						'& .ag-cell': {
							backgroundColor: 'blue.darker',
							color: 'text.main',
							height: '50px',
							paddingLeft: '7px',
							lineHeight: '40px',
							fontFamily: 'Montserrat',
							border: 'none !important',

							'&:hover': {
								backgroundColor: '#0c0f25 !important'
							},

							'@media screen and (max-width: 1300px)': {
								fontSize: '13px'
							}
						}
					},

					'& .ag-checkbox-input-wrapper': {
						background: 'none',
						outline: 'none',
						boxShadow: 'none !important',
						marginTop: '-3px',
						marginLeft: '10px',

						'& input, &:focus, &:active': {
							outline: 'none !important'
						},
						'&:before': {
							height: '20px',
							width: '20px'
						}
					},

					'& .ag-paging-panel': {
						backgroundColor: 'blue.dark',
						color: 'text.main',
						position: 'relative',

						'& .ag-paging-row-summary-panel': {
							position: 'absolute',
							left: 0,
							'&:before': {
								content: '"Showing "'
							},
							'&:after': {
								content: '" results "'
							}
						}
					},
					'& .ag-drag-handle': {
						color: 'blue.light',
						order: 1
					},
					'& .ag-icon-grip': {
						color: 'blue.light',
						height: '24px',
						width: '24px'
					}
				}
			}}
		>
			<AgGridReact
				className="ag-theme-quartz ag-custom-theme-quartz"
				{...props}
				icons={{
					columnMoveMove: `<img src="${envPanelIcon}" style="padding-top: 15px"/>'`,
					dropNotAllowed: `<img src="${closePanelIcon}" style="padding-top: 15px"/>'`
				}}
			/>
		</Box>
	);
};

export default StyledAgGridReact;
