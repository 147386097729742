import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	Collapse,
	Dialog,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	MenuProps,
	Select,
	Snackbar,
	Stack,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { DataPipelineModal } from './modal-component';
import FilterIcon from '../../assets/filter-projects.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import ExpandMoreIcon from '../../assets/down-arrow.svg';
import { ReactComponent as ViewIcon } from '../../assets/view-detail-img.svg';
import ExportIcon from '../../assets/export-img.svg';
import CalenderDateIcon from '../../assets/calender-date.svg';
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import {
	getJobPerformanceChartData,
	getMappedJobScheduleList,
	getProgramById,
	getProjectListByProgram,
	getDpProjectKpi,
	getScheduleList,
	updateReorderSchedule
} from '../../service/service';
import { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import moment from 'moment';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as CalendarGreyIcon } from '../../assets/icon-calendar-grey.svg';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import { ReactComponent as ViewHighlightIcon } from '../../assets/icon-view-highlight.svg';
import TeradataIcon from '../../assets/icon-teradata.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, RowSelectionMode, RowSelectionOptions } from 'ag-grid-community';
import textWithTooltip from './textWithTooltip';
import { ReactComponent as ReorderFourIcon } from '../../assets/reorder-four.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import DateInfoTooltip from 'components/Tooltip/DateInfoTooltip';
import MoreOptionMenu from 'components/Dropdown/MoreOptionSecondary';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import NumberWidget from 'components/Widgets/NumberWidget';
import CustomAccordionSummary from 'components/Accordion/CustomAccordionSummary';
import CountBox from 'components/Widgets/CountBox';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import StyledAgGridReact from 'components/AgGridReactComponent/CustomStyled';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import CustomPagination from 'components/Widgets/CustomAgGridPagination';
import { PaginationDetails } from 'components/pagination_details';
import eventEmitter from 'SnackBarEventEmitter';

const ProgramDetails = () => {
	let agGridRef = useRef<any>();
	const gridRef = useRef<AgGridReact<any>>(null);
	// Row Data: The data to be displayed.
	const [rowData, setRowData] = useState([]);
	const rowSelection = useMemo<RowSelectionOptions | 'single' | 'multiple'>(() => {
		return {
			mode: 'multiRow'
		};
	}, []);

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState<ColDef[]>([
		{
			field: 'platformId',
			headerName: 'Platform',
			cellRenderer: (props: any) => {
				return <RowTypography><img src={TeradataIcon} style={{ width: '18px', verticalAlign: 'middle' }} /> {props.data.platformId == 1 ? 'Teradata' : ''}</RowTypography>;
			},
			width: 130
		},
		{
			field: 'schedulerName',
			headerName: 'Scheduler Name',
			minWidth: 120
		},
		{
			field: 'jobName',
			headerName: 'Jobs',
			cellRenderer: (props: any) => {
				return (
					<RowTypography isSuccessor={props.data.successor}>
						{props.data.jobName ? props.data.jobName : '--'}
					</RowTypography>
				);
			},
			minWidth: 250,
			width: 250
		},
	
		{
			field: 'scheduleInfo',
			headerName: 'Schedule Info',
			cellRenderer(props: any) {
				return (
					<RowTypography textTransform="capitalize">{props.data.scheduleInfo ? props.data.scheduleInfo : '--'}</RowTypography>
				);
			},
			minWidth: 240,
			maxWidth: 400
		},
		{
			field: 'slaDate',
			headerName: 'SLA Date (ODate+)',
			cellRenderer(props: any) {
				return (
					<RowTypography textTransform="capitalize">
						{props.data.slaDate == null ? '--' : props.data.slaDate}
					</RowTypography>
				);
			},
			minWidth: window.innerWidth > 1450 ? 210 : 160,
			maxWidth: 400
		},
		
		{
			field: 'slaTime',
			headerName: 'SLA Time (CST)',
			cellRenderer(props: any) {
				const startDate = props.data.slaTime;
				return (
					<RowTypography textTransform="capitalize">
						{startDate?
							  (startDate < 10
									? `000${startDate}`.replace(/(.{2})$/, ':$1')
									: startDate < 100
									? `00${startDate}`.replace(/(.{2})$/, ':$1')
									: startDate.toString().replace(/(.{2})$/, ':$1'))
							: '--'}
					</RowTypography>
				);
			},
			width: 150
		},
		
		{
			field: 'Actions',
			rowDrag: true,
			cellRenderer(props: any) {
				return (
					<IconButton onClick={() => handleTableAction(props.data, 'delete')} title="Click to Delete">
						<DeleteIcon />
					</IconButton>
				);
			},
			width: 100
		}
	]);
	interface Row {
		id: number;
		Job_Name: string;
		Platform_Id: number;
		Start_Time: string;
		Normal_Completion_Time: number;
		slaStatus: number;
		jobStatus: string;
		estimatedTime: string;
		Days: number;
		actualStartTime: string;
		actualEndTime: string;
		scheduleStartTime: number;
		Date: string;
		successor: number;
	}
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorElDetails);
	const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		event.preventDefault();
		event.stopPropagation();
		setAccordionActiveId(index);
		setAnchorEl(event.currentTarget);
	};
	const handleThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setAccordionActiveId(null);
	};

	const { id, name } = useParams();

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	const onHandleReorderSchedules = () => {
        setSelectedRows([]);
		// Deselect all rows
		agGridRef.current && agGridRef.current.deselectAll();
		const temprowData: any = rowData;
		const reorderedRowData: any = [];
		temprowData.filter((d: any, i: number) => {
			reorderedRowData.push({ project_Id: projectId, Job_Id: d.jobId, Job_Index: (pageSize * (page - 1)) + i });
		});
		updateReorderedScheduleData(reorderedRowData);
	};

	const updateReorderedScheduleData = async (reorderedRowData: any) => {
		console.log(reorderedRowData)
		await updateReorderSchedule(reorderedRowData);
		setIsOrderChanged(false);
	};

	const onHandleDragCancel = (isUpdate: boolean) => {
		setIsOrderChanged(isUpdate);
		setSelectedRows([]);
		// Deselect all rows
		agGridRef.current && agGridRef.current.deselectAll();
		setTableLoading(true);
		setTimeout(() => {
			setRowData(jobSchedulesList);
			setTableLoading(false);
		}, 300);
	};

	const onHandleRowDragEnd = (event: any) => {
		agGridRef.current = event.api;
		// Deselect all rows
		agGridRef.current && agGridRef.current.deselectAll();
		const updatedRowData: any = [...rowData];
		updatedRowData.map((data: any) => {
			if (data.jobId == event.node.data.jobId) {
				data['reorderIndex'] = event.overIndex;
			}
		});
		const fromIndex = event.node.id;
		const toIndex = event.node.rowIndex;
		const [movedRow] = updatedRowData.splice(fromIndex, 1);
		updatedRowData.splice(toIndex, 0, movedRow);

		setRowData(updatedRowData);
		setSelectedRows([]);
		setIsOrderChanged(true);
	};

	const onGridReady = (params: any) => {
		agGridRef.current = params.api;
	};

	const onSelectionChangeEvent = (event: any) => {
		agGridRef.current = event.api;
		setSelectedRows(event.api.getSelectedRows());
		console.log(agGridRef.current.getSelectedRows());
	};

	const classes = useStyles();

	const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
	const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false, message: '' });
	const [programDetail, setProgramDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [projectId, setProjectId] = useState('');
	const [tableLoading, setTableLoading] = useState(false);
	const [jobSchedulesList, setjobSchedulesList] = useState([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const defaultColumnNames = [
		'Job',
		'Platform',
		'SLA Status',
		'Job Status',
		'Act. Start Time (CST)',
		'Act. End Time (CST)',
		'Schedule Start Time (CST)',
		'SLA Completion Time (CST)',
		'Estimated Completion (CST)',
		'Date',
		'Actions'
	];
	const [expanded, setExpanded] = useState<any>(false);
	const { prId, prName } = useParams();
	const [nameState, setNameState] = useState(prName);
	const [toast, setToast] = useState<any>({
		open: false,
		message: ''
	});

	const navigate = useNavigate();

	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'Job_Name',
				name: 'Job',
				formatter(props) {
					return (
						<RowTypography title={props.row.Job_Name} isSuccessor={props.row.successor > 0}>
							{props.row.Job_Name ? props.row.Job_Name : '--'}
						</RowTypography>
					);
				},
				minWidth: 170
			},
			{
				key: 'Platform_Id',
				name: 'Platform',
				formatter(props) {
					return (
						<RowTypography isSuccessor={props.row.successor > 0}>
							{props.row.Platform_Id == 1 ? 'Teradata' : ''}
						</RowTypography>
					);
				},
				minWidth: 110
			},

			{
				key: 'slaStatus',
				name: 'SLA Status',
				formatter(props) {
					const status = props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'Not Run';
					return <StatusChip status={status} />;
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'NA'}</div>
				},
				minWidth: 120
			},

			{
				key: 'Normal_Completion_Time',
				name: 'SLA Completion Time (CST)',
				formatter(props) {
					return (
						<RowTypography isSuccessor={props.row.successor > 0} textTransform="capitalize">
							{props.row.Normal_Completion_Time
								? getDays(props.row.Days) +
								  ', ' +
								  (props.row.Normal_Completion_Time < 10
										? `000${props.row.Normal_Completion_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.Normal_Completion_Time < 100
										? `00${props.row.Normal_Completion_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.Normal_Completion_Time.toString().replace(/(.{2})$/, ':$1'))
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'actualStartTime',
				name: 'Act. Start Time (CST)',
				formatter(props) {
					const startDate = props.row.actualStartTime;
					// if (startDate) {
					return (
						<RowTypography isSuccessor={props.row.successor > 0}>
							{' '}
							{startDate && startDate != null
								? moment(props.row.actualStartTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
					// }
					// return '';
				},
				minWidth: 150
			},
			{
				key: 'actualEndTime',
				name: 'Act. End Time (CST)',
				formatter(props) {
					const startDate = props.row.actualEndTime;
					// if (startDate) {
					return (
						<RowTypography isSuccessor={props.row.successor > 0}>
							{' '}
							{startDate
								? moment(props.row.actualEndTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
					// }
					// return '';
				},
				minWidth: 150
			},
			{
				key: 'scheduleStartTime',
				name: 'Schedule Start Time (CST)',
				formatter(props) {
					return (
						<RowTypography isSuccessor={props.row.successor > 0} textTransform="capitalize">
							{props.row.scheduleStartTime
								? getDays(props.row.Days) +
								  ', ' +
								  (props.row.scheduleStartTime < 10
										? `000${props.row.scheduleStartTime}`.replace(/(.{2})$/, ':$1')
										: props.row.scheduleStartTime < 100
										? `00${props.row.scheduleStartTime}`.replace(/(.{2})$/, ':$1')
										: props.row.scheduleStartTime.toString().replace(/(.{2})$/, ':$1'))
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'jobStatus',
				name: 'Job Status',
				formatter(props) {
					const status = props.row.slaStatus == 2 ? 'Not Run' : props.row.jobStatus;
					return <StatusChip status={status} />;
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.jobStatus ? props.row.jobStatus : '--'}</div>
				},
				minWidth: 120
			},
			{
				key: 'estimatedTime',
				name: 'Estimated Completion (CST)',
				formatter(props) {
					const startDate = props.row.estimatedTime;
					if (startDate) {
						return (
							<RowTypography isSuccessor={props.row.successor > 0}>
								{' '}
								{startDate
									? moment(props.row.estimatedTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss')
									: '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 150
			},
			{
				key: 'Date',
				name: 'Date',
				formatter(props) {
					const startDate = props.row.Date;
					if (startDate) {
						return (
							<RowTypography isSuccessor={props.row.successor > 0}>
								{' '}
								{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 100
			},
			{
				key: 'action',
				name: 'Actions',
				minWidth: 100,
				resizable: false,
				formatter: (props: any) => (
					<IconButton onClick={() => handleTableAction(props.row, 'delete')} title="Click to Delete">
						<DeleteIcon />
					</IconButton>
				)
			}
		];
	}

	const handleBulkDeleteAction = () => {
		setOpenModal({
			isOpen: true,
			type: 'Schedule',
			action: 'DELETE',
			from: 'delete',
			data: selectedRows
		});
	};

	const handleTableAction = (props: any, type: string) => {
		if (type == 'delete') {
			console.log(props)
			setOpenModal({
				isOpen: true,
				type: 'Schedule',
				action: 'DELETE',
				from: 'delete',
				data: [props]
			});
		}
	};

	const getDays = (day: number) => {
		let days;
		if (day == 0) {
			days = 'Sun';
		} else if (day == 1) {
			days = 'Mon';
		} else if (day == 2) {
			days = 'Tue';
		} else if (day == 3) {
			days = 'Wed';
		} else if (day == 4) {
			days = 'Thur';
		} else if (day == 5) {
			days = 'Fri';
		} else if (day == 6) {
			days = 'Sat';
		}
		return days;
	};

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setTableLoading(false)
	});

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if (!openModal.isOpen) {
			getProgramDetails();
			getProjectsByProgram();
			// if (openModal.message) {
			//     setToast(true)
			// }
			console.log('TOASTTT', openModal);
		}
	}, [openModal]);

	const onOpenProjectAccordion = (panel: any, projectId: string, jobCount: number) => (event: any, isExpanded: any) => {
		event.preventDefault();
		event.stopPropagation();
		setSelectedRows([]);
		setProjectId(projectId);
		setExpanded(isExpanded ? panel : false);
		setIsOrderChanged(false);
	};

	const getProjectDetails = (e: any, projectId: string, jobCount: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (jobCount) {
			projectMappedJobScheduleFunc(projectId, 1, 100);
		}
	};

	const passInfoToParent = (data: any) => {
		projectMappedJobScheduleFunc(projectId, 1, 100);
	};

	const getProgramDetails = async () => {
		setLoading(true);
		let result = await getProgramById(id);
		setLoading(false);
		setProgramDetail(result.data?.programData ? result.data.programData[0] : '');
		// if (window.location.pathname.split('/')[4] !== programDetail?.program_Name) {
		//     window.location.href = `/data-pipeline/programs/${programDetail.Program_ID}/${programDetail.program_Name}`
		// }
		// jobPerformanceBarChartData();
		navigate(`/data-pipeline/programs/${id}/${encodeURIComponent(result.data.programData[0].program_Name)}`);
	};

	const getProjectsByProgram = async () => {
		setLoading(true);
		let result = await getProjectListByProgram(id, 1, 100);
		// let kpiCountData = await getDpProjectKpi(id);
		let kpiCountData = {};
		let reqData: any = [];
		console.log('HERERRERERE', kpiCountData);
		if (result.data.projectList.length > 0) {
			result.data.projectList.map((data: any, index: number) => {
				// let kpiCount = kpiCountData.data.length
				// 	? kpiCountData.data.filter((obj: any) => obj.Project_Id === data.Project_Id)
				// 	: [];
				let kpiCount: any = [];
				if (kpiCount.length) {
					reqData.push({
						...data,
						completed: JSON.parse(kpiCount[0].Schedule_Execution_Metrics)?.completed || 0,
						failed: JSON.parse(kpiCount[0].Schedule_Execution_Metrics)?.failed || 0,
						sla_met: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_met || 0,
						sla_missed: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_missed || 0,
						sla_pending: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_pending || 0
					});
				} else {
					reqData.push({
						...data,
						completed: 0,
						failed: 0,
						sla_met: 0,
						sla_missed: 0,
						sla_pending: 0
					});
				}
			});
		}

		setLoading(false);
		setProjectList(reqData);
		// setProjectList(result.data.projectList)
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		projectMappedJobScheduleFunc(projectId, value, pageSize);
	};
	const handlepageSizeChange = (event: any) => {
		projectMappedJobScheduleFunc(projectId, page, event.target.value);
	};

	async function projectMappedJobScheduleFunc(projectId: string, pageNumber: number, pageSize: number) {
		setTableLoading(true);
		const res = await getScheduleList(projectId, pageNumber, pageSize);
		if (res.success) {
			setTableLoading(false);
			let jobArr = res.data.selectedJobData;
			let reqData = jobArr;
			reqData.map((data: any) => {
				data['reorderIndex'] = data.jobIndex;
				data['Project_Id'] = projectId;
			});
			console.log(reqData);
			setRowData(reqData);
			setjobSchedulesList(reqData);
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
		}
	}

	const handleEditClose = (data: any) => {
		setOpenModal({
			...openModal,
			isOpen: false
		});
		setSelectedRows([]);
	};

	const onCreateProgramClick = (event: any) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'CREATE'
		});
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		// debugger
		e.preventDefault();
		e.stopPropagation();
		handleThreeDotsClose(e);
		handleDetailsThreeDotsClose(e);
		data = {
			...data,
			ProgramName: programDetail.program_Name,
			ProgramID: programDetail.Program_ID
		};

		console.log('DATTA', data);

		switch (type) {
			case 'edit':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: module === 'Program' ? programDetail : data
				});
				break;
			case 'delete':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'DELETE',
					data: module === 'Program' ? programDetail : data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/projects/${data.Program_ID}/${data.Project_Id}/${encodeURIComponent(
					data.ProgramName
				)}/${encodeURIComponent(data.Project_Name)}`;
				break;
			default:
				break;
		}
	};

	const handleClose = () => {
		setToast({
			...toast,
			open: false
		});
	};

	const getToastData = (data: any) => {
		setToast({
			...toast,
			message: data.data.message
		});
		console.log('OPENNN', data, toast);
		console.log('OPENNN22', data, toast);
	};

	// const getToastMessage = () => {

	// }

	return (
		<Box mt="45px" p={2} bgcolor="blue.darker">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[4].replace(/%20/g, ' '))
					}
				]}
			>
				{role !== 'Data Operations User' && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick}>
						ADD PROJECT
					</Button>
				)}
			</PageHeader>

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal
					handleEditClose={handleEditClose}
					programData={openModal}
					getToastData={getToastData}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Stack spacing={2} mt={4}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={7}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Program Name',
									value: programDetail?.program_Name,
									isLoading: !programDetail?.program_Name,
									valueProps: {
										lineHeight: '24px'
									}
								},
								{
									label: 'Sector',
									value: programDetail?.Sector,
									isLoading: !programDetail?.Sector,
									valueProps: {
										lineHeight: '24px'
									}
								}
							]}
							infoTooltip={{
								dateValues: [
									{ label: 'Date Created', value: programDetail?.Created_Dt ?? 'NA' },
									{ label: 'Last Updated', value: programDetail?.Updated_Dt ?? 'NA' }
								]
							}}
							infoOptionMenu={
								role !== 'Data Operations User'
									? {
											onClick: handleDetailsThreeDotsClick,
											onClose: handleDetailsThreeDotsClose,
											menuItems: [
												{
													key: 'edit',
													label: 'Edit',
													icon: <EditIcon />,
													onMenuClick: (e) => handleAction('Program', 'edit', {}, e)
												},
												{
													key: 'delete',
													label: 'Delete',
													icon: <DeleteIcon />,
													onMenuClick: (e) => handleAction('Program', 'delete', {}, e)
												}
											]
									  }
									: undefined
							}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Total Projects',
									value: programDetail?.ProjectCount || 0,
									isLoading: loading,
									labelProps: {
										fontSize: '16px',
										fontWeight: 700
									},
									valueProps: {
										fontSize: '24px',
										fontWeight: 700,
										lineHeight: '24px'
									}
								}
							]}
						/>
					</Grid>
				</Grid>
				{programDetail?.ProjectCount > 0 && !loading ? (
					<>
						<Stack direction="row" justifyContent="center" alignItems="center" width="100%" px={2} pt={3}>
							<Box width="60%" fontWeight={700}>
								Projects
							</Box>
							<Box width="40%" fontWeight={700}>
								Jobs
							</Box>
						</Stack>
						<Stack
							spacing={2}
							sx={{
								// below mx: 0 is given becoz another css file overriding this component. remove this once all the css files are removed.
								'.MuiPaper-root': {
									mx: '0 !important'
								}
							}}
						>
							{projectList.map((project: any, index: number) => {
								return (
									<Accordion
										key={index}
										expanded={expanded === index}
										onChange={onOpenProjectAccordion(index, project.Project_Id, project.jobCount)}
									>
										<CustomAccordionSummary
											onClick={(e) => getProjectDetails(e, project.Project_Id, project.jobCount)}
											id={`panel-${index}-header`}
										>
											<Stack direction="row" justifyContent="center" alignItems="center" width="100%">
												<Box width="60%">
													<Typography lineHeight="normal" borderRight="1px solid #fff">
														{project.Project_Name ? project.Project_Name : ''}
													</Typography>
												</Box>
												<Box width="40%">
													<Stack direction="row" justifyContent="space-between" alignItems="center">
														<CountBox count={project.jobCount} ml={4} />
														<Stack direction="row" alignItems="center">
															<Link
																to={`/data-pipeline/projects/${project.Program_ID}/${
																	project.Project_Id
																}/${encodeURIComponent(programDetail.program_Name)}/${encodeURIComponent(
																	project.Project_Name
																)}`}
															>
																<ViewHighlightIcon title="Click to View" />
															</Link>
															{role !== 'Data Operations User' && (
																<MoreOptionMenu
																	onClick={(e) => handleThreeDotsClick(e, index)}
																	onClose={handleThreeDotsClose}
																	menuItems={
																		!project.Clarity_Project_Id
																			? [
																					{
																						key: 'edit',
																						label: 'Edit',
																						icon: <EditIcon />,
																						onMenuClick: (e) => handleAction('Project', 'edit', project, e)
																					},
																					{
																						key: 'delete',
																						label: 'Delete',
																						icon: <DeleteIcon />,
																						onMenuClick: (e) => handleAction('Project', 'delete', project, e)
																					}
																			  ]
																			: [
																					{
																						key: 'delete',
																						label: 'Delete',
																						icon: <DeleteIcon />,
																						onMenuClick: (e) => handleAction('Project', 'delete', project, e)
																					}
																			  ]
																	}
																/>
															)}
														</Stack>
													</Stack>
												</Box>
											</Stack>
										</CustomAccordionSummary>
										<AccordionDetails>
											<Stack>
												{project.jobCount && !tableLoading && jobSchedulesList.length ? (
													<>
														<Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
															<Typography height="40px" lineHeight="40px" variant="widgetTitle">Added Jobs</Typography>
															{selectedRows.length > 1 && !isOrderChanged && (
																<Button variant="pepwiseErrorOutline" onClick={handleBulkDeleteAction}>
																	DELETE ALL SELECTED
																</Button>
															)}
															{isOrderChanged && (
																<Box display="flex" gap={1}>
																	<Button variant="pepwiseOutline" onClick={(e) => onHandleDragCancel(false)}>
																		CANCEL
																	</Button>
																	<Button variant="pepwiseSecondary" onClick={onHandleReorderSchedules}>
																		SAVE & CONFIRM
																	</Button>
																</Box>
															)}
														</Box>
														<StyledAgGridReact
															rowData={rowData}
															columnDefs={colDefs}
															rowDragManaged={true}
															onRowDragEnd={(e) => onHandleRowDragEnd(e)}
															rowSelection={rowSelection}
															onSelectionChanged={onSelectionChangeEvent}
															onGridReady={onGridReady}
														/>
														{
															rowData && <PaginationDetails
															page={page}
															pageSize={pageSize}
															paginationData={paginationData}
															listLength={paginationData.totalElements}
															handlepageSizeChange={handlepageSizeChange}
															handlePageChange={handlePageChange}
														/>
														}
														{/* <CustomizedDataGrid
																			tableColumns={getColumns()}
																			tableData={jobSchedulesList}
																			tableType={`job-schedules-`+ index}
																			defaultColumnNames={defaultColumnNames}
																			page={page}
																			pageSize={pageSize}
																			paginationData={paginationData}
																			listLength={jobSchedulesList.length}
																			handlepageSizeChange={handlepageSizeChange}
																			handlePageChange={handlePageChange}
																			isDateRangeFilter={false}
																			isDisabled={false}
																			isFetching={true}
																			/> */}
													</>
												) : project.jobCount && !tableLoading && jobSchedulesList.length == 0 ? (
													<Typography textAlign="center">No result found</Typography>
												) : tableLoading ? (
													<Loader />
												) : (
													''
												)}
												{!project.jobCount && <ShowInfoWidget title="Add Jobs" height="40vh" />}
											</Stack>
										</AccordionDetails>
									</Accordion>
								);
							})}
						</Stack>
					</>
				) : loading ? (
					<Stack justifyContent="center" alignItems="center" height="60vh">
						<Typography variant="pageTitle" color="dark.main">
							Loading...
						</Typography>
					</Stack>
				) : (
					<ShowInfoWidget title="Add Project" height="60vh" />
				)}
			</Stack>
			<Snackbar
				open={toast && openModal.message}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				autoHideDuration={6000}
				onClick={handleClose}
				message={openModal.message}
			/>
		</Box>
	);
};

export default ProgramDetails;
