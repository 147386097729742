import { useEffect, useRef, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Stack, Switch, Typography } from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import '../DataEstate/data-estate.scss';
import ReactECharts from 'echarts-for-react';
import { getIncidentDetails, incidentSearchForJobExecutions, getSlaReportData, getDatabricksClusterImpactDetails, fetchChartData, getTeradataJobExecutions, fetchJobPerformance } from '../../service/service';
import { Column } from 'react-data-grid';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import moment from 'moment';
import { getJobExecutions } from '../../service/service';
import { OverflowTooltip } from '../overflow_tooltip';
import DateRangeFilter from 'components/Dropdown/DateRangeFilter';
import StatusChip from 'components/Table/StatusChip';
import RowTypography from 'components/Table/RowTypography';
import CustomDropdown from 'components/Dropdown';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import LoaderComponent from 'components/Loader';

interface Row {
	id: string;
	scheduledJob: string;
	sector: string;
	system: string;
	process: string;
	projects: string;
	jobStatus: string;
	startTime: string;
	endTime: string;
	slaEndTime: string;
	slaStatus: string;
	actualStartTime: string;
	normalCompletionTime: string;
	actualCompletionTime: string;
	currentExecutionTime: string;
	averageExecutionTime: string;
	reason: string;
	followUp: string;
	actionTaken: string;
	lastSuccessfulRun: string;
	estimatedDuration: string;
	executionFrequency: string;
	criticalFlag: string;
	date: string;
	jobName: string;
	name: string;
	runStatus: string;
	reasonForDelay: string;
	runStartTime: string;
	runEndTime: string;
	clusterName: string;
	driverNodeType: string;
	errorMessage: string;
	timestamp: string;
	projectCode: string;
	projectNumber: string;
	projectName: string;
	sparkVersion: string;
	workspaceName: string;
	clusterId: string;
	jobId: string;
	runName: string;
	status: string;
	time: string;
	nodeType: string;
	dfProjectCode: string;
	dfDataAssetName: string;
	iterationDate: string;
}

export default function IncidentImpactAnalysis() {
	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [month, setFilterMonth] = useState("0M");
	const params = useParams();
	const location = useLocation();
	const paramsData = location.state;
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [boxLoading, setBoxLoading] = useState(false);
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const [dateTimeRange, setDateTimeRange] = useState<any>(
		[
			paramsData?.outageStartTime ? moment(paramsData.outageStartTime).subtract(1, 'days') : paramsData?.openedDate ? moment(paramsData.openedDate).subtract(1, 'days')
				: moment().startOf('day').subtract(1, 'hours'),
				paramsData?.outageEndTime
				? moment(paramsData.outageEndTime).add(2, 'days').isSameOrAfter(moment().startOf("day"))
					? moment().startOf("day") 
					: moment(paramsData.outageEndTime).add(2, 'days')
				: paramsData?.resolvedDate
				? moment(paramsData.resolvedDate).add(2, 'days').isSameOrAfter(moment().startOf("day"))
					? moment().startOf("day")
					: moment(paramsData.resolvedDate).add(2, 'days')
				: moment().endOf('day').subtract(1, 'hours')
		]
	);
	const [execution, setExecution] = useState(true)
	const [executionTitle, setExecutionTitle] = useState(`Jobs Executed On ${moment(dateTimeRange[0]).format('YYYY-MM-DD')}`)
	const [toggleTitle, setToggleTitle] = useState('By Execution')
	const [performanceTitle, setPerformanceTitle] = useState('Job Performance By Day')

	const [reasonList, setReasonList] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });
	const [incidentType, setIncidentType] = useState('teradata');

	function getDatabricksColumns(): Array<Column<Row>> {
		return [
			{
                key: 'date',
                name: 'Date',
                width: 180,
                minWidth: 180,
				formatter(props) {
					const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
					return data;
				},
			},
			{
                key: 'time',
                name: 'Time', 
                width: 180,
                minWidth: 180,
				formatter(props) {
					const data = props.row.time ? moment(props.row.time).utc().format('hh:mm:ss') : '-';
					return data;
				},
			},
			{
				key: 'workspaceName',
				name: 'Workspace Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.workspaceName ? props.row.workspaceName : '-';
					return data;
				}
			},
			{
				key: 'clusterId',
				name: 'Cluster ID',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.clusterId ? props.row.clusterId : '-';
					return data;
				}
			},
			{
				key: 'clusterName',
				name: 'Cluster Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.clusterName ? props.row.clusterName : '-';
					return <RowTypography>{data}</RowTypography>;
				}
			},
			{
				key: 'status',
				name: 'Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.status;
					return <StatusChip status={status == null ? 'N/A' : status} />
				}
			},
			{
				key: 'sparkVersion',
				name: 'Spark Version',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.sparkVersion ? props.row.sparkVersion : '-';
					return data;
				}
			},
			{
                key: 'driverNodeType',
                name: 'Driver Node Type',
                minWidth: 150,
                width: 180,
				formatter(props) {
					const data = props.row.driverNodeType ? props.row.driverNodeType : '-';
					return data;
				}
            },
			{
                key: 'nodeType',
                name: 'Node Type',
                minWidth: 150,
                width: 180,
				formatter(props) {
					const data = props.row.nodeType ? props.row.nodeType : '-';
					return data;
				}
            },
			
			{
				key: 'jobId',
				name: 'Job ID',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.jobId ? props.row.jobId : '-';
					return <RowTypography>{data}</RowTypography>;
				}
			},
			{
                key: 'runName',
                name: 'Run Name',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.runName ? props.row.runName.replaceAll('?', '') : '-';
					return <RowTypography>{data}</RowTypography>;
				}
            },
			{
                key: 'errorMessage',
                name: 'Error Message',
                width: 230,
                minWidth: 200,
				formatter(props) {
					const data = props.row.errorMessage ? props.row.errorMessage : '-';
					return <RowTypography>{data}</RowTypography>;
				}
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.sector ? props.row.sector : '-';
					return <RowTypography>{data}</RowTypography>;
				}
			},
			{
				key: 'projectCode',
				name: 'Project Code',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.projectCode ? props.row.projectCode : '-';
					return data;
				}
			},
			{
				key: 'projectNumber',
				name: 'Project Number',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.projectNumber ? props.row.projectNumber : '-';
					return data;
				}
			},
			{
				key: 'projectName',
				name: 'Project Name',
				minWidth: 200,
                width: 200,
				formatter(props) {
					const data = props.row.projectName ? props.row.projectName : '-';
					return data;
				}
            },
			{
				key: 'dfProjectCode',
				name: 'DF Project Code',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.dfProjectCode ? props.row.dfProjectCode : '-';
					return data;
				}
			},
			{
				key: 'dfDataAssetName',
				name: 'DF Data Asset Name',
                minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.dfDataAssetName ? props.row.dfDataAssetName : '-';
					return data;
				}
            },
		]
	}

	function getColumns(): Array<Column<Row>> {
        return [
			// {
            //     key: 'iterationDate',
			// 	// key: 'date',
            //     name: 'Date',
            //     width: 180,
            //     minWidth: 180,
			// 	formatter(props) {
			// 		const iterationDate = props.row.iterationDate;
			// 		if (iterationDate) {
			// 			return moment(iterationDate).format('MM-DD-YY');
			// 		}
			// 		return '';
			// 		// const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
			// 		// return data;
			// 	}
			// },
			{
				key: 'name',
				// key: 'jobName',
				name: 'Job Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.name ? props.row.name : '-';
					return <RowTypography>{data}</RowTypography>
					// const data = props.row.jobName ? props.row.jobName : '-';
					// return <RowTypography>{data}</RowTypography>
				}
			},
			{
                key: 'sector',
                name: 'Sector',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.sector ? props.row.sector : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
                key: 'system',
				name: 'System',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.system ? props.row.system : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
                key: 'process',
                name: 'Projects',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.process ? props.row.process.replaceAll('?', '') : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.slaStatus;
					return <StatusChip status={status == null ? 'Scheduled' : status === 'Met' ? 'Sla Met' : status === 'Not Met' ? 'Sla Missed' : status}/>;
				}
			},
			...(execution ? [{
				key: 'runStatus',
				// key: 'jobStatus',
				name: 'Job Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props: { row: { runStatus: any; }; }) {
					const status = props.row.runStatus;
					// const status = props.row.jobStatus;
					return <StatusChip status={status == null ? 'Scheduled' : status}/>;
				}
			}] : []),
			
            {
                key: 'runEndTime',
				// key: 'actualCompletionTime',
                name: 'Act. End Time (CST)',
                minWidth: 150,
                width: 200,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).tz('America/Chicago').format('MM-DD-YY HH:mm:ss');
					}
					return '';
					// const data = props.row.actualCompletionTime ? props.row.actualCompletionTime : '-';
					// return data;
				}
            },
			{
                key: 'slaEndTime',
				// key: 'normalCompletionTime',
                name: 'SLA Time (CST)',
                minWidth: 150,
                width: 200,
				formatter(props) {
					// return (
					// 	<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
					// 		{moment.tz(props.row.slaEndTime, "HH:mm", "America/New_York").tz("America/Chicago").format("HH:mm")}
					// 	</OverflowTooltip>
					// );
					return (
						<RowTypography>
							{moment.tz(props.row.slaEndTime, "HH:mm", "America/New_York").tz("America/Chicago").format("HH:mm")}
						</RowTypography>
					);
					// const data = props.row.normalCompletionTime ? props.row.normalCompletionTime : '-';
					// return <RowTypography>{data}</RowTypography>
				}
            },
			...(execution ? [{
                key: 'reasonForDelay',
				// key: 'reason',
                name: 'Reason',
                width: 230,
                minWidth: 200,
				formatter(props: { row: { reasonForDelay: any; }; }) {
					const data = props.row.reasonForDelay ? props.row.reasonForDelay : '-';
					return <RowTypography>{data}</RowTypography>
					// const data = props.row.reason ? props.row.reason : '-';
					// return <RowTypography>{data}</RowTypography>
				}
            }] : []),
            // {
            //     key: 'scheduledJob',
            //     name: 'Scheduled Job',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.scheduledJob ? props.row.scheduledJob : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'startTime',
            //     name: 'Start Time',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.startTime ? props.row.startTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'endTime',
            //     name: 'End Time',
            //     width: 150,
            //     minWidth: 130,
			// 	formatter(props) {
			// 		const data = props.row.endTime ? props.row.endTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'slaEndTime',
            //     name: 'SLA End Time',
            //     minWidth: 150,
			// 	formatter(props) {
			// 		const data = props.row.slaEndTime ? props.row.slaEndTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'currentExecutionTime',
            //     name: 'Current Execution Time',
            //     minWidth: 150,
            //     width: 200,
			// 	formatter(props) {
			// 		const data = props.row.currentExecutionTime ? props.row.currentExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'averageExecutionTime',
            //     name: 'Average 15 Days Exec. Time',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.averageExecutionTime ? props.row.averageExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'followUp',
            //     name: 'Follow Up',
            //     minWidth: 150,
            //     width: 150,
			// 	formatter(props) {
			// 		const data = props.row.followUp ? props.row.followUp : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'actionTaken',
            //     name: 'Action Taken',
            //     minWidth: 140,
            //     width: 140,
			// 	formatter(props) {
			// 		const data = props.row.actionTaken ? props.row.actionTaken : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'estimatedDuration',
            //     name: 'Estimated Duration',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.estimatedDuration ? props.row.estimatedDuration : '-';
			// 		return data;
			// 	}
            // },
        ];
    }

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [slaReport, setSlaReport] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

	const defaultDatabricksColumnNames = [
		'Date',
		'Time',
		'Workspace Name',
		'Cluster ID',
		'Cluster Name',
		'Status',
		'Spark Version',
		'Driver Node Type',
		'Node Type',
		'Job ID',
		'Run Name',
		'Error Message',
		'Sector',
		'Project Code',
		'Project Number',
		'Project Name',
		'DF Project Code',
		'DF Data Asset Name',
        'Workspace ID',
        'DBR Version'
	];
	const defaultColumnNames = [
		'Job Name',
        'Sector',
        'System',
        'Projects',
		'SLA Status',
		'Job Status',
		'Act. Start Time (CST)',
        'Act. End Time (CST)',
		'SLA Time (CST)',	
		'Reason',
		// 'Scheduled Job',
        // 'Start Time',
        // 'End Time',
        // // 'SLA End Time',
        // 'Current Execution Time',
        // 'Average 15 Days Exec. Time',
        // 'Reason',
        // 'Follow Up',
        // 'Action Taken',
        // 'Estimated Duration',
	];
	const defaultColumnNamesProject = [
		
        'Sector',
        'System',
        'Projects',
		'SLA Status',
        'Act. End Time (CST)',
		'SLA Time (CST)',
		'Job Name',
		
		// 'Scheduled Job',
        // 'Start Time',
        // 'End Time',
        // // 'SLA End Time',
        // 'Current Execution Time',
        // 'Average 15 Days Exec. Time',
        // 'Reason',
        // 'Follow Up',
        // 'Action Taken',
        // 'Estimated Duration',
	];


	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		fetchIncidentsDetailFunc(value, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), execution);
		// fetchIncidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
	   fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), execution);
		// fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), execution);
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		fetchIncidentsDetailFunc(1, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), execution);
		// fetchIncidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	//commen
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, 'scheduledJob'));
	};

	let incident = ['INC09423964', 'INC09430828', 'INC09440376', 'INC09548251']

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// [Date Range Menu]
	const handleDateTimeChange = ([from, to]: any) => {
		setDateTimeRange([from, to]);
	};
	const applyDateTimeRange = ([fromDate, toDate]: any) => {
		resetSearchParams();
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, fromDate, toDate, parseFilter(filterInfo),execution);
	};
	
	const resetSearchParams = () => {
		if (hasDrillDowned || searchParams.has('fromDate') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('isDrillDowned');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};

	async function fetchSlaReportData(fromDateTime: any, toDateTime: any) {
		setLoading(true);
		if (incidentType == 'teradata') {
			let fromDate = moment(fromDateTime).format('YYYY-MM-DD')
			let toDate = moment(toDateTime).format('YYYY-MM-DD')
			let type = execution ? 1 : 2
			let res = await fetchJobPerformance(fromDate, toDate, type);
			setSlaReport(res.data)
			incidentChartTeraData(res.data)
		}
	}

	async function fetchIncidentsDetailFunc(pageNumber: any, 
		size: any,
		sortingInfo: any,
		fromDateTime: any,
    	toDateTime: any,
		jobFilterInfo: any,
		pageOnLoad?: boolean,
		execution?: any) {
		setLoading(true);
		let isExecution =''
		if(execution)
			isExecution = 'job';
		else
			isExecution = 'project'

		setDateTimeRange([fromDateTime, toDateTime]);
		let incidentType = 'teradata'
		let incidentId = paramsData?.incidentId
		if (paramsData?.assignmentGroup == 'ADVANCED ANALYTICS PLATFORM SUPPORT' ) {
			incidentType = 'databricks'
		}
		setIncidentType(incidentType);
		let res
		if (incidentType == 'teradata') {
			let fromDate = moment(fromDateTime).format('YYYY-MM-DD')
			let toDate = moment(toDateTime).format('YYYY-MM-DD')
			res = await getTeradataJobExecutions(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDate,
				toDate,
				true,
				isExecution);
		} else if (incidentType == 'databricks') {
			res = await getDatabricksClusterImpactDetails(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime,
				incidentId);
			databricksExecution(res, pageNumber, size, sortingInfo, jobFilterInfo)
			} else {
			res = await getIncidentDetails(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime,
				incidentType);
		}


    
	// async function fetchIncidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	// 	setLoading(true);
	// 	setFromDateTime(fromDateTime);
	// 	setToDateTime(toDateTime);
	// 	let incidentType = 'teradata'
	// 	if (paramsData.incidentId == 'INC08935506') {
	// 		incidentType = 'databricks'
	// 	}
	// 	setIncidentType(incidentType);
	// 	const res = await getIncidentDetails(
	// 		pageNumber.toString(),
	// 		size.toString(),
	// 		sortingInfo,
	// 		jobFilterInfo,
	// 		fromDateTime,
	// 		toDateTime,
	// 		incidentType
	// 	);

	
	let slaData;
	if (incident.includes(paramsData.incidentId)) {
	let sla = {
		startDate: fromDateTime,
		endDate: toDateTime,
		incidentId: paramsData.incidentId
	}
			 slaData = await fetchChartData(sla)	
			 if (slaData.success) {
		
			// setSlaReport(slaData.data)
			// incidentChartData(slaData.data);
			}
	} else {
		// if (pageOnLoad) {
		// slaData = await getSlaReportData(fromDateTime, toDateTime)
		// if (slaData.success) {
		// 	setSlaReport(slaData.data.slaReport)
		// 	incidentChartTeraData(slaData.data.slaReport);
		
		// }
		// }
	}
	    // const slaData = await getSlaReportData(fromDateTime, toDateTime)
	
		
		if (incidentType !== 'databricks' && res.success) {
			setIncidentDetails(res.data.jobs);
			// sla report maping removed
			// if (!Object.keys(filterData).length) {
			// 	setSlaReport(res.data.slaReport)
			// incidentChartTeraData(res.data.slaReport)
			// }
			setPaginationData(res.data.page);
			const filters = res.data.filterOptions;
			let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'iterationDate') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
			filters['iterationDate'] = sortingInfo.sortBy == 'iterationDate' && sortingInfo.sortDir == 'desc' ? formatedDates.reverse() : formatedDates
			setFilterData(filters);
			setPage(pageNumber);
			setPageSize(res.data.page.totalElements);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: jobFilterInfo
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
		}

		setLoading(false);
	}
	const databricksExecution = (res : any,pageNumber :any, size: any, sortingInfo : any, jobFilterInfo : any) => {
		if (res.success) {
			setIncidentDetails(res.data.data);
			setPaginationData(res.data.page);
			const filters = res.data.filterOptions;
			let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'date') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
			filters['date'] = formatedDates
			setFilterData(filters);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
		}
		setLoading(false);
	};

		async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await incidentSearchForJobExecutions(searchDetails, jobFilterInfo);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const incidentChartData = (reportData: any) => {
		// fetchChartData(paramsData.openedDate, paramsData.resolvedDate).then(res => {
		// 	if (res) {
				const startDate = paramsData.outageStartTime ? moment(paramsData?.outageStartTime).subtract(1, 'days') : dateTimeRange[0]
				const endDate = paramsData.outageEndTime ?  moment(paramsData?.outageEndTime).add(2, 'days') : dateTimeRange[1]
				const duration = moment.duration(endDate.diff(startDate));
				const hours = duration.asHours();
				console.log("HOURDAYYYY", startDate, endDate, duration, hours)
				if (hours >= 48) {
					setPerformanceByDay(true)
				}
				let option = {};
				let xAxisArray:any =[];
				let success:any =[];
				let failure:any=[];
				
				
				
				reportData.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
					// if (paramsData.incidentId != 'INC08935506') {
						success.push(res[xAxisArray[i]].success)
						failure.push(res[xAxisArray[i]].failure)
					// }
					// sla_failed.push(res[xAxisArray[i]].sla_missed)
				})
				option = {
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '5%',
						right: '2%',
						bottom: '30%'
					},
					xAxis: {
						type: 'category',
						data: xAxisArray,
						axisLabel: {
							color: '#fff',
							// formatter: (element: string) => {
							// 		return element.split(' ')[2];
							// }
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#fff'
						}
					},
					series: [
						{
							// silent: paramsData?.incidentId == 'INC08935506' ? false : true,
							silent:  true,
							name: 'Successful',
							type: 'bar',
							stack: 'total',
							data: success,
							itemStyle: {
								color: 'rgba(41, 155, 255, 1)'
							}
						},
						{
							silent: true,
							name: 'Failed',
							type: 'bar',
							stack: 'total',
							data: failure,
							itemStyle: {
								color: '#ff7f00'
							}
						}
						// {
						// 	silent: paramsData.incidentId == 'INC08935506' ? false : true,
						// 	name: 'N/A',
						// 	type: 'bar',
						// 	stack: 'total',
						// 	data: sla_total,
						// 	itemStyle: {
						// 		color: '#A9ADCB'
						// 	}
						// }
					],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: true,
						left: '35px',
						bottom: '15px',
						data:  ['Successful', 'Failed'],
						// data: paramsData.incidentId == 'INC08935506' ? ['SLA MISSED'] : ['Successful', 'Failed'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
			// 	}
			// });
		}

		const handleSlaReportOnclick = (param:any) => {
				if(param && param.name){
					setDateTimeRange([param.name,dateTimeRange[1]])
					fetchIncidentsDetailFunc(page, pageSize, sortingInfo, param.name, dateTimeRange[1], parseFilter(filterInfo), true);
				}
			};

		const incidentChartTeraData = (reportData: any) => {
			// fetchChartData(paramsData.openedDate, paramsData.resolvedDate).then(res => {
			// 	if (res) {
					const startDate = moment(paramsData.outageStartTime).subtract(1,'days');
					const endDate = moment(paramsData.outageEndTime).add(2, 'days');
					const duration = moment.duration(endDate.diff(startDate));
					const hours = duration.asHours();
					if (hours >= 48) {
						setPerformanceByDay(true)
					}
					let option = {};
					let xAxisArray:any =[];
					let sla_met:any =[];
					let sla_failed:any=[];
					let sla_total:any=[];
				
					reportData.map((res:any,i:number)=>{
						xAxisArray.push(Object.keys(res)[0])
						// if (paramsData.incidentId != 'INC08935506') {
							sla_met.push(res[xAxisArray[i]].sla_met)
							sla_total.push(res[xAxisArray[i]].sla_pending)
						// }
						sla_total.push(res[xAxisArray[i]].sla_total)
					})
					option = {
						backgroundColor: '#053367',
						grid: {
							top: '13%',
							left: '5%',
							right: '2%',
							bottom: '30%'
						},
						xAxis: {
							type: 'category',
							data: xAxisArray,
							axisLabel: {
								color: '#fff',
								// formatter: (value: any) => {
								// 	const evenHours = ['12AM', '2', '4', '6', '8', '10', '12PM', '2', '4', '6', '8', '10'];
								// 	return evenHours.includes(value) ? value : '';
								// }
							}
						},
						yAxis: {
							type: 'value',
							axisLabel: {
								color: '#fff'
							}
						},
						series: [
							{
								// silent: paramsData.incidentId == 'INC08935506' ? false : true,
								silent: true,
								name: 'SLA Met',
								type: 'bar',
								stack: 'total',
								data: sla_met,
								itemStyle: {
									color: 'rgba(1, 192, 8, 1)'
								}
							},
							{
								silent: true,
								name: 'SLA Missed',
								type: 'bar',
								stack: 'total',
								data: sla_failed,
								itemStyle: {
									color: 'rgba(255, 162, 0, 1)'
								}
							},
							{
								silent:  true,
								// silent: paramsData.incidentId == 'INC08935506' ? false : true,
								name: 'Scheduled',
								type: 'bar',
								stack: 'total',
								data: sla_total,
								itemStyle: {
									color: 'rgba(169, 173, 203, 1)'
								}
							}
						],
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {
							show: true,
							left: '35px',
							bottom: '15px',
							data:  ['SLA Met', 'SLA Missed', 'Scheduled'],
							// data: paramsData.incidentId == 'INC08935506' ? ['SLA MISSED'] : ['SLA Met', 'SLA Missed', 'Scheduled'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
			// 	}
			// });
		}

	const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExecution(event.target.checked);
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), event.target.checked);
		fetchSlaReportData(dateTimeRange[0], dateTimeRange[1]);
		if (event.target.checked) {
			setToggleTitle('By Execution');
			setPerformanceTitle('Job Performance By Day')
			setExecutionTitle(`Job's Executed On ${moment(dateTimeRange[0]).format('YYYY-MM-DD')}`);
		}
		else {
			setToggleTitle('By Project');
			setPerformanceTitle('Project Performance By Day')
			setExecutionTitle(`Project SLA's On ${moment(dateTimeRange[0]).format('YYYY-MM-DD')}`)
		}
	};

	useEffect(() => {
		const startDate = paramsData.outageStartTime ? moment(paramsData?.outageStartTime).subtract(1, 'days') : dateTimeRange[0]
				const endDate = paramsData.outageEndTime ?  moment(paramsData?.outageEndTime).add(2, 'days') : dateTimeRange[1]
				const duration = moment.duration(endDate.diff(startDate));
				const hours = duration.asHours();
		if (hours >= 48) {
			setPerformanceByDay(true)
		}

		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo), true);
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		// Chart
		fetchSlaReportData(dateTimeRange[0], dateTimeRange[1]);
	}, [])

	const seriesData = incident.includes(paramsData.incidentId) ? [
		{ name: 'Successful', dataKey: 'success', color: '#299bff' },
		{ name: 'Failed', dataKey: 'failure', color: '#ff7f00' },
	] : [
		{ name: 'SLA Met', dataKey: 'sla_met', color: 'rgba(1, 192, 8, 1)', is3d: true, shadow: 'rgba(1, 192, 8, 0.5)' },
		{ name: 'SLA Missed', dataKey: 'sla_missed', color: 'rgba(255, 162, 0, 1)', is3d: true, shadow: 'rgba(255, 162, 0, 0.5)' }
		// { name: 'Scheduled', dataKey: 'sla_pending', color: 'rgba(169, 173, 203, 1)' }
	];

	return (
		<Stack p={2} mt='110px' gap={2}>
			<Box 
				display='flex' 
				alignItems='center' 
				gap={1}
				justifyContent='flex-end'
				position='sticky'
				top={120}
				zIndex={1}
				bgcolor="blue.darker"
			>
				<CustomDropdown
					// TODO: give correct options
					options={[
						{label: paramsData?.configurationItem || 'TERADATA PROD', value: paramsData?.configurationItem || 'TERADATA PROD'}
					]}
					value={paramsData?.configurationItem || 'TERADATA PROD'}
					handleChange={()=>{}}
				/>
				{/* [Date Range Menu] */}
				<DateRangeFilter
					fromDateTime={dateTimeRange[0]}
					toDateTime={dateTimeRange[1]}
					handleChange={handleDateTimeChange}
					handleApplyDateChange={applyDateTimeRange}
					minDateTime={minJobExecDate}
				/>
			</Box>
			<FormGroup>
			<FormControlLabel style={{position: 'absolute', top: '86px', right: '2px', zIndex: '100'}} control={<Switch defaultChecked checked={execution} onChange={handleToggleChange} name='' />} label={toggleTitle} />
			</FormGroup>
			<Stack gap={2}>
				{ !grafanaChartLoading ? (
					<BarChartWidget
					title={incidentType == 'teradata' ? (performanceByDay ? performanceTitle : 'Job Performance By Hour') : 'Job clusters by Day'}
					isLoading={grafanaChartLoading}
					data={slaReport}
					seriesData={seriesData}
					handleEvent={handleSlaReportOnclick}
					isEvent={false}
					/>
					) : (
						<Loader />
					)
				}
			</Stack>
			{/* Table */}
			<Box pb={2}>
				{!loading ? (
					<CustomizedDataGrid
						title={executionTitle}
						tableColumns={incidentType == 'teradata' ? getColumns() : getDatabricksColumns()}
						tableData={!!incidentDetails?.length ? incidentDetails : 0}
						tableType={incidentType == 'databricks' ? 'databricks' : incidentType == 'teradata' ? 'impact-teradata' : 'incident'}
						defaultColumnNames={incidentType == 'teradata' ? execution ?  defaultColumnNames : defaultColumnNamesProject : defaultDatabricksColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={incidentDetails && incidentDetails.length != 0 ? incidentDetails : 0}
						handlepageSizeChange={incidentType == 'teradata' ? "" :handlepageSizeChange}
						handlePageChange={incidentType == 'teradata' ? "" : handlePageChange}
						isFetching={loading}
						showExport={false}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton
					/>
				) : (
					<Loader />
				)}
			</Box>
		</Stack>
	);
};
