import { FC, ReactNode } from 'react';
import { AccordionSummary, AccordionSummaryProps, Box } from '@mui/material';
import ExpandMoreIcon from '../../assets/down-arrow.svg';

interface PepwiseAccordionSummaryProps extends AccordionSummaryProps {
	id: string;
	onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	children: ReactNode;
}

const CustomAccordionSummary: FC<PepwiseAccordionSummaryProps> = ({ id, onClick, children, ...props }) => {
	return (
		<AccordionSummary
			expandIcon={<Box component="img" src={ExpandMoreIcon} />}
			onClick={onClick}
			id={id}
			{...props}
			sx={{
				backgroundColor: 'primary.main',
				color: 'white.main',
				fontWeight: 600,
				fontSize: '16px',
				borderRadius: '4px',
				...props.sx
			}}
		>
			{children}
		</AccordionSummary>
	);
};

export default CustomAccordionSummary;
