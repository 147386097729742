import React, { useEffect, useState } from 'react';
import { Stack, Paper, Typography, Table, TableBody, TableCell, TableRow, Chip, Box } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment-timezone';
import { dashboardUrl } from '../../service/config';
import { EditJob } from './edit_job';

import { OverflowTooltip } from '../overflow_tooltip';
import convertSeconds from '../../utils/convertSeconds';
import calenderIcon from '../../assets/ion_calendar-outline.svg';
import timerIcon from '../../assets/ic_sharp-access-time.svg';
import minisIcon from '../../assets/ic_twotone-running-with-errors.svg';
import '../jobs/overview.css';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { RiceBowl } from '@mui/icons-material';

export function Overview({ jobId, afterUpdate, jobOverview }: any) {
	// const classes = useStyles();
	const [jobData, setJobData] = useState<any>({});
	// const [chartData, setChartData] = useState<any>([]);

	const [editOpen, setEditOpen] = React.useState(false);

	moment.updateLocale(moment.locale(), { invalidDate: '' });

	const handleEditOpen = () => {
		setEditOpen(true);
	};

	const handleEditClose = () => {
		setEditOpen(false);
		afterUpdate();
	};

	useEffect(() => {
		fetchJobData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (window.location.pathname === '/jobs') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-list-datas');
			}
		} else if (jobName !== window.location.pathname && window.location.pathname === '/jobs/' + jobName) {
			if (activeId) {
				activeId.classList.add('jobs-list');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('root');
				document.body.classList.remove('jobs-list-datas');
			}
		} else {
			if (activeId) {
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-list-datas');
			}
		}
	}, [jobOverview]);

	function fetchJobData() {
		setJobData(jobOverview);
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
				return <Chip label={status} color="success" />;
			case 'FAILURE':
			case 'TERMINATED':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
				return <Chip label={status} color="warning" />;
			case 'RUNNING':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
		}
	};

	return (
		<Stack direction="column" spacing={2} sx={{ paddingBottom: 0, marginBottom: "50px", '& .MuiTableCell-root':{
										paddingBottom:"20px !important"
									}}}>
			<Stack direction="row" alignItems="flextStart" spacing={2}>
				
					<Paper elevation={1} className="box-panel width-65 h-360" >
					<Box p={2}>
						<Typography variant="h6" component="h6">
							Job Details
						</Typography>
						<Table className="job-overview-table">
							<TableBody>
								<TableRow>
									<TableCell className="attrTitle">Job Name</TableCell>
									<TableCell className="attrValue">{jobData.name}</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<React.Fragment>
										{jobData.scheduleDays === '' ? (
											<React.Fragment>
												<TableCell className="attrTitle">Schedule Dates</TableCell>
												<TableCell className="attrValue">
													{jobData.scheduleDate}{' '}
													{jobData.otherScheduleInfo && <Box mt={2}>{JSON.parse(jobData.otherScheduleInfo).dates}</Box>}
												</TableCell>
											</React.Fragment>
										) : (
											<React.Fragment>
												<TableCell className="attrTitle">Schedule Days</TableCell>
												<TableCell className="attrValue">
													{jobData.scheduleDays}
													{jobData.otherScheduleInfo && (
														<Box mt={2}>{JSON.parse(jobData.otherScheduleInfo).day.replace(/[\[\]"]/g, '')}</Box>
													)}
												</TableCell>
											</React.Fragment>
										)}
									</React.Fragment>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Observed Since</TableCell>
									<TableCell className="attrValue">
										<p className="cal-txt">{jobData.createdAt && moment(jobData.createdAt).format()}</p>
										<img src={calenderIcon} className="cal-icon" />
									</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<TableCell className="attrTitle">Run Window</TableCell>
									<TableCell className="attrValue">
										<p className="cal-txt">
											{jobData.runWindow}
											{jobData.otherScheduleInfo && <Box mt={2}>{JSON.parse(jobData.otherScheduleInfo).runWindow}</Box>}
										</p>
										<img src={timerIcon} className="cal-icon" />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Scheduler</TableCell>
									<TableCell className="attrValue">{jobData.schedulerName}</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<TableCell className="attrTitle">Execution Frequency</TableCell>
									<TableCell className="attrValue">
										<p className="cal-txt">
											{jobData.executionFrequency}
											{jobData.otherScheduleInfo && (
												<Box mt={2}>{JSON.parse(jobData.otherScheduleInfo).frequencyOfExecution}</Box>
											)}
										</p>
										<img src={minisIcon} className="cal-icon" />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Box Name</TableCell>
									<TableCell className="attrValue">
										{jobData.boxName ? (
											<a
												href={`/jobs/${jobData.boxId}`}
												onClick={() => {
													window.location.replace(`/jobs/${jobData.boxId}`);
												}}
											>
												<OverflowTooltip>{jobData.boxName}</OverflowTooltip>
											</a>
										) : (
											'NA'
										)}
									</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<TableCell className="attrTitle">Schedule Time</TableCell>
									<TableCell className="attrValue scheduleTime">
										<p className="cal-txt">
											{jobData.scheduleTime || (jobData.latestRun && jobData.latestRun.scheduleTime)}
											{jobData.otherScheduleInfo && <Box mt={2}>{JSON.parse(jobData.otherScheduleInfo).time}</Box>}
										</p>
										{jobData.scheduleTime ? <img src={timerIcon} className="cal-icon" /> : ''}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Job Type</TableCell>
									<TableCell className="attrValue">{jobData.jobType}</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<TableCell className="attrTitle">Last Successful Run</TableCell>
									<TableCell className="attrValue">
										{jobData.lastSuccessRun && moment(jobData.lastSuccessRun).format()}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Machine</TableCell>
									<TableCell className="attrValue">{jobData.machine}</TableCell>
									<TableCell className="emptyCell"></TableCell>
									<TableCell className="attrTitle">Avg Exec Duration</TableCell>
									<TableCell className="attrValue">
										{jobData.latestRun && convertSeconds(jobData.latestRun.avgRunTime)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
				</Box>
					</Paper>
				<div className="job-execution-iframe-div">
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.jobExecAndSla,
							className: 'jobExecutionIframeContainer',
							otherParams: {
								from: 'now-6h',
								to: 'now',
								'var-jobId': jobId
							}
						}}
					/>
					{/* </Paper> */}
				</div>
			</Stack>

			<Stack direction="row" alignItems="flextStart" spacing={2} sx={{paddingRight:"0px"}}>
			
				<Paper elevation={8} className="box-panel width-65 h-400" style={{height:"400px !important"}}>
				<div className="edit-icon--wrap">
						<IconButton className="edit-icon--style" aria-label="edit" onClick={handleEditOpen} color="primary">
							<EditIcon />
						</IconButton>
					</div>	
					<Box p={2}>
					<Typography variant="h6" component="h6">
						Metadata
					</Typography>
					
					<Table className="job-overview-table">
						<TableBody>
							<TableRow>
								<TableCell className="attrTitle">Job Alias</TableCell>
								<TableCell className="attrValue">{jobData.friendlyName}</TableCell>
								<TableCell className="emptyCell"></TableCell>
								<TableCell className="attrTitle">Estimated Duration</TableCell>
								<TableCell className="attrValue">{jobData.estimatedDuration}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">Description</TableCell>
								<TableCell className="attrValue">{jobData.description}</TableCell>
								<TableCell className="emptyCell"></TableCell>
								<TableCell className="attrTitle">Normal Completion Time</TableCell>
								<TableCell className="attrValue">
									{jobData.slaEndTime ? (
										<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
											<p className="cal-txt">{jobData.slaEndTime}</p>
											<img src={timerIcon} className="cal-icon" />
										</OverflowTooltip>
									) : (
										''
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">Sector</TableCell>
								<TableCell className="attrValue">{jobData.sector}</TableCell>
								<TableCell className="emptyCell"></TableCell>
								<TableCell className="attrTitle">Critical Job</TableCell>
								<TableCell className="attrValue">{jobData.criticalFlag ? 'Yes' : 'No'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">Process</TableCell>
								<TableCell className="attrValue">{jobData.process}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">System</TableCell>
								<TableCell className="attrValue">{jobData.system}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">Application</TableCell>
								<TableCell className="attrValue">{jobData.application}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="attrTitle">Sub Application</TableCell>
								<TableCell className="attrValue">{jobData.subApplication}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					</Box>
					
				</Paper>
				{jobData.latestRun ? (
					<Paper elevation={8} className="box-panel width-30 h-400">
						<Box p={2}>
						<Typography variant="h6" component="h6">
							Recent Run
						</Typography>

						<Table className="job-overview-table">
							<TableBody>
								<TableRow>
									<TableCell className="attrTitle">Status</TableCell>
									<TableCell className="attrValue">{renderStatus(jobData.latestRun.runStatus)}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Start Time</TableCell>
									<TableCell className="attrValue">
										<p className="cal-txt">
											{jobData.latestRun.runStartTime && moment(jobData.latestRun.runStartTime).format()}
										</p>
										<img src={calenderIcon} className="cal-icon" />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">End time</TableCell>
									<TableCell className="attrValue">
										<p className="cal-txt">
											{jobData.latestRun.runEndTime && moment(jobData.latestRun.runEndTime).format()}
										</p>
										<img src={calenderIcon} className="cal-icon" />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">SLA Status</TableCell>
									<TableCell className="attrValue">
										{jobData.latestRun.slaStatus ? (
											<Chip
												label={jobData.latestRun.slaStatus}
												color={jobData.latestRun.slaStatus === 'Met' ? 'success' : 'secondary'}
											/>
										) : (
											'NA'
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Follow Up</TableCell>
									<TableCell className="attrValue">{jobData.latestRun.downstream}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Reason</TableCell>
									<TableCell className="attrValue">{jobData.latestRun.reasonForDelay}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className="attrTitle">Action Taken</TableCell>
									<TableCell className="attrValue">{jobData.latestRun.actionTaken}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						</Box>
						
					</Paper>
				) : (
					''
				)}
			</Stack>
			<Dialog open={editOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<EditJob jobData={jobData} handleEditClose={handleEditClose} />
			</Dialog>
		</Stack>
	);
}
