import { Dispatch } from 'react';
import { CacheDataActions, CacheDataState } from '../types/CacheDataContext';
import { DataEstateStateType } from './type';

export const dataEstateState: DataEstateStateType = {
	metricDetails: null,
	executiveMetricDetails: null,
	databricksClusters: null,
	deStorageSummary: null,
	overviewMetrics: null,
	tableauUserCount: null,
	tableauPrevUserCount: null,
	powerBiUserCount: null,
	teradataStorageLastSyncDate: null,
	azureCostSummaryLastSyncDate: null,
	teraDataSize: null,
	teraDataPrevSize: null,
	teraDataJobs: null,
	teraDataGrowthSize: null,
	powerbiUsageCount: null,
	powerBiPrevUserCount: null
};

export const dataEstateReducer = (state: CacheDataState, action: CacheDataActions): CacheDataState => {
	switch (action.type) {
		case 'METRIC_DETAILS':
			return { ...state, metricDetails: action.value };
		case 'EXEC_METRIC_DETAILS':
			return { ...state, executiveMetricDetails: action.value };
		case 'DB_CLUSTERS':
			return { ...state, databricksClusters: action.value };
		case 'DE_STORAGE_SUMMARY':
			return { ...state, deStorageSummary: action.value };
		case 'OVERVIEW_METRICS':
			return { ...state, overviewMetrics: action.value };
		case 'TABLEAU_USER_COUNT':
			return { ...state, tableauUserCount: action.value };
		case 'TABLEAU_PREV_USER_COUNT':
			return { ...state, tableauPrevUserCount: action.value };
		case 'POWERBI_USER_COUNT':
			return { ...state, powerBiUserCount: action.value };
		case 'TERADATA_STORAGE_LAST_SYNC_DATE':
			return { ...state, teradataStorageLastSyncDate: action.value };
		case 'COST_SUMMARY_LAST_SYNC_DATE':
			return { ...state, azureCostSummaryLastSyncDate: action.value };
			case 'TERADATA_SIZE':
			return { ...state, teraDataSize: action.value };
		case 'TERADATA_PREV_SIZE':
			return { ...state, teraDataPrevSize: action.value };
		case 'TERADATA_GROWTH_SIZE':
			return { ...state, teraDataGrowthSize: action.value };
		case 'TERADATA_JOBS':
			return { ...state, teraDataJobs: action.value };
		case 'POWERBI_USAGE_COUNT':
				return { ...state, powerbiUsageCount: action.value };
		case 'POWERBI_PREV_USER_COUNT':
					return { ...state, powerBiPrevUserCount: action.value };
		default:
			return state;
	}
};

// Action creator
export const setMetricDetails = (dispatch: Dispatch<CacheDataActions>, value: CacheDataState['metricDetails']) =>
	dispatch({ type: 'METRIC_DETAILS', value });

export const setExecutiveMetricDetails = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['executiveMetricDetails']
) => dispatch({ type: 'EXEC_METRIC_DETAILS', value });

export const setDataBricksClusters = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['databricksClusters']
) => dispatch({ type: 'DB_CLUSTERS', value });

export const setDEStorageSummary = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['deStorageSummary']
) => dispatch({ type: 'DE_STORAGE_SUMMARY', value });

export const setOverviewMetrics = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['overviewMetrics']
) => dispatch({ type: 'OVERVIEW_METRICS', value });

export const setTableauUserCount = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['tableauUserCount']
) => dispatch({ type: 'TABLEAU_USER_COUNT', value });

export const setTableauPrevUserCount = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['tableauPrevUserCount']
) => dispatch({ type: 'TABLEAU_PREV_USER_COUNT', value });

export const setPowerBiUserCount = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['powerBiUserCount']
) => dispatch({ type: 'POWERBI_USER_COUNT', value });

export const setTeradataStorageLastSyncDate = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['teradataStorageLastSyncDate']
) => dispatch({ type: 'TERADATA_STORAGE_LAST_SYNC_DATE', value });

export const setAzureCostSummaryLastSyncDate = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['azureCostSummaryLastSyncDate']
) => dispatch({ type: 'COST_SUMMARY_LAST_SYNC_DATE', value });

export const setTeradataDetails = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['teraDataSize'],
	type: 'TERADATA_SIZE' | 'TERADATA_PREV_SIZE' | 'TERADATA_JOBS' | 'TERADATA_GROWTH_SIZE'
) => dispatch({ type: type, value });

export const setPowerbiUsageCount = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['powerBiUserCount']
) => dispatch({ type: 'POWERBI_USAGE_COUNT', value });

export const setPowerBIPreviousUsageCount = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['powerBiPrevUserCount']
) => dispatch({ type: 'POWERBI_PREV_USER_COUNT', value });
