import React, { useEffect, useState, useRef } from 'react';
import {
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import { fetchSectors } from '../../service/service';
import { powerBICapacityStatus } from '../../service/service';
import { powerBICUUtil } from '../../service/service';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import CapacityObservabilityLogo from 'assets/powerbi_capacity_obs.svg';
import CustomDropdown from 'components/Dropdown';

const CapacityObservability = () => {
	interface Row {
		capacityName: string;
		capacityOwner: string;
		capacityStatus: string;
		workspaceName: string;
		sector: string;
		workspaceOwner: string;
		itemKind: string;
		itemName: string;
		itemStatus: string;
		itemOwner: string;
		operation: string;
		CU_Percentage: string;
		duration: string;
		throttled: string;
		frozen: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'capacityName',
				name: 'Capacity',
				formatter(props) {
					return <RowTypography>{props.row.capacityName ? props.row.capacityName : '--'}</RowTypography>;
				},
				width: 230,
				minWidth: 230
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'capacityStatus',
				name: 'Capacity Status',
				width: 150,
				minWidth: 150,
				cellClass: 'center-text',
				formatter: ({ row }) => <StatusChip status={row.capacityStatus} />
			},
			{
				key: 'workspaceName',
				name: 'Workspace',
				formatter(props) {
					return <RowTypography>{props.row.workspaceName ? props.row.workspaceName : '--'}</RowTypography>;
				},
				width: 230,
				minWidth: 230
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 230
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 200,
				minWidth: 200
			},
			{
				key: 'itemKind',
				name: 'Item Kind',
				formatter(props) {
					return <RowTypography>{props.row.itemKind ? props.row.itemKind : '--'}</RowTypography>;
				},
				minWidth: 230
			},
			{
				key: 'itemName',
				name: 'Item Name',
				formatter(props) {
					return <RowTypography>{props.row.itemName ? props.row.itemName : '--'}</RowTypography>;
				},
				minWidth: 230
			},
			{
				key: 'itemStatus',
				name: 'Item Status',
				minWidth: 130,
				width: 130
			},
			{
				key: 'itemOwner',
				name: 'Item Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'operation',
				name: 'Operation',
				width: 180,
				minWidth: 180
			},
			{
				key: 'CU_Percentage',
				name: 'CU %',
				minWidth: 125,
				width: 125
			},
			{
				key: 'duration',
				name: 'Duration',
				minWidth: 130,
				width: 130
			},
			{
				key: 'throttled',
				name: 'Throttled',
				minWidth: 100,
				width: 100
			},
			{
				key: 'frozen',
				name: 'Frozen',
				minWidth: 100,
				width: 100
			}
		];
	}

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [chartLoading, setChartLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState<{ [key: string]: any }>({});
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [metricsData, setMetricsData] = useState<any>({
		healthyCount: 0,
		throttledCount: 0,
		frozenCount: 0,
		riskCount: 0,
		frozenRecoveredCount: 0,
		totalCount: 0
	});
	const [chartOption, setChartOption] = useState<any>();
	const [capacityStatusFilter, setCapacityStatusFilter] = useState<string | null>(null);
	const checkedListRef = useRef<any>([]);
	const [timePeriod, setTimePeriod] = useState('Monthly');

	const defaultColumnNames = [
		'Capacity',
		'Capacity Owner',
		'Capacity Status',
		'Workspace',
		'Sector',
		'Workspace Owner',
		'Item Kind',
		'Item Name',
		'Item Status',
		'Item Owner',
		'Operation',
		'CU %',
		'Duration',
		'Throttled',
		'Frozen'
	];

	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList

		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
			filterInfo['sector']=updatedCheckedList;
		} else {
			setCurrSectorFilter([]);
			filterInfo['sector']='';
		}
		Object.keys(filterInfo).forEach((key: any) => {
			if(key !== 'sector'){
				delete filterInfo[key];
			}
		});
		setFilterInfo(filterInfo);
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		fetchData();
		powerBICUUtil(updatedCheckedList);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		capacityDetailFunc(1, pageSize, sortingInfo, null);
		//fetchCapacityData();
		fetchChartData(timePeriod);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		capacityDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		capacityDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		console.log(filterInfo, 'filterinfo');
		capacityDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	// const handleSearch = (searchDetails: any, columnKey: any) => {
	// 	// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		console.log(filterInfo,name,"parse")
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		console.log(parsedFilter,"parsefilter")
		return parsedFilter;
	};

async function capacityDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	// Reset page number if page size has changed
    if (pageSize !== size) {
        pageNumber = 1;
    }

		try {
			// Retrieve stored sector filter from localStorage
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;

			setTableLoading(true);

			let response;

			// Check if a status is selected in jobFilterInfo
			if (jobFilterInfo?.capacityStatus) {
				// Call the powerBICapacityStatus API to get filtered data by status
				response = await powerBICapacityDetailData(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null
				);
			} else {
				// Otherwise, call the general capacity detail API
				response = await powerBICapacityDetailData(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null
				);
			}

			// Check if response contains the necessary data
			const capacityData = response?.data?.result || []; // Assuming 'result' contains the relevant data

			// Transform the fetched data into the desired structure
			const incidentData = capacityData.map((item: any) => ({
				incidentId: item.CapacityId, // Using CapacityId as incidentId for uniqueness
				capacityName: item.capacityName,
				capacityOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
				capacityStatus: item.CapacityStatus,
				workspaceName: item.workspaceName,
				sector: item.sector,
				workspaceOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
				itemKind: item.itemKind,
				itemName: item.itemName,
				itemStatus: item.ItemStatus,
				itemOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
				operation: item.Operation,
				CU_Percentage: item.CU_Percentage,
				duration: '-', // Update as needed
				throttled: item.Throttled,
				frozen: item.Frozen
			}));

			setIncidentDetails(incidentData);

			// Update pagination data based on the response
			const paginationData = response.data.page;
			setPaginationData(paginationData); // Set pagination data directly from response

			// Update state for current page and size
			if (pageNumber > response.data.page.totalPages) {
				setPage(response.data.page.currentPage);
			} else {
				setPage(pageNumber);
			}
			setPageSize(size);
			setSortingInfo({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
			setFilterData(response?.data?.filterOptions);
		} catch (error) {
			console.error('Error fetching capacity data:', error);
		} finally {
			setTableLoading(false);
		}
	}

	async function powerBICapacitySearchFunc(searchDetails: any, columnKey: any) {
		const res = await powerBICapacitySearch(
			searchDetails,
			columnKey,
			parseFilter(filterInfo),
			checkedListRef.current,
			drillDownStatusfilterInfo
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey);
		if (searchDetails?.length >= 3) powerBICapacitySearchFunc(searchDetails, columnKey);
	};
	const handleCapacityClick = (status: string) => {
		setCapacityStatusFilter(status); 
		const statusArray:any =[];
		statusArray.push(status)
		setFilterInfo([]);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';

        // Update the state with the new JSON array
		updateFilterInfo('capacityStatus', statusArray);
		// Fetch data and filter by status
		capacityDetailFunc('1', pageSize, sortingInfo, { capacityStatus: statusArray });
	};
	const updateFilterInfo = (key: string, value: any) => {
		setFilterInfo(prev => ({ ...prev, [key]: value }));
	};
	
	async function fetchData() {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
			setLoading(true);
			const response = await powerBICapacityMetricsData(storedSectorFilter);

			// Transform the data to match the expected structure
			if (response && response.data) {
				const transformedData = {
					healthyCount: response.data.data.CapacitiesHealthy || 0,
					throttledCount: response.data.data.CapacitiesThrottled || 0,
					frozenCount: response.data.data.CapacitiesFrozen || 0,
					riskCount: response.data.data.CapacitiesAtRiskOfGettingThrottled || 0,
					frozenRecoveredCount: response.data.data.CapacitiesOutOfFrozen || 0,
					totalCount: response.data.data.latestRecords.length || 0
				};
				setMetricsData(transformedData);
			} else {
				console.error('No data returned from API');
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	}

	const fetchChartData = async (timePeriod: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
			setChartLoading(true);	
			// Simulate fetching chart data
				const response = await powerBICUUtil(storedSectorFilter);
				const currentDate = new Date();const currentYear = currentDate.getFullYear();const currentMonth = currentDate.getMonth();
			    console.log(response,'chart-Response');console.log(timePeriod,'timePeriod');

				if (response && response.data && response.data.weeklyCU && timePeriod == 'Weekly') {
					const fetchedData = response.data.weeklyCU;
				
					// Filter and organize data for currentYear
					const reportData = fetchedData
						.filter((item: { Year: number; }) => item.Year === currentYear) 
						.reduce((acc: { [x: string]: { [x: string]: number; }; }, item: { Year: any; Week: any; Weekly_CU_Percentage: any; }) => {
							const { Year, Week, Weekly_CU_Percentage } = item;
				
							// Calculate starting date for each week
							const startDate = new Date(Year, 0, (Week - 1) * 7);
							const formattedDate = startDate.toLocaleString('en-US', { day: 'numeric', month: 'short' });
				
							// Categorize CU% level
							const level = categorizeCULevel(Weekly_CU_Percentage);
				
							// Initialize week object if it doesn't exist
							if (!acc[formattedDate]) {
								acc[formattedDate] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
							}
				
							// Increment the count for level
							acc[formattedDate][level] += 1;
				
							return acc;
						}, {});
				
						const formattedData = Object.entries(reportData).map(([date, data]) => ({
							[date]: data
						}));
					    setChartOption(formattedData);
				}
				else if (response && response.data && response.data.monthlyCU && timePeriod == 'Monthly') {
					const fetchedData = response.data.monthlyCU;
				
					// Organize data by month and categorize by CU% ranges
					const reportData = fetchedData
					.filter((item: { Year: number; }) => item.Year === currentYear) 
					.reduce((acc: { [x: string]: { [x: string]: number; }; }, item: { Year: any; Month: { toString: () => string; }; Monthly_CU_Percentage: any; }) => {
					
					const month = `${item.Year}-${item.Month.toString().padStart(2, '0')}`;
					const percentage = item.Monthly_CU_Percentage;
					
					// Categorize CU% level
					let level = categorizeCULevel(percentage);
					
					// Initialize the month object if it doesn't exist
					if (!acc[month]) {
						acc[month] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
					}
					
					// Increment the count for the level
					acc[month][level] += 1;
					
					return acc;
				}, {});
				
				const formattedData = Object.keys(reportData).map(month => {
					const [year, monthNum] = month.split('-');
					// Format as "Jan 24"
			        const date = new Date(parseInt(year), parseInt(monthNum) - 1);
			        const formattedMonth = date.toLocaleString('en-US', { month: 'short' });
			        const formattedYear = year.slice(-2);
			        const newKey = `${formattedMonth} ${formattedYear}`;
					return { [newKey]: reportData[month] };
				});
				setChartOption(formattedData);
				}
				else if (response && response.data && response.data.dailyCU && timePeriod === 'Daily') {
					const fetchedData = response.data.dailyCU;
					
					// Filter organize current year and latest month/last available month from API
					const monthsInData = fetchedData
					.filter((item: { Date: string | number | Date; }) => new Date(item.Date).getFullYear() === currentYear)
					.map((item: { Date: string | number | Date; }) => new Date(item.Date).getMonth());
					const lastMonthInData = Math.max(...monthsInData);
				
					const reportData = fetchedData
						.filter((item: { Date: string }) => {
							const itemDate = new Date(item.Date);
							return itemDate.getFullYear() === currentYear && (itemDate.getMonth() === currentMonth || itemDate.getMonth() === lastMonthInData);
						})
						.reduce((acc: { [x: string]: { [x: string]: number } }, item: { Date: string, Daily_CU_Percentage: number }) => {
							const { Date:dateString, Daily_CU_Percentage } = item;
				
							const itemDate =  new Date(dateString);
							const formattedDate = itemDate.toLocaleString('en-US', { day: 'numeric', month: 'short' }); // Format date as "Jan 1", "Jan 2", etc.
							
							// Categorize CU% level
							const level = categorizeCULevel(Daily_CU_Percentage);
				
							// Initialize day object if it doesn't exist
							if (!acc[formattedDate]) {
								acc[formattedDate] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
							}
				
							// Increment the count for the level
							acc[formattedDate][level] += 1;

							return acc;
						}, {});
						
						const formattedData = Object.entries(reportData).map(([date, data]) => ({
						[date]: data
					    }));
					setChartOption(formattedData);
				}
				else if (response && response.data && response.data.hourlyCU && timePeriod === 'Hourly') {
					const fetchedHourlyData = response.data.hourlyCU;
			
					// Filter for the current year and find the latest month in data
					const filteredHourlyData = fetchedHourlyData.filter(
						(item: { Year: number }) => item.Year === currentYear
					);
			
					const monthsInHourlyData = filteredHourlyData.map((item: { Month: number }) => item.Month);
					const latestMonthInHourlyData = Math.max(...monthsInHourlyData); 
					
					// Filter to only get data from the latest day of the latest month available
					const daysInLatestMonth = filteredHourlyData
						.filter((item: { Month: number }) => item.Month === latestMonthInHourlyData)
						.map((item: { Day: number }) => item.Day);
			
					const latestDay = Math.max(...daysInLatestMonth);
					const hourlyReportData = filteredHourlyData
						.filter((item: { Month: number; Day: number }) => 
							item.Month === latestMonthInHourlyData && item.Day === latestDay
						)
						.reduce((acc: { [hour: string]: { [x: string]: number } }, item: { Hour: number, Hourly_CU_Percentage: number | null }) => {
							// const hourKey = `${item.Hour}:00`;
							const hourKey = `${item.Hour}`;
			
							// Categorize CU% level
							const level = item.Hourly_CU_Percentage !== null ? categorizeCULevel(item.Hourly_CU_Percentage) : 'Unknown';
			
							// Initialize hour object if it doesn't exist
							if (!acc[hourKey]) {
								acc[hourKey] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
							}
			
							// Increment the count for the level
							acc[hourKey][level] += 1;
			
							return acc;
						}, {});
			
					const formattedHourlyData = Object.entries(hourlyReportData).map(([hour, data]) => ({
						[hour]: data
					}));
					setChartOption(formattedHourlyData);
				}
			else{
				console.error('No data returned from API');
			}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoading(false);
		}
	};

	function categorizeCULevel(percentage: number | null) {
		if (percentage === null) return 'Unknown';
		if (percentage <= 35) return '00-35%';
		if (percentage <= 60) return '36-60%';
		if (percentage <= 80) return '61-80%';
		if (percentage <= 90) return '81-90%';
		return '91%+';
	}

		const handleTimePeriodChange = (event: { target: { value: string } }) => {
			const newTimePeriod: string = event.target.value;
			setTimePeriod(newTimePeriod);
			// Update chart data based on time period
			fetchChartData(newTimePeriod);
		};

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/capacityObservability/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		fetchData();
		fetchChartData(timePeriod);
	}, [timePeriod]);

	const infoCardData = [
		{
			title: 'Capacities Healthy',
			handleEvent: () => handleCapacityClick('HEALTHY'),
			number: metricsData.healthyCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities Throttled',
			handleEvent: () => handleCapacityClick('THROTTLED'),
			number: metricsData.throttledCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities Frozen',
			handleEvent: () => handleCapacityClick('FROZEN'),
			number: metricsData.frozenCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities at risk of Getting Throttled',
			handleEvent: () => handleCapacityClick('RISK'),
			number: metricsData.riskCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities out of Frozen (In Last 24 hours)',
			handleEvent: () => handleCapacityClick('FROZEN 24'),
			number: metricsData.frozenRecoveredCount,
			totalNumber: metricsData.totalCount
		}
	];

	const seriesData = [
		{ name: '00-35%', dataKey: '00-35%', color: '#3498db' },
		{ name: '36-60%', dataKey: '36-60%', color: '#1abc9c' },
		{ name: '61-80%', dataKey: '61-80%', color: '#f1c40f' },
		{ name: '81-90%', dataKey: '81-90%', color: '#e67e22' },
		{ name: '91%+', dataKey: '91%+', color: '#e74c3c' }
	];

	return (
		<Stack mt="80px" width="100%" className="sector-filter-drop">
			<PageHeader title="Capacity Observability" icon={CapacityObservabilityLogo} lastSync={''} iconSize={30}>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2} className='capacity-metrics'>
				<Grid container spacing={1}>
					<Grid item container xs={12} md={6} spacing={1}>
						{infoCardData.map((info, idx) => (
							<Grid key={info.title} item xs={12} md={idx > 2 ? 6 : 4}>
								<NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber
									}}
									containerProps={{
										minHeight: '50px'
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Grid item container xs={12} md={6}>
						<BarChartWidget
							title="Capacities CU% Utilization Over Time"
							isLoading={chartLoading}
							seriesData={seriesData}
							data={chartOption}
							yAxisOptions={{ name: 'Capacities', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange}
								value={timePeriod}
								options={[
									{
										label: 'Minutes',
										value: 'Minutes',
									},
									{
										label: 'Hourly',
										value: 'Hourly',
									},
									{
										label: 'Daily',
										value: 'Daily',
									},
									{
										label: 'Weekly',
										value: 'Weekly',
									},
									{
										label: 'Monthly',
										value: 'Monthly',
									},
									// {
									// 	label: 'Yearly',
									// 	value: 'Yearly',
									// },
								]}
							/>
						</BarChartWidget>
					</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={incidentDetails}
						tableType="incident-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={incidentDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default CapacityObservability;
