import React from 'react';
import { FormControl, TextField, FormHelperText, TextFieldProps } from '@mui/material';

const CustomTextField: React.FC<TextFieldProps> = ({
	error,
	label,
	value,
	onChange,
	InputLabelProps,
	InputProps,
	sx,
	helperText,
	...rest
}) => {
	return (
		<FormControl fullWidth error={error}>
			<TextField
				label={label}
				value={value}
				onChange={onChange}
				error={error}
				FormHelperTextProps={{ sx: { color: 'red' } }}
				InputLabelProps={{
					sx: {
						backgroundColor: 'primary.darker',
						padding: '0 6px',
						...InputLabelProps?.sx
					},
					...InputLabelProps
				}}
				InputProps={{
					sx: {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: 'white.main',
							borderWidth: '1px',
							borderStyle: 'solid'
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: 'white.main'
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: 'white.main'
						},
						...InputProps?.sx
					},
					...InputProps
				}}
				sx={{
					'& .MuiOutlinedInput-root': {
						border: '1px solid white.main'
					},
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: 'light.diff'
					},
					...sx
				}}
				{...rest}
			/>
			<FormHelperText sx={{ color: 'red' }}>{error && helperText}</FormHelperText>
		</FormControl>
	);
};

export default CustomTextField;
