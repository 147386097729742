import { Chip, ChipProps } from '@mui/material';

const StatusChip: React.FC<{ status: string } & ChipProps> = ({ status, ...props }) => {
	let bgColor = '#299BFF26';
	let color = '#fff';
    status = status ? status : '--'
	switch (status?.toUpperCase()) {
		case 'SUCCESS':
		case 'SUCCESSFUL':
		case 'YES':
		case 'COMPLETED':
		case 'ONLINE':	
		case 'CLOSED':
			bgColor = '#17372e';
			color = '#01c008';
			break;

		
		case 'OPEN':
			bgColor = '#299bff26';
			color = '#299bff';
			break;

		case 'FAILURE':
		case 'TERMINATED':
		case 'SUSPENDED':
		case 'NO':
		case 'FAILED':
			bgColor = '#3d2033';
			color = '#cd2429';
			break;

		
		case 'IN PROGRESS':
			bgColor = 'rgba(255, 162, 0, 0.3)';
			color = 'rgba(255, 162, 0, 1)';
			break;

		case 'SLA MISSED':
			bgColor = 'rgba(253, 120, 59, 0.3)';
			color = 'rgba(253, 120, 59, 1)';
			break;

		case 'Not Met':
		case 'NOT MET':
		case 'Sla Missed':
		case 'IN PROGRESS':
			bgColor = 'rgba(255, 162, 0, 0.2)';
			color = 'rgba(255, 162, 0, 1)';
			break;

		case 'NOT STARTED':
			bgColor = '#1B1E35CC';
			color = '#71758F';
			break;

		case 'N/A':
		case 'NA':
		case 'Scheduled':
		case 'SCHEDULED':
			bgColor = 'rgba(169, 173, 203, 0.2)';
			color = 'rgba(169, 173, 203, 1)';
			break;

		// case 'SCHEDULED':
		// 	bgColor = '#ffa20033';
		// 	color = '#ffa200';
		// 	break;

		case 'RUNNING':
		case 'ACTIVATED':
		case 'IN PROGRESS':
		case 'HOLD':
		case 'FROZEN':
			bgColor = '#299BFF26';
			color = '#299BFF';
			break;
		
		case 'SLA MET':
		case 'MET':
		case 'Sla Met':
		case 'HEALTHY':
		case 'SUCCESSFUL':
			bgColor = 'rgba(1, 192, 8, 0.2)';
			color = 'rgba(1, 192, 8, 1)';
			break;
		case 'OFFLINE':	
		case 'THROTTLED':
			bgColor = '#FE262626';
			color = '#FE2626';
			break;
		case '--':
			bgColor = 'transparent';
			color = '#fff';
			break;
		default:
			bgColor = '#299BFF26';
			color = '#fff';
			break;
	}

	const chipProps: ChipProps = {
		label: status,
		sx: {
			'&.MuiChip-filled': {
				width: 'fit-content'
			},
			'&.MuiChip-filled, & .MuiChip-label': {
				margin: 0,
				fontFamily: 'Montserrat',
				fontWeight: 600,
				textTransform: 'uppercase'
			},
			// !important is given, becoz mui chip style is being overridden by other css files, remove !important once css files are removed
			bgcolor: `${bgColor} !important`,
			color: `${color} !important`
		},
		...props
	};

	return <Chip {...chipProps} />;
};

export default StatusChip;
