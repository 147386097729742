import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import PageHeader from 'components/Header/Page';
import QueryIcon from 'assets/query.svg';
import PepWiseQueryBuilder from 'components/QueryBuilder';
import TableSection from './TableSection';
import { inputFieldType, getOperators } from '../../QueryBuilder/utilities';

const dummyTableData = {
	success: true,
	data: {
		filterOptions: [],
		result: [
			{
				id: '5663518',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer Out',
				MeterName: 'Bandwidth',
				DAIResource: 'No'
			},
			{
				id: '5663523',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer In',
				MeterName: 'Bandwidth',
				DAIResource: 'No'
			},
			{
				id: '5663524',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663532',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - Write Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663533',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Standard Page Blob - LRS Data Stored',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663544',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Files - List Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663545',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Tables - Batch Write Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663557',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Tables - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663573',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - List and Create Container Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663574',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Queues - Class 2 Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '5663593',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Files - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150938',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer In',
				MeterName: 'Bandwidth',
				DAIResource: 'No'
			},
			{
				id: '6150939',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Files - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150946',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer Out',
				MeterName: 'Bandwidth',
				DAIResource: 'No'
			},
			{
				id: '6150947',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Files - List Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150948',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Tables - Batch Write Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150956',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - Write Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150957',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Standard Page Blob - LRS Data Stored',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150966',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150967',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Queues - Class 2 Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150975',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Tables - Read Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6150990',
				Resource: '6ht4jdq5tltzkdata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'General Block Blob - List and Create Container Operations',
				MeterName: 'Storage',
				DAIResource: 'No'
			},
			{
				id: '6664553',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664560',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664561',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664587',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines Edsv4 Series - E4ds v4 - US East',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664588',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664599',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664666',
				Resource: 'aks-npbp01-20111891-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664674',
				Resource: 'aks-npbp01-20111891-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SUK-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664677',
				Resource: 'aks-npbp01-23042447-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664686',
				Resource: 'aks-npbp01-20111891-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664688',
				Resource: 'aks-npbp01-23042447-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664696',
				Resource: 'aks-npbp01-23042447-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664697',
				Resource: 'aks-npbp01-23042447-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664705',
				Resource: 'aks-npbp01-23042447-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664708',
				Resource: 'aks-npbp01-31272025-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-GWC-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664709',
				Resource: 'aks-npbp01-31272025-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664714',
				Resource: 'aks-npbp01-31272025-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664722',
				Resource: 'aks-npbp01-20111891-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664732',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664733',
				Resource: 'aks-npbp01-20111891-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664738',
				Resource: 'aks-npbp01-31272025-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664741',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664742',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664743',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664749',
				Resource: 'aks-npbp01-31272025-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664767',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines Edsv4 Series - E8ds v4 - US South Central',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664768',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664831',
				Resource: 'aks-npprom1z1-32145575-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664832',
				Resource: 'aks-npprom1z1-32145575-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664842',
				Resource: 'aks-npprom1z1-32145575-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664861',
				Resource: 'aks-npprom1z1-32145575-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664872',
				Resource: 'aks-npprom1z1-32145575-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664895',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664906',
				Resource: 'aks-nps01z1-20018736-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664916',
				Resource: 'aks-nps01z1-20018736-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664922',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664923',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664924',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664928',
				Resource: 'aks-nps01z1-23978685-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664931',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664937',
				Resource: 'aks-nps01z1-23978685-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664944',
				Resource: 'aks-nps01z1-20018736-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SUK-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664945',
				Resource: 'aks-nps01z1-20018736-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664946',
				Resource: 'aks-nps01z1-20018736-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664957',
				Resource: 'aks-nps01z1-23978685-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664958',
				Resource: 'aks-nps01z1-23978685-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664959',
				Resource: 'aks-nps01z1-23978685-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664971',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664979',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664980',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664981',
				Resource: 'aks-nps01z1-33478029-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-GWC-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664987',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664988',
				Resource: 'aks-nps01z1-33478029-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664989',
				Resource: 'aks-nps01z1-33478029-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664990',
				Resource: 'aks-nps01z1-33478029-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664991',
				Resource: 'aks-nps01z1-33478029-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6664998',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6664999',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665000',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665010',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665011',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines Edsv4 Series - E4ds v4 - US East',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6665027',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6665028',
				Resource: 'aks-nps01z1-32793209-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665070',
				Resource: 'aks-nps01z2-11718791-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665075',
				Resource: 'aks-nps01z2-10132970-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6665076',
				Resource: 'aks-nps01z2-10132970-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665077',
				Resource: 'aks-nps01z2-10132970-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665078',
				Resource: 'aks-nps01z2-11718791-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665084',
				Resource: 'aks-nps01z2-10132970-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665085',
				Resource: 'aks-nps01z2-10477391-vmss',
				ResourceGroup: 'PEP-EIAP-NONPROD-GWC-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6665086',
				Resource: 'aks-nps01z2-10477391-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665087',
				Resource: 'aks-nps01z2-10477391-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665088',
				Resource: 'aks-nps01z2-11718791-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665097',
				Resource: 'aks-nps01z2-10477391-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665098',
				Resource: 'aks-nps01z2-10477391-vmss',
				ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665099',
				Resource: 'aks-nps01z2-11718791-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SUK-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No'
			},
			{
				id: '6665109',
				Resource: 'aks-nps01z2-10132970-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			},
			{
				id: '6665110',
				Resource: 'aks-nps01z2-11718791-vmss',
				ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No'
			}
		],
		page: {
			size: 100,
			totalElements: 3567375,
			totalPages: 35674,
			currentPage: 1
		}
	}
};

const dummyTreeData = {
	success: true,
	data: {
		filterOptions: [],
		result: [
			{
				id: '5663518',
				Resource: '32wobvnlqajzydata000',
				ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer Out',
				MeterName: 'Bandwidth',
				DAIResource: 'No',
				dependentData: [
					{
						id: '5663523',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer In',
						MeterName: 'Bandwidth',
						DAIResource: 'No'
					},
					{
						id: '5663524',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663532',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - Write Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663533',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Standard Page Blob - LRS Data Stored',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663544',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Files - List Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663545',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Tables - Batch Write Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663557',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Tables - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663573',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - List and Create Container Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663574',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Queues - Class 2 Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '5663593',
						Resource: '32wobvnlqajzydata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Files - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150938',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer In',
						MeterName: 'Bandwidth',
						DAIResource: 'No'
					},
					{
						id: '6150939',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Files - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150946',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Rtn Preference: MGN - Standard Data Transfer Out',
						MeterName: 'Bandwidth',
						DAIResource: 'No'
					},
					{
						id: '6150947',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Files - List Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150948',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Tables - Batch Write Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150956',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - Write Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150957',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Standard Page Blob - LRS Data Stored',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150966',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150967',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Queues - Class 2 Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150975',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Tables - Read Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					},
					{
						id: '6150990',
						Resource: '6ht4jdq5tltzkdata000',
						ResourceGroup: 'pep-eiap-preprod-scus-f5-01-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'General Block Blob - List and Create Container Operations',
						MeterName: 'Storage',
						DAIResource: 'No'
					}
				]
			},
			{
				id: '6664553',
				Resource: 'aks-npargo01z1-13370969-vmss',
				ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No',
				dependentData: [
					{
						id: '6664560',
						Resource: 'aks-npargo01z1-13370969-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664561',
						Resource: 'aks-npargo01z1-13370969-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664587',
						Resource: 'aks-npargo01z1-13370969-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines Edsv4 Series - E4ds v4 - US East',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664588',
						Resource: 'aks-npargo01z1-13370969-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664599',
						Resource: 'aks-npargo01z1-13370969-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664666',
						Resource: 'aks-npbp01-20111891-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664674',
						Resource: 'aks-npbp01-20111891-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-SUK-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664677',
						Resource: 'aks-npbp01-23042447-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664686',
						Resource: 'aks-npbp01-20111891-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664688',
						Resource: 'aks-npbp01-23042447-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664696',
						Resource: 'aks-npbp01-23042447-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664697',
						Resource: 'aks-npbp01-23042447-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664705',
						Resource: 'aks-npbp01-23042447-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664708',
						Resource: 'aks-npbp01-31272025-vmss',
						ResourceGroup: 'PEP-EIAP-NONPROD-GWC-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664709',
						Resource: 'aks-npbp01-31272025-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664714',
						Resource: 'aks-npbp01-31272025-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664722',
						Resource: 'aks-npbp01-20111891-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664732',
						Resource: 'aks-npbp01-77613578-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664733',
						Resource: 'aks-npbp01-20111891-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664738',
						Resource: 'aks-npbp01-31272025-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664741',
						Resource: 'aks-npbp01-77613578-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664742',
						Resource: 'aks-npbp01-77613578-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					}
				]
			},

			{
				id: '6664743',
				Resource: 'aks-npbp01-77613578-vmss',
				ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
				MeterName: 'Virtual Network',
				DAIResource: 'No',
				dependentData: [
					{
						id: '6664749',
						Resource: 'aks-npbp01-31272025-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664767',
						Resource: 'aks-npbp01-77613578-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines Edsv4 Series - E8ds v4 - US South Central',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664768',
						Resource: 'aks-npbp01-77613578-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664831',
						Resource: 'aks-npprom1z1-32145575-vmss',
						ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664832',
						Resource: 'aks-npprom1z1-32145575-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664842',
						Resource: 'aks-npprom1z1-32145575-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664861',
						Resource: 'aks-npprom1z1-32145575-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664872',
						Resource: 'aks-npprom1z1-32145575-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664895',
						Resource: 'aks-nps01z1-21684377-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664906',
						Resource: 'aks-nps01z1-20018736-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664916',
						Resource: 'aks-nps01z1-20018736-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					}
				]
			},

			{
				id: '6664922',
				Resource: 'aks-nps01z1-21684377-vmss',
				ResourceGroup: 'PEP-EIAP-PREPROD-SCUS-INFRA-02-AKS-RG',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Machines RI - Standard',
				MeterName: 'Virtual Machines',
				DAIResource: 'No',
				dependentData: [
					{
						id: '6664923',
						Resource: 'aks-nps01z1-21684377-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664924',
						Resource: 'aks-nps01z1-21684377-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664928',
						Resource: 'aks-nps01z1-23978685-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664931',
						Resource: 'aks-nps01z1-21684377-vmss',
						ResourceGroup: 'pep-eiap-preprod-scus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664937',
						Resource: 'aks-nps01z1-23978685-vmss',
						ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664944',
						Resource: 'aks-nps01z1-20018736-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-SUK-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664945',
						Resource: 'aks-nps01z1-20018736-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664946',
						Resource: 'aks-nps01z1-20018736-vmss',
						ResourceGroup: 'pep-eiap-preprod-suk-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664957',
						Resource: 'aks-nps01z1-23978685-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664958',
						Resource: 'aks-nps01z1-23978685-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664959',
						Resource: 'aks-nps01z1-23978685-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664971',
						Resource: 'aks-nps01z1-30394436-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					}
				]
			},
			{
				id: '6664979',
				Resource: 'aks-nps01z1-30394436-vmss',
				ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
				DFSectorname: '',
				Environment: 'Non-prod',
				BillingInformation: '',
				ProjectName: null,
				DFProjectcode: '',
				Subscription: 'pep-nonprod-02-sub',
				MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
				MeterName: 'Virtual Network',
				DAIResource: 'No',
				dependentData: [
					{
						id: '6664980',
						Resource: 'aks-nps01z1-32793209-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664981',
						Resource: 'aks-nps01z1-33478029-vmss',
						ResourceGroup: 'PEP-EIAP-NONPROD-GWC-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664987',
						Resource: 'aks-nps01z1-32793209-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664988',
						Resource: 'aks-nps01z1-33478029-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664989',
						Resource: 'aks-nps01z1-33478029-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664990',
						Resource: 'aks-nps01z1-33478029-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664991',
						Resource: 'aks-nps01z1-33478029-vmss',
						ResourceGroup: 'pep-eiap-nonprod-gwc-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Private Link - Standard Data Processed - Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6664998',
						Resource: 'aks-nps01z1-30394436-vmss',
						ResourceGroup: 'PEP-EIAP-NONPROD-AE-GLOBAL-01-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6664999',
						Resource: 'aks-nps01z1-30394436-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6665000',
						Resource: 'aks-nps01z1-32793209-vmss',
						ResourceGroup: 'pep-eiap-preprod-eus-infra-02-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Ingress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6665010',
						Resource: 'aks-nps01z1-30394436-vmss',
						ResourceGroup: 'pep-eiap-nonprod-ae-global-01-aks-rg',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Network Peering - Intra-Region Egress',
						MeterName: 'Virtual Network',
						DAIResource: 'No'
					},
					{
						id: '6665011',
						Resource: 'aks-nps01z1-32793209-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines Edsv4 Series - E4ds v4 - US East',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					},
					{
						id: '6665027',
						Resource: 'aks-nps01z1-32793209-vmss',
						ResourceGroup: 'PEP-EIAP-PREPROD-EUS-INFRA-02-AKS-RG',
						DFSectorname: '',
						Environment: 'Non-prod',
						BillingInformation: '',
						ProjectName: null,
						DFProjectcode: '',
						Subscription: 'pep-nonprod-02-sub',
						MeterCategory: 'Virtual Machines RI - Standard',
						MeterName: 'Virtual Machines',
						DAIResource: 'No'
					}
				]
			}
		],
		page: {
			size: 100,
			totalElements: 3567375,
			totalPages: 35674,
			currentPage: 1
		}
	}
};

const ResourceMapping = () => {
	useEffect(() => {
		//TODO: remove DOM manipulation after all components change to mui without css
		const activeId = document.getElementById('root');
		if (activeId) {
			document.head.classList.add('theme-bg-blue');
			document.body.classList.add('theme-bg-blue');
		}
	}, []);

	const dummyOptions = ['pep-datahub-prod-01-sub', 'pep-gtmvn-apac-prod-scus-01-rg', 'databricksngibcvwhhednarqc'];
	const fields = {
		resourceGroup: {
			name: 'resourceGroup',
			label: 'Resource Group',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: null,
				operators: getOperators('boolean'), // Fetch operators based on field input type
				valueType: 'boolean'
			}
		},
		resource: {
			name: 'resource',
			label: 'Resource',
			fieldOptions: {
				fieldInput: inputFieldType.textfield,
				options: null,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		},
		sector: {
			name: 'sector',
			label: 'Sector',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: dummyOptions,
				operators: getOperators('number'), // Fetch operators based on field input type
				valueType: 'number'
			}
		},
		environment: {
			name: 'environment',
			label: 'Environment',
			fieldOptions: {
				fieldInput: inputFieldType.textfield,
				options: null,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		},
		project: {
			name: 'project',
			label: 'Project',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: dummyOptions,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		},
		projectNumber: {
			name: 'projectNumber',
			label: 'Project Number',
			fieldOptions: {
				fieldInput: inputFieldType.textfield,
				options: null,
				operators: getOperators('number'), // Fetch operators based on field input type
				valueType: 'number'
			}
		},
		billingInformation: {
			name: 'billingInformation',
			label: 'Billing Information',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: null, //used Boolean options
				operators: getOperators('boolean'), // Fetch operators based on field input type
				valueType: 'boolean'
			}
		},
		subscription: {
			name: 'subscription',
			label: 'Subscription',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: null,
				operators: getOperators('boolean'), // Fetch operators based on field input type\
				valueType: 'boolean'
			}
		},
		meterCategory: {
			name: 'meterCategory',
			label: 'Meter Category',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: dummyOptions,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		},
		meterName: {
			name: 'meterName',
			label: 'Meter Name',
			fieldOptions: {
				fieldInput: inputFieldType.textfield,
				options: null,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		},
		'D&AI': {
			name: 'D&AI',
			label: 'D & AI',
			fieldOptions: {
				fieldInput: inputFieldType.autocomplete,
				options: dummyOptions,
				operators: getOperators('string'), // Fetch operators based on field input type
				valueType: 'string'
			}
		}
	};
	const [isTree, setTree] = useState(true);

	return (
		<Box mt="70px" bgcolor="blue.darker">
			<PageHeader title="Resource Mapping" icon={QueryIcon} />
			<Stack p={2} spacing={2}>
				<PepWiseQueryBuilder fields={fields} setTree={setTree} />
				<TableSection tableData={isTree ? dummyTreeData : dummyTableData} />
			</Stack>
		</Box>
	);
};

export default ResourceMapping;
